import React from "react"
import styled from "styled-components"
import ContentWithSidebar from "./ContentWithSidebar"
import CompanyCta from "../components/sidebar/CompanyCTA"
import CompanySidebar from "../components/CompanySidebar"
import CompanyPlanDetailedList from "../components/Slices/CompanyPlanDetailedList"
import Byline from "../components/Byline"

const Shell = styled.div`
  .table-section {
    max-width: 44rem;
  }
`

export default props => {
  const { children, page } = props
  const { topContent, author, dateModifiedPretty } = page
  const after = <Byline author={author} dateModified={dateModifiedPretty} />
  return (
    <Shell>
      <ContentWithSidebar
        h1={`${page.name} Plans, Deals, and Promotions`}
        body={children}
        page={page}
        after={after}
        sidebar={<CompanySidebar {...props.page} />}
        topContent={topContent}
        topSideContent={<CompanyCta {...props.page} />}
      >
        <div className="top-overlay">
          <CompanyPlanDetailedList plans={page.plans} company={page} />
        </div>
      </ContentWithSidebar>
    </Shell>
  )
}
