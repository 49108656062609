import React from "react"
import styled from "styled-components"
import { snakeCase, trim, round } from "lodash"
import ContentWithSidebar from "./ContentWithSidebar"
import Cta from "../components/Cta"
import { Colors, media } from "../constants/Style"
import Image from "../components/Image"
import { lighten } from "polished"
import Contain from "../components/Contain"
import processStringToReact from "../helpers/processStringToReact"
import BaseCtaButton from "../components/BaseCTAButton"
import PhoneCtaButton from "../components/PhoneCtaButton"
import { formatPhoneNumber } from "../helpers/formatPhoneNumber"
import Col from "../Col"
import Link from "../components/Link"
import Table from "../components/Slices/Table"
import toJsonForReact from "../helpers/toJsonForReact"
import Byline from "../components/Byline"
import ParsedContentBody from "../components/ParsedContentBody"
import addCommas from "../lib/addCommas"

const Shell = styled.div`
  .topContent {
    margin: 0 auto -8rem;
  }
  &&& article {
    margin-top: -3rem;
    section.primary {
      .city-top-contain {
        h2 {
          font-size: 1rem;
          margin-bottom: 0.3rem;
          text-transform: uppercase;
          text-align: center;
          opacity: 0.6;
          margin-top: 0 !important;
        }
      }
    }
    .byline {
      margin-left: 0;
      padding-left: 0;
      margin-top: 5rem;
      margin-bottom: -8rem;
    }
    ${media.tablet`
      padding: 0;
      .city-top-contain {
        padding: 0 1rem;
      }
    `}
  }
  ${media.tablet`
    .top-shell {
      min-height: 29rem;
    }
  `}
  ${media.hand`
    &&& h1 {
      margin-top: 1rem;
      display: block;
    }
    .top-shell {
      min-height: 22rem;
    }
    &&& article {
      section.primary {
      .city-top-contain {
        h2 {
          margin-bottom: 0.6rem;
          max-width: 80%;
          margin-left: auto;
          margin-right: auto;
        }
      }
      }
    }
  `}
`

const CTA = styled.div.attrs({ className: "action-box" })`
  display: flex;
  background: ${lighten(0.39, Colors.lightBlue)};
  border: 1px solid ${lighten(0.3, Colors.lightBlue)};
  padding: 1.6rem 2rem 1.6rem;
  flex-direction: row;
  margin-bottom: 1.8rem;
  align-items: center;
  box-shadow: 2px 6px 12px rgba(0, 0, 0, 0.03);
  &:last-of-type {
    margin-bottom: 6rem;
  }
  .logo {
    max-width: 240px;
    max-height: 100px;
    margin: 0 auto;

    img {
      right: 0;
      margin: 0 auto;
    }
  }
  .section-left {
    width: 26%;
    margin-right: 2rem;
    flex: none;
  }
  .section-right {
    width: 20rem;
    display: flex;
    align-items: center;
    .actions {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      justify-items: center;
      a:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  && .section-center {
    flex: 1;
    margin-right: 4rem;
    display: flex;
    align-items: center;
    h4 {
      margin-top: 0;
      margin-bottom: 0;
      padding-top: 0;
    }
    h3 {
      font-size: 1.4rem;
      margin-top: 1.3rem;
      margin-bottom: 0.6rem;
      &:first-of-type {
        margin-top: 0;
      }
    }
  }

  ${media.tablet`
    flex-direction: column;
    padding:  2rem 2rem 0.8rem;
    .section-left {
      width: 80% !important;
      margin-right: 0;
    }
    .section-right {
      width: 100%;
      .actions {
        max-width: 30rem;
        margin: 0 auto 1rem;
      }
    }
    &&& .section-center {
      margin: 2rem 0;

    }
    && .cta-body {
      h4 {
          margin-top: 0.6rem;
          padding-top: 0;
      }
      margin: 2rem 0;
      div {
        flex-direction: row;
        width: 100%;
        margin: 0 0 0.5rem 0;
        &.actions a {
          flex: 1;
          text-align: center;
          margin-bottom: 0.4rem;
          width: 100% !important;
          min-width: 100% !important;
          max-width: 100% !important;
          margin: 0 0 1rem !important;
        }
      }
    }
  `}
`
const Recommendation = styled.div`
  background: ${lighten(0.014, Colors.veryLightBlue)};
  margin-bottom: 0rem;
  padding: 4rem;
  &:nth-of-type(2n) {
    background: ${lighten(0.0, Colors.veryLightBlue)};
  }
  > div {
    display: flex;
    flex-direction: row;
  }
  .action-box {
    flex: none;
    width: 16.25rem;
    margin-right: 2rem;
    float: none;
    background: transparent;
    .logo {
      max-height: 8.4rem;
      padding: 0 0.5rem;
    }
    .actions {
      padding-top: 0.8rem;
    }
  }
  &&& h3 {
    margin-top: 0;
    padding-top: 0;
  }
  ${media.tablet`
  padding: 3rem 2rem;
    > div {
      display: block;
      .action-box {
        float: left;
        margin-left: 0;
        margin-bottom: 0.2rem;
        margin-right: 1.6rem;
      }
    }
  `}
  ${media.hand`
    padding: 2rem 0.5rem;
    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .action-box {
        float: none;
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 1rem;
      }
    }
  `}
`
const Tags = styled.ul`
  display: flex;
  list-style-type: none;
  list-style-position: outside;
  &&& {
    margin: 0 0 1rem 0;
    padding: 0;
    width: 100%;
  }
  &&& li {
    margin: 0 0.4rem 0 0;
    text-align: center;
    background: #f6f4eb;
    color: #969389;
    padding: 0.2rem 0.87rem;
    font-size: 0.75rem;
    border-radius: 23px;
    line-height: 160%;
  }
  ${media.hand`
    justify-content: center;
  `}
`
const Badge = styled.div`
  margin: 1.4rem auto 0;
  width: 78%;
  text-align: center;
  border: 2px solid ${lighten(0.05, "#fce9c8")};
  font-weight: bold;
  background: #fffdf2;
  padding: 0.6rem;
  font-size: 0.9rem;
`
const Bullets = styled.ul`
  &&& {
    padding: 0;
    margin: 0;
    list-style-position: inside;
  }
  &&& li {
    margin-bottom: 0;
    font-size: 1.04rem;
  }
`
const TableContain = styled(Contain)`
  padding: 8rem 0 0;
  ${media.tablet`
    padding: 8rem 1rem 0;
  `}
`
const AnalysisShell = styled.div`
  background: #eee;
  padding: 5rem 0;
  display: flex;
  justify-content: center;
  width: 100%;
  &&& > div {
    &.paper {
      margin: 0 1rem 0 0;
      background: #fff;
      padding: 3.1rem 3.4rem;
      width: 54rem;
      box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.025);
    }

    h3 {
      margin-top: 0;
      padding-top: 0;
      margin-bottom: 1.4rem;
      font-size: 2.2rem;
    }
    ${media.tablet`
      width: 100%;
      &.paper {
        padding: 2rem;
        margin-right: 0;
        width: 100%;
      }
    `}
    ${media.hand`
      &.paper {
        padding: 1rem;
      }
    `}
  }
  ${media.tablet`
    padding: 1rem 1rem;
    flex-direction: column;
  `}
`
const Source = styled.div`
  font-size: 0.9rem;
  font-style: italic;
  margin: 3rem 0 -1.4rem;
  color: #888;
  ${media.tablet`
    margin: 2rem 0 -1rem;
  `}
  ${media.hand`
    font-size: 0.8rem;
    margin: 2rem 0 -0.4rem;
  `}
`
const AnalysisSidebar = styled.div`
  width: 14rem;
  flex: none;
  ${media.tablet`
    margin-top: 0.4rem;
    display: flex;
    flex-wrap: wrap;
  `}
`
const StatBlock = styled.div`
  background: #fff;
  margin-bottom: 0.4rem;
  width: 100%;
  flex: none;
  padding: 1.2rem;
  label {
    font-size: 0.9rem;
    font-weight: bold;
    color: ${Colors.brown};
  }
  div {
    font-size: 1.8rem;
    font-weight: bold;
  }
  .sub {
    font-size: 0.8rem;
    opacity: 0.8;
  }
  ${media.tablet`
      align-items: center;
      width: calc(50% - 0.4rem);
      margin-right: 0.4rem;
      &:nth-of-type(2n) {
      width: 50%;
      margin-right: 0;
    }
  `}
`

export default props => {
  const { page } = props
  const { author, dateModifiedPretty, prismicContent } = page
  const providerRows = [
    [
      { header: true, content: "Provider" },
      { header: true, content: "HAR Score" },
      { header: true, content: "Website" },
      { header: true, content: "Install Type" },
      { header: true, content: "Phone" },
      { header: true, content: "Monitoring Type" },
      { header: true, content: "Business Service" },
    ],
    ...page.body.allProviders.map(
      ({
        uid,
        data: {
          name,
          valueScore,
          website,
          installType,
          customerServicePhone,
          offersBusinessService,
          typeOfMonitoring,
          wikipedia,
        },
      }) => [
        { header: true, content: name },
        {
          content: valueScore,
          style: { minWidth: "120px", color: Colors.green, fontWeight: "bold", fontSize: "1.15rem" },
        },
        {
          content: toJsonForReact({
            tag: "link",
            content: trim(website.url.replace("http://", "").replace("https://", ""), "/"),
            to: website.url,
            target: "_blank",
            name: "city",
            company: uid,
            render: "simple",
          }),
        },
        { content: installType, style: { minWidth: "140px" } },
        { content: formatPhoneNumber(customerServicePhone), style: { minWidth: "140px" } },
        { content: typeOfMonitoring, style: { minWidth: "140px" } },
        { content: offersBusinessService === "true" ? "Yes" : "No", style: { minWidth: "140px" } },
      ]
    ),
  ]
  const { stats } = page.body
  return (
    <Shell>
      <ContentWithSidebar
        h1={page.h1}
        body={[]}
        page={page}
        topComponent={
          <div className="topContent">
            <p style={{ margin: "0 auto" }}>
              Here's what we found when we analyzed home security providers and <a href="#analysis">crime data</a> in{" "}
              {page.cityName}
            </p>
          </div>
        }
        centerTop={true}
        topSideContent={false}
        overlayTopContent={false}
        topAlign="center"
        wideContent={
          <div style={{ marginTop: "-10.55rem" }}>
            <Contain className="city-top-contain">
              <h2>{`Recommended Home Security Providers in ${page.cityName}`}</h2>
              {page.body.providers.map((company, i) => {
                return (
                  <CTA className={!i && "first-choice"} key={`prov-${company.uid}`}>
                    <div className="section-left">
                      <Image className="logo" {...company.data.companyLogo} forceLoaded={true} />
                      <Badge>{company.rec}</Badge>
                    </div>
                    <div className="section-center">
                      <Col>
                        <Tags>
                          {company.tags.map(tag => (
                            <li key={`tag-${snakeCase(tag)}`}>{tag}</li>
                          ))}
                        </Tags>
                        <Bullets>
                          {company.bullets.map(bullet => (
                            <li key={snakeCase(bullet)}>{bullet}</li>
                          ))}
                        </Bullets>
                      </Col>
                    </div>
                    <div className="section-right">
                      <div className="actions">
                        {company.salesPhone && (
                          <PhoneCtaButton name="city" company={company.uid} to={`tel:${company.salesPhone.phone}`}>
                            {formatPhoneNumber(company.salesPhone.phone)}
                          </PhoneCtaButton>
                        )}
                        <BaseCtaButton name="city" company={company.uid} to={company.data.website.url}>
                          View Site »
                        </BaseCtaButton>
                      </div>
                    </div>
                  </CTA>
                )
              })}
            </Contain>
            <AnalysisShell name="analysis">
              <Contain className="paper">
                <h3>{`Our Analysis of Safety in ${page.cityName}`}</h3>
                {page.textblocks && page.textblocks.cityMainText && page.textblocks.cityMainText.introSection && (
                  <div>
                    {page.textblocks.cityMainText.introSection.map(p =>
                      p.indexOf("###") === 0 ? (
                        <h3 key={p.substr(0, 32)}>{p.replace("### ", "")}</h3>
                      ) : (
                        <p key={p.substr(0, 32)}>{processStringToReact(p)}</p>
                      )
                    )}
                  </div>
                )}
                <Source>Data sourced from FBI Uniform Crime Reporting, 2017 (last available dataset)</Source>
              </Contain>
              <AnalysisSidebar>
                <StatBlock>
                  <label>Population</label>
                  <div>{addCommas(stats.population)}</div>
                </StatBlock>
                <StatBlock>
                  <label>Most Common Property Crime</label>
                  <div>{stats.mcpropc}</div>
                </StatBlock>
                <StatBlock>
                  <label>Law Enforcement per 1000 Capita</label>
                  <div>{round(stats.lawenfPer1000Capita, 3)}</div>
                  <div className="sub">{stats.lawenfRatePercentileGroup} Percentile</div>
                </StatBlock>
                <StatBlock>
                  <label>Total Crimes per Hour</label>
                  <div>
                    {round(stats.totalCrimesPerhr, 3)}
                    <div className="sub">{stats.totalCrimesPerhrPercentileGroup} Percentile</div>
                  </div>
                </StatBlock>
                <StatBlock>
                  <label>Property Crimes per 1000 Capita</label>
                  <div>{round(stats.propertyCrimesPer1000Capita, 3)}</div>
                  <div className="sub">{stats.propertyCrimesRatePercentileGroup} Percentile</div>
                </StatBlock>
                <StatBlock>
                  <label>Person Crimes per 1000 Capita</label>
                  <div>{round(stats.personCrimesPer1000Capita, 3)}</div>
                  <div className="sub">{stats.personCrimesRatePercentileGroup} Percentile</div>
                </StatBlock>
              </AnalysisSidebar>
            </AnalysisShell>
            {page.body.recs.map((rec, i) => {
              const c = page.body.providers[i]
              return (
                <Recommendation key={`rec-${i}`}>
                  <Contain>
                    <Cta
                      company={c}
                      cta={{
                        internalName: "sidecta",
                        design: "side_right",
                        // header: c.data.name,
                        primaryAction: c.salesPhone ? (
                          <Link name="city" company={c.uid} className="phoneLink" to={`tel:${c.salesPhone.phone}`}>
                            {formatPhoneNumber(c.salesPhone.phone)}
                          </Link>
                        ) : null,
                        secondaryAction: (
                          <Link name="city" company={c.uid} to={`tel:${c.data.website.url}`}>
                            Visit Site
                          </Link>
                        ),
                        includeLogo: true,
                        richText: "",
                        richTextSpecial: "",
                      }}
                    />
                    <div>
                      {rec
                        .split("\n\n")
                        .map(p =>
                          p.indexOf("###") === 0 ? <h3>{p.replace("### ", "")}</h3> : <p>{processStringToReact(p)}</p>
                        )}
                    </div>
                  </Contain>
                </Recommendation>
              )
            })}

            <TableContain>
              {prismicContent && <ParsedContentBody body={prismicContent.data.body} />}
              <Table
                id="city-provider"
                rows={providerRows}
                primary={{
                  tableDescription: `Here is a complete list of providers available in ${page.cityName}:`,
                  tableHeader: [{ type: "heading2", text: `${page.cityName} Home Security Providers`, spans: [] }],
                  tableFooter: null,
                }}
                forceNormalWidth
              />
              <Byline author={author} dateModified={dateModifiedPretty} />
            </TableContain>
          </div>
        }
      />
    </Shell>
  )
}
