import React from "react"
import { pick } from "lodash"
import styled from "styled-components"
import ContentWithSidebar from "./ContentWithSidebar"
import CompanyCta from "../components/sidebar/CompanyCTA"
import CompanySidebar from "../components/CompanySidebar"
import CompanyReviews from "../components/CompanyReviews"
import SubmitReview from "../components/SubmitReview"
import ScoreBars from "../components/ScoreBars"
import { media } from "../constants/Style"
import Byline from "../components/Byline"

const Types = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: -3rem -3rem 0rem -3rem;
  background: #fafcff;
  padding: 1rem 3rem;
  border-top: 2px solid #d4deec80;
  div {
    width: 50%;
    font-size: 1.2rem;
  }
  ${media.hand`
    padding: 1rem;
    margin: -1rem -1rem 0rem -1rem;
    div {
    font-size: 0.9rem
    text-align: center;
    }
  `}
`

const ReviewOverview = ({ page }) => {
  const { typeOfMonitoring, installType } = page
  return (
    <div className="top-overlay">
      <Types>
        <div>
          <strong>Monitoring Type:</strong> {typeOfMonitoring}
        </div>
        <div>
          <strong>Install Type:</strong> {installType}
        </div>
      </Types>
      <ScoreBars
        scores={pick(page, [
          "customerServiceScore",
          "easeOfUseScore",
          "equipmentScore",
          "featuresAndTechnologyScore",
          "valueScore",
        ])}
      />
    </div>
  )
}

export default props => {
  const { page, children } = props
  const { topContent, reviews, companyId, h1, name, author, dateModifiedPretty } = page
  const h1Parts = h1.split(":")
  const mainH1 = h1Parts[0]
  let withH1 = h1Parts[1] ? `: ${h1Parts[1]}` : ""
  const after = (
    <>
      <Byline author={author} dateModified={dateModifiedPretty} style={{ marginBottom: "4rem" }} />
      <SubmitReview companyId={companyId} name={name} />
      {reviews && reviews.length > 0 ? <h3>Customer {name} Reviews</h3> : null}
      <CompanyReviews reviews={reviews} name={name} />
    </>
  )

  const before = (
    <>
      <ReviewOverview {...props} />
    </>
  )

  return (
    <ContentWithSidebar
      h1={mainH1}
      withH1={withH1}
      body={children}
      page={props.page}
      sidebar={<CompanySidebar {...props.page} />}
      topContent={topContent}
      topSideContent={<CompanyCta {...props.page} />}
      after={after}
      before={before}
    />
  )
}
