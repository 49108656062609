import React from "react"
import styled from "styled-components"
import {
  FiAlertCircle,
  FiHelpCircle,
  FiFrown,
  FiMeh,
  FiSmile,
  FiHeart,
  FiGift,
  FiLifeBuoy,
  FiAward,
  FiCompass,
  FiSlash,
  FiThumbsDown,
  FiThumbsUp,
  FiStar,
} from "react-icons/fi"
import { Colors, media } from "../../constants/Style"
import prismicSerializer from "../../helpers/prismicSerializer"
import { lighten, darken, desaturate } from "polished"

const icons = {
  exclamation: FiAlertCircle,
  question: FiHelpCircle,
  buoy: FiLifeBuoy,
  thumbsup: FiThumbsUp,
  thumbsdown: FiThumbsDown,
  smile: FiSmile,
  meh: FiMeh,
  frown: FiFrown,
  heart: FiHeart,
  gift: FiGift,
  award: FiAward,
  slashout: FiSlash,
  compass: FiCompass,
  star: FiStar,
}

const gray = "#f1f3f5"
const red = Colors.red
const blue = Colors.lightBrightBlue
const green = Colors.green
const yellow = Colors.gold

const colors = {
  gray: {
    background: gray,
    text: darken(0.45, gray),
  },
  blue: {
    background: lighten(0.014, blue),
    text: darken(0.43, desaturate(0.33, blue)),
  },
  green: {
    background: lighten(0.46, green),
    text: lighten(0.04, desaturate(0.12, green)),
  },
  red: {
    background: lighten(0.39, red),
    text: lighten(0.02, desaturate(0.17, red)),
  },
  yellow: {
    background: lighten(0.34, yellow),
    text: darken(0.1, desaturate(0.2, yellow)),
  },
}

const Shell = styled.div`
  padding: 2.4rem 2.4rem 2.4rem 10.7rem;
  background: ${({ colors }) => colors.background};
  color: ${({ colors }) => colors.text};
  display: flex;
  margin-bottom: 3rem;
  align-items: center;
  position: relative;
  && p {
    color: ${({ colors }) => colors.text};
  }
  figure {
    margin-right: 2.4rem;
    margin-bottom: -2.4rem;
    padding: 2.4rem;
    background: rgba(255, 255, 255, 0.3);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    display: flex;
    align-items: center;
  }
  ${media.hand`
    flex-direction: column;
    padding: 0;
    figure {
      position: relative;
      width: 100%;
      margin-bottom: 0.1rem;
      margin-right: 0;
      display: block;
      text-align: center;
    }
    p {
      padding: 2rem;
    }
  `}
`

export default ({ primary }) => {
  const { color, text, icon } = primary
  const Icon = icon ? icons[icon] : false
  const clrs = colors[color || "yellow"]
  return (
    <Shell colors={clrs}>
      {Icon && (
        <figure>
          <Icon size="50px" color={clrs.text} />
        </figure>
      )}
      {prismicSerializer(text)}
    </Shell>
  )
}
