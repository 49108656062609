import React from "react"
import styled from "styled-components"
import Link from "./Link"
import { Colors } from "../constants/Style"
import { lighten } from "polished"
import processStringToReact from "../helpers/processStringToReact"

const References = styled.div`
  margin-bottom: 2rem;
  padding: 2.5rem 3.75rem 2.225rem;
  background: ${lighten(0.55, Colors.darkBlueGray)};
  && ol {
    margin-top: 1.1rem;
    margin-left: 1.1rem;
    margin-bottom: 0;
    padding-bottom: 0;
    li {
      font-size: 0.95rem;
      margin-bottom: 0.8rem;
      a {
        color: ${Colors.darkBlueGray};
        border-bottom: 0px solid ${lighten(0.25, Colors.lightBlue)};
        text-decoration: none;
      }
    }
  }
  && h4 {
    margin-top: 0;
    padding-top: 0;
  }
  .return-link {
    width: 1.875rem !important;
    display: inline-block;
    text-align: center;
    padding-right: 0.3125rem;
    font-size: 0.9rem;
    border-bottom: 0 !important;

    span {
      transform: rotate(90deg) !important;
      display: inline-block;
    }
  }
`
const Ref = styled.span``

export default ({ refs }) => {
  return (
    refs &&
    refs.length > 1 && (
      <References>
        <h4>References</h4>
        <ol>
          {refs.map(({ content, to, num }) => (
            <li key={to}>
              <Ref id={`ref-${num}`} as={to && to.length ? Link : null} to={to} target="_blank">
                {processStringToReact(content)}
              </Ref>
              <a href={`#return-ref-${num}`} className="return-link">
                <span>&#8617;</span>
              </a>
            </li>
          ))}
        </ol>
      </References>
    )
  )
}
