import React from "react"
import { Link } from "gatsby"
import { PUBLIC_DOMAIN, TEST_DOMAIN } from "../constants/Net"
import { omit } from "lodash"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import nameFromSlugParts from "../lib/nameFromSlugParts"

let companies = []

const addSlash = url => {
  if (url) {
    const len = url.length
    if (url[len - 1] !== "/") url += "/"
  }
  return url.replace(/\s/g, "")
}

const getUrl = ({ tel, to, href }) => {
  return tel ? `tel:${tel}` : href || to
}

class SmartLink extends React.Component {
  state = {
    mask: false,
    to: false,
    rel: null,
    target: null,
  }
  constructor(props) {
    super(props)
    this.aRef = React.createRef()
  }
  getName() {
    let template = "main"
    if (window.location) {
      const path = window.location.pathname.substr(1, window.location.pathname.length - 2).split("/")
      const last = path[path.length - 1]
      const first = path[0]
      template = nameFromSlugParts({ first, last, company: this.props.company })
    }
    const name = { ...this.props }.name.replace("%template%", template)
    return name
  }
  componentDidMount() {
    const { company } = this.props
    const url = getUrl(this.props)
    if (url && url.includes("amazon.com")) {
      const withTag = url.includes("hareport-20") ? url : `${url}${url.includes("?") ? "&" : "?"}tag=hareport-20`
      this.setState({ mask: url, to: withTag, target: "_blank", rel: "nofollow noopener noreferrer" })
    } else if (url.indexOf("tel:") !== 0 && company && companies[company]) {
      const name = this.getName()
      this.setState({
        mask: url,
        to: `/out/${company}/${name}/`,
        target: "_blank",
        rel: "nofollow noopener noreferrer",
      })
    }
  }
  cleanProps(props) {
    return omit(
      Object.entries(props).reduce((out, [key, val]) => {
        if (typeof val !== "string" || val.length) {
          out[key] = val
        }
        return out
      }, {}),
      ["name", "format", "company", "length", "render"]
    )
  }
  handleMouseDown = () => {
    this.aRef.current.href = this.state.to || this.props.to
    setTimeout(() => {
      this.handleMouseUp()
    }, 3000)
  }
  handleMouseUp = () => {
    setTimeout(() => {
      this.aRef.current.href = this.state.mask
    }, 100)
  }
  trackClick = () => {
    if (typeof window.ga === "function") {
      window.ga("send", {
        hitType: "event",
        eventCategory: "CTAs",
        eventAction: "click",
        eventLabel: `${this.props.company} - ${this.getName()}`,
      })
    }
  }
  phoneClick = () => {
    if (typeof window.ga === "function") {
      window.ga("send", {
        hitType: "event",
        eventCategory: "CTAs",
        eventAction: "phone",
        eventLabel: `${this.props.company} - ${this.getName()}`,
      })
    }
  }
  renderMasked() {
    const { mask, rel, target } = this.state
    const { children, visibleTo, ...props } = this.props
    return (
      <a
        ref={this.aRef}
        href={mask || visibleTo}
        onMouseDown={this.handleMouseDown}
        onMouseUp={this.handleMouseUp}
        onClick={this.trackClick}
        {...{ rel, target }}
        {...this.cleanProps(props)}
      >
        {children}
      </a>
    )
  }
  renderPhoneLink = () => {
    const { rel, target } = this.state
    let { to, href, tel, ...props } = this.props
    const url = getUrl(this.props)
    return <a href={url} rel={rel} onClick={this.phoneClick} target={target} {...this.cleanProps(props)} /> // eslint-disable-line
  }
  renderExternalLink = () => {
    const { rel, target } = this.state
    let { to, href, tel, ...props } = this.props
    const url = getUrl(this.props)

    return <OutboundLink href={url} rel={rel} target={target} {...this.cleanProps(props)} /> // eslint-disable-line
  }
  renderInternalLink = () => {
    const { to, href, ...props } = this.props
    return (
      <Link
        to={addSlash(href || to)
          .replace("https://homealarmreport.com", "")
          .replace("https://howsafe.org", "")}
        {...this.cleanProps(props)}
      />
    ) // eslint-disable-line
  }
  render() {
    const { visibleTo, children } = this.props
    const url = getUrl(this.props)

    // FRONTPOINTREMOVAL: Return all frontpont links as plaintext
    if ((url && url.includes("frontpoint")) || (visibleTo && visibleTo.includes("frontpoint"))) {
      return <>{children}</>
    }
    if ((this.state.mask && this.state.to) || visibleTo) {
      return this.renderMasked()
    }
    if (url && url.includes("tel")) {
      return this.renderPhoneLink()
    } else if (
      url &&
      (url.includes("#") ||
        (url.includes("http") &&
          !url.includes(PUBLIC_DOMAIN) &&
          !url.includes(TEST_DOMAIN) &&
          !url.includes("homealarmreport.com")))
    ) {
      return this.renderExternalLink()
    } else {
      return this.renderInternalLink()
    }
  }
}

export default SmartLink
export const setCompanies = cs => {
  companies = cs.reduce((out, { uid, mainLink }) => {
    if (mainLink) {
      out[uid] = true
    }
    return out
  }, {})
}
