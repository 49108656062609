import React, { useState } from "react"
import styled from "styled-components"
import apollo from "../lib/apollo"
import gql from "graphql-tag"
import { Colors, media } from "../constants/Style"
import { lighten } from "polished"
import Button from "./Button"
import getCodeFromNetworkError from "../helpers/getCodeFromNetworkError"

const Shell = styled.form`
  width: 100%;
  padding: 3rem;
  background: ${lighten(0.01, Colors.veryLightBlue)};
  && h3 {
    margin-top: 0;
    padding-top: 0;
    color: ${Colors.darkBlue};
  }
  label {
    display: block;
    font-weight: 600;
    margin-bottom: 0.05rem;
  }
  input,
  textarea {
    padding: 0.6rem;
    font-size: 1.1rem;
    margin-bottom: 1rem;
    border: 1px solid ${lighten(0.48, Colors.darkBlueGray)};
    width: 50%;
  }
  textarea {
    width: 100%;
  }
  ${media.hand`
    padding: 2rem 1.6rem;
    margin-bottom: 1.5rem;
    && h2 {
      margin-bottom: -0.7rem;
    }
    &&& h4, &&& h3 {
      margin-bottom: 1rem;
      margin-top: 0;
    }
  `}
`
const Error = styled.div`
  padding: 2rem;
  background: ${Colors.red};
  color: #fff;
  margin-top: 2rem;
`
const Success = styled.div`
  padding: 2rem;
  background: ${Colors.green};
  color: #fff;
  margin-bottom: 2rem;
`

const submitQuestionMutation = gql`
  mutation CreateQuestion($input: CreateQuestionInput!) {
    createQuestion(input: $input) {
      clientMutationId
    }
  }
`
let errorTimo = 0
const submitQuestion = async (form, formState, setFormState, e) => {
  clearTimeout(errorTimo)
  e.preventDefault()
  e.stopPropagation()
  let error = false
  if (!form.question || !form.question.length > 20) {
    error = "You'll need to add some content to your question"
  }
  if (!form.email || !form.email.length) {
    error = "You'll need to add your email so we can get back to you"
  }
  if (!form.name || !form.name.length) {
    error = "You'll need to add your name"
  }
  if (error) {
    setFormState({ error })
    errorTimo = setTimeout(() => {
      setFormState("ready")
    }, 4400)
  } else {
    if (formState !== "process") {
      setFormState("process")
      try {
        form.uri = window.location.pathname
        form.ipaddress = "192.168.1.255"
        await apollo.mutate({
          mutation: submitQuestionMutation,
          variables: { input: { question: form } },
        })
        setFormState("success")
      } catch (e) {
        const code = getCodeFromNetworkError(e.message)
        setFormState({
          error:
            code === 429
              ? "Wait a bit before submitting another question"
              : "There was a server issue, our team has been notified",
        })
      }
    }
  }
}
export default () => {
  const [form, setForm] = useState({ question: "", ipaddress: "", name: "", email: "" })
  const [formState, setFormState] = useState("ready")
  const btnText = formState === "process" ? "Submitting..." : "Submit Question"
  if (formState === "success") {
    return (
      <Success>
        Your question was successfully submitted! Our team will review your submission and respond as soon as possible.
      </Success>
    )
  }
  return (
    <Shell onSubmit={e => submitQuestion(form, formState, setFormState, e)}>
      <h3>Ask a Question</h3>
      <div>
        <label id="qna-name">Your Name</label>
        <input
          value={form.name}
          aria-labelledby="qna-name"
          placeholder="Your Name"
          onChange={({ target: { value } }) => setForm({ ...form, name: value })}
        />
      </div>
      <div>
        <label id="qna-email">Your Email</label>
        <input
          value={form.email}
          placeholder="Your Email"
          aria-labelledby="qna-email"
          onChange={({ target: { value } }) => setForm({ ...form, email: value })}
        />
      </div>
      <div>
        <label id="qna-content">Your Question</label>
        <textarea
          placeholder="Your Question"
          aria-labelledby="qna-content"
          value={form.question}
          onChange={({ target: { value } }) => setForm({ ...form, question: value })}
        />
      </div>
      <Button aria-label="Submit Your Question">{btnText}</Button>
      {formState.hasOwnProperty("error") && <Error>{formState.error}</Error>}
    </Shell>
  )
}
