import React from "react"
import styled from "styled-components"
import prismicSerializer from "../../helpers/prismicSerializer"
import ListFromText from "../ListFromText"
import { media } from "../../constants/Style"
import Row from "../../Row"

const Shell = styled.div`
  padding: 0rem;
  margin-bottom: 2.5rem;
  .row {
    justify-content: space-between;
    div:first-of-type {
      margin-right: 1rem;
      padding-right: 2rem;
    }
    ${media.hand`flex-direction: column;`}
  }
  && h2 {
    margin-top: 0;
    padding-top: 0;
  }
  &&& ul {
    padding: 0px 25px;
    list-style-type: none;
    margin: 0;
    li {
      list-style-type: none;
      position: relative;
      .icon {
        position: absolute;
        top: 6px;
        left: -28px;
      }
      margin-bottom: 0;
    }
    li:last-child {
      margin-bottom: 0;
    }
  }
  && h4 {
    font-size: 1.3rem;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
  }
`

const Column = styled.div``
export default ({ primary, items }) => {
  const pros = items
    .filter(item => item.type === "pro")
    .map(i => i.description)
    .join("\n")
  const cons = items
    .filter(item => item.type === "con")
    .map(i => i.description)
    .join("\n")

  return (
    <Shell>
      {prismicSerializer(primary.header)}
      <Row>
        {items && items.length > 0 ? (
          <>
            <Column>
              <h4>Pros</h4>
              <ListFromText text={pros} className="pros" />
            </Column>
            <Column>
              <h4>Cons</h4>
              <ListFromText text={cons} className="cons" />
            </Column>
          </>
        ) : null}
      </Row>
    </Shell>
  )
}
