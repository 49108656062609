import React from "react"
import styled from "styled-components"
import Contain from "./Contain"
import prismicSerializer from "../helpers/prismicSerializer"
import AspectImage from "./AspectImage"
import { Colors, media } from "../constants/Style"

const Shell = styled(Contain)`
  section {
    display: flex;
    flex-wrap: wrap;
    padding-right: 0;
  }
`
const MemberShell = styled.div`
  width: calc(33% - 5rem);
  margin-right: 5rem;
  margin-bottom: 5rem;
  ${media.aboveTablet`
  &:nth-of-type(3n) {
    margin-right: 0;
  }
  `}
  &&&& {
    h4 {
      font-size: 1.4rem;
      margin-bottom: 0.25rem;
      padding-bottom: 0;
    }
    h5 {
      padding-top: 0;
      margin-top: 0;
      font-size: 1.05rem;
      color: ${Colors.darkBlueGray};
      opacity: 0.7;
      font-weight: normal;
    }
    p {
      font-size: 0.94rem;
    }
  }
  .photo {
    max-height: 17.5rem;
    overflow: hidden;
    margin-bottom: 1.2rem;
    .aspectImage {
      margin-top: 0rem;
    }
  }
  ${media.tablet`
    margin-right: 2.5rem;
    margin-bottom: 2.5rem;
    width: calc(50% - 1.25rem);
    &:nth-of-type(2n) {
      margin-right: 0;
    }
  `}
  ${media.hand`
    margin-right: 0;
    margin-bottom: 2.5rem;
    width: 100%;
    &:nth-of-type(2n) {
      margin-right: 0;
    }
  `}
`

const Member = ({ firstName, lastName, bio, photo, role }) => {
  return (
    <MemberShell>
      <div className="photo">
        <AspectImage {...photo} useVariant="600" />
      </div>
      <h4>
        {firstName} {lastName}
      </h4>
      <h5>{role}</h5>
      <div>{prismicSerializer(bio)}</div>
    </MemberShell>
  )
}

export default ({ members }) => {
  const order = ["rose", "hannah", "bethUel", "darren"]
  const ms = order.map(k => members[k])
  return (
    <Shell>
      <h3>Our Team</h3>
      <section>{members && ms.map(member => <Member {...member} />)}</section>
    </Shell>
  )
}
