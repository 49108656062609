import React, { useEffect } from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
import MainContent from "../components/MainContent"
import CompanyPage from "./CompanyPage"
import ReviewsPage from "./ReviewsPage"
import HomePage from "./HomePage"
import DealPage from "./DealPage"
import Cards from "../components/Cards"
import HubPage from "./HubPage"
import ContactForm from "../components/ContactForm"
import Team from "../components/Team"
import LinkMap from "../components/LinkMap"
import Breadcrumbs from "../components/Breadcrumbs"
import ComparisonPage from "./ComparisonPage"
import CityPage from "./CityPage"
import ReportPage from "./ReportPage"
import { Colors } from "../constants/Style"
import CreateFinderSearchMutation from "../graph/mutations/CreateFinderSearchMutation"
import apollo from "../lib/apollo"

// const submitZip = async (e, zip) => {
//   e.preventDefault()
//   e.stopPropagation()
// }

const Highlight = styled.div`
  padding: 1rem;
  background: ${Colors.lightBrightBlue};
  color: ${Colors.darkBlue};
  margin-bottom: 1.2rem;
  width: 100%;
`

export default props => {
  let showRelated = true
  const pass = { ...props }
  const { page, crumbs, location } = props
  let { type, h1, pageTitle, template, uid } = page
  let Component = MainContent
  // const underByline = ""

  // Preprocess + Set Templates
  if (uid === "best" && location.state && location.state.cityByCityId) {
    const city = `${location.state.cityByCityId.name}, ${location.state.cityByCityId.stateByStateId.name}`
    pass.before = (
      <Highlight>
        Find our recommendations for <b>{city}</b> below:
      </Highlight>
    )
  } else if (uid === "best" && location.hash.length > 1) {
    useEffect(() => {
      const zip = location.hash.substr(1)
      apollo
        .mutate({
          mutation: CreateFinderSearchMutation,
          variables: { input: { finderSearch: { zip, ipaddress: "192.168.1.255" } } },
        })
        .then(res => {
          navigate(`/home-security/best/#${zip}`, { state: res.data.createFinderSearch })
        })
    }, [])
  }
  if (uid === "contact") {
    pass.before = <ContactForm />
    pass.narrow = true
  }
  if (uid === "mission") {
    pass.after = <Team members={page.authors} />
    pass.narrow = true
  }
  if (type === "company") {
    if (template === "reviews") Component = ReviewsPage
    else if (template === "deals") Component = DealPage
    else Component = CompanyPage
  }
  if (type === "sitepage") {
    if (uid === "home") {
      Component = HomePage
    }
  }
  if (template === "auto_city") {
    Component = CityPage
  }
  if (template === "research") {
    Component = ReportPage
  }
  if (template === "comparison") {
    Component = ComparisonPage
  }
  if (template === "hubpage") {
    Component = HubPage
    showRelated = false
    if (uid === "safety") {
      pass.before = (
        <div style={{ width: "150%" }}>
          <LinkMap />
        </div>
      )
    }
  }
  props.page.h1 = h1 || pageTitle
  return (
    <>
      <Component {...pass} />
      {showRelated && page.related && page.related.length > 0 && <Cards pages={page.related} title="Related Pages" />}
      <Breadcrumbs crumbs={crumbs} />
    </>
  )
}
