import styled from "styled-components"
import BaseCtaButton from "./BaseCTAButton"

export default styled(BaseCtaButton)`
  background: linear-gradient(to bottom, rgba(0, 99, 204, 1) 0%, rgba(0, 68, 145, 1) 100%);
  border-radius: 8px;
  border-bottom: 2px solid #002447;
  text-align: center;
  font-size: 1.55rem !important;
  text-decoration: none;
  padding: 2.1rem 2rem 0.65rem !important;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
  color: #fff;
  position: relative;
  &:hover {
    filter: brightness(1.15);
  }
  &:before {
    position: absolute;
    width: 100%;
    content: "Call for Free Quote";
    letter-spacing: 0.2px;
    top: 0.8rem;
    font-weight: 600;
    left: 0;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
    font-size: 1.1rem;
  }
`
