function formatPhoneNumber(phone) {
  if (!phone) return ""
  var cleaned = `${phone.indexOf("1-") === 0 ? "" : "1-"}${phone.replace("+1", "")}`.replace(/\D/g, "")
  var match = cleaned.match(/(\d)(\d{3})(\d{3})(\d{4})/)
  if (match) {
    return "(" + match[2] + ") " + match[3] + "-" + match[4]
  }
  return ""
}
exports.formatPhoneNumber = formatPhoneNumber
