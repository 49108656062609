import React, { useEffect } from "react"
import styled from "styled-components"
import dayjs from "dayjs"

const Table = styled.table`
  margin-bottom: 0;
  th {
    width: 6rem;
  }
  textarea {
    padding: 0.5rem;
    border: #eee;
    width: 100%;
    font-size: 0.9rem;
    line-height: 132%;
    resize: none;
  }
`

export default ({ author: { firstName, lastName }, title, dateModified, permalink }) => {
  const modified = dayjs(dateModified)
  const now = dayjs()
  const mlaDate = "DD MMM. YYYY."
  const apaDate = "YYYY, MMMM YY"
  const chicagoDate = "MMMM D, YYYY"

  // Selects text on mouse over for easy copying
  const handleMouseOver = e => {
    e.currentTarget.select()
  }

  // Size textareas to fit once loaded
  useEffect(() => {
    setTimeout(() => {
      const textareas = document.querySelectorAll(".citation-table textarea")
      textareas.forEach(t => {
        t.style.height = ""
        t.style.height = t.scrollHeight + 3 + "px"
      })
    }, 300)
  }, [])

  return (
    <Table className="citation-table">
      <tr>
        <th>HTML</th>
        <td>
          <textarea
            value={`<a href="${permalink}" title="${title} by ${firstName} ${lastName} of HowSafe.org">${title}</a>`}
            onMouseOver={handleMouseOver}
            onInput=""
          />
        </td>
      </tr>
      <tr>
        <th>MLA</th>
        <td>
          <textarea
            value={`${lastName}, ${firstName}. "${title}" HowSafe.org. HowSafe.org, ${modified.format(
              mlaDate
            )} Web. Accessed ${now.format(mlaDate)} <${permalink}>.`}
            onMouseOver={handleMouseOver}
            onInput=""
          />
        </td>
      </tr>
      <tr>
        <th>APA</th>
        <td>
          <textarea
            value={`${lastName} , ${firstName[0]}. (${modified.format(apaDate)}). ${title} Retrieved from ${permalink}`}
            onMouseOver={handleMouseOver}
          />
        </td>
      </tr>
      <tr>
        <th>Chicago</th>
        <td>
          <textarea
            value={`${lastName}, ${firstName}. ${title} HowSafe.org. Last modified ${modified.format(
              chicagoDate
            )}. Accessed ${now.format(chicagoDate)}. ${permalink}.`}
            onMouseOver={handleMouseOver}
          />
        </td>
      </tr>
    </Table>
  )
}
