import React, { useState } from "react"

import styled from "styled-components"
import { IoIosArrowDown } from "react-icons/io"
import { Colors, media } from "../constants/Style"
import { lighten } from "polished"
import Link from "./Link"

const A = styled(Link)`
  position: relative;
  /* height: 4rem; */
  /* top: 1.33rem; */
  cursor: pointer;

  ${media.tablet`
    /* height: auto; */
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  `}
`
const Div = styled.div`
  position: relative;
  /* height: 4rem; */
  /* top: 1.33rem; */
  cursor: pointer;
  ${media.tablet`
    margin-bottom: 1.6rem;
    text-align: center;
    /* height: auto; */
    /* justify-content: center; */
    /* display: flex; */
    /* flex-direction: column; */
    /* align-items: center; */
  `}
`
const Wrap = styled.div`
  position: absolute;
  width: 230px;
  opacity: 0;
  top: 36px;
  left: -99999px;
  z-index: 5;
  padding: 1.2rem;
  transform: translateY(0px);
  transition: opacity 0.15s 0s, transform 0.15s 0s, left 0s 0.4s, max-height 0.15s, padding 0.15s;
  border: 1px solid ${lighten(0.45, Colors.darkBlueGray)};
  background: #fff;
  &.open {
    transition: opacity 0.15s 0s, transform 0.15s 0s, left 0s 0s, max-height 0.15s, padding 0s;
    left: 0;
    transform: translateY(-6px);
    opacity: 1;
  }
  ul {
    margin: 0;
    padding: 0;
  }
  li {
    margin-bottom: 1rem;
    list-style-type: none;
    a {
      line-height: 1.5rem;
      font-size: 1.05rem !important;
      color: ${Colors.lightBlue};
      text-decoration: none;
      display: inline;
      border-bottom: 2px solid ${lighten(0.45, Colors.darkBlueGray)};
      transition: 0.2s all;
      outline: none;
      user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      -webkit-touch-callout: none;
      -o-user-select: none;
      -moz-user-select: none;
      &:hover {
        border-bottom: 2px solid ${lighten(0.1, Colors.lightBlue)};
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  ${media.tablet`
    position: relative;
    left: 0;
    top: 15px;
    opacity: 1;
    border: 0;
    margin-top: 0;
    background: ${lighten(0.55, Colors.darkBlueGray)}
    max-height: 0;
    overflow: hidden;
    padding-top: 0;
    padding-bottom: 0;
    box-sizing: content-box;
    
    ul {
      padding: 1.2rem 0;
    }
    top: 0;
    &.open {
      max-height: 1000px;
      transform: translateY(0);
      top: 0;
    }
  `}
`
const Label = styled.div.attrs({ className: "droplink-label" })`
  display: flex;
  align-items: center;
  span {
    margin-right: 6px;
  }
  ${media.tablet`
    &.open-submenu svg {
      transform: rotate(180deg);
      transition: transform 200ms;
    }
  `}
`

function Droplink({ to, children, text }) {
  let mouseTimo = 0
  const [open, setOpen] = useState(false)
  const Component = to ? A : Div
  const toggleOpen = e => {
    e.preventDefault()
    setOpen(!open)
  }
  const mouseOver = e => {
    if (window.innerWidth > 1238) {
      clearTimeout(mouseTimo)
      setOpen(true)
    }
  }
  const mouseOut = e => {
    if (window.innerWidth > 1238) {
      mouseTimo = setTimeout(() => setOpen(false), 20)
    }
  }
  return (
    <Component to={to} onClick={toggleOpen} onMouseOver={mouseOver} onMouseOut={mouseOut}>
      <Label className={open ? "open-submenu" : "closed-submenu"}>
        <span>{text}</span> <IoIosArrowDown color={Colors.lightBlue} size="23px" />
      </Label>
      <Wrap className={open ? "open" : "closed"}>{children}</Wrap>
    </Component>
  )
}
export default Droplink
