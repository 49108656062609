import React from "react"
import styled from "styled-components"
import { media, Colors } from "../constants/Style"
import processStringToReact from "../helpers/processStringToReact"
import Col from "../Col"
import Contain from "./Contain"
import Link from "./Link"

const Shell = styled(Col)`
  position: relative;
  width: 100%;
  margin: 2rem auto 0;
  background: ${Colors.darkBlue};
  padding: 4rem 0 3.2rem;
  justify-content: space-between;
  align-items: center;
  ul {
    list-style-type: none;
  }

  .legal,
  .copyright {
    font-size: 0.9rem;
    padding: 0.8rem 0;
    color: ${Colors.lightBlue};
    margin: 0 auto;
  }
  ${media.tablet`
    width: 100%;
    padding-left: 2rem;
  `}
  ${media.hand`
    padding: 1rem
    nav a {
      margin-top: 0.8rem;
      font-size: 0.8rem;
      border-bottom: 0;
      text-decoration: underline;
      margin-right: 0.5rem;
      &:last-child {
        margin-right: 0;
      }
    }
    ${Contain} {
      padding-left: 0;
    }
    .legal,
    .copyright {
      padding: 0.2rem
    }
  `}
`

const LinkCols = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 2.2rem;
  border-bottom: 1px solid #fffee940;
  padding-bottom: 2.2rem;
  p {
    color: #fff;
    font-size: 1.15rem;
  }
  nav {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    a {
      color: ${Colors.white};
      border-bottom: 2px solid ${Colors.lightBlue};
      text-decoration: none;
      font-size: 1rem;
      display: inline-block;
      text-align: left;
      padding: 0 0 0.1rem 0;
      margin-right: 0rem;
      margin-bottom: 1rem;
      opacity: 0.7;
      transition: 0.2s all;
      &:hover {
        opacity: 1;
      }
    }
  }
  ${media.hand`
    flex-direction: column;
    p {
      margin-top: 3rem;
      margin-bottom: 0.5rem;
      text-align: center;
      width: 100%;
    }
    && nav {
      justify-content: center;
      align-items: center;
      a {
        text-align: center
        margin-right: 0;
      }
    }
  `}
`

export default ({ footerLinksAndHtml, footerLegalese, copyrightText }) => {
  return (
    <Shell>
      <Contain>
        <LinkCols>
          <nav>
            <p>Reviews</p>
            {/*FRONTPOINTREMOVAL <Link to="/home-security/frontpoint/reviews">Frontpoint Review</Link> */}
            <Link to="/home-security/adt/reviews">ADT Review</Link>
            <Link to="/home-security/vivint/reviews">Vivint Review</Link>
            <Link to="/home-security/brinks/reviews">Brinks Home Security Review</Link>
            <Link to="/home-security/simplisafe/reviews">Simplisafe Review</Link>
          </nav>
          <nav>
            <p>Guides</p>
            <Link to="/home-security/best">Best Home Security Systems</Link>
            <Link to="/home-security/">Home Security Guide</Link>
            <Link to="/smart-home">Smart Home Guide</Link>
            <Link to="/security-cameras">Security Camera Guide</Link>
            <Link to="/safety">Nationwide State Safety</Link>
          </nav>
          <nav>
            <p>Top Providers</p>
            {/*FRONTPOINTREMOVAL <Link to="/home-security/frontpoint">Frontpoint</Link> */}
            <Link to="/home-security/adt">ADT</Link>
            <Link to="/home-security/vivint">Vivint</Link>
            <Link to="/home-security/brinks">Brinks Home Security</Link>
            <Link to="/home-security/simplisafe">Simplisafe</Link>
          </nav>
          <nav>
            <p>HomeAlarmReport</p>
            {processStringToReact(footerLinksAndHtml)}
          </nav>
        </LinkCols>
        <div className="legal">{processStringToReact(footerLegalese)}</div>
        <div className="copyright">{processStringToReact(copyrightText)}</div>
      </Contain>
    </Shell>
  )
}
