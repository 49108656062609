import React from "react"

export default ({ primary, items }) => (
  <>
    {items.length < 1 ? null : (
      <ul>
        {items.map(item => (
          <li>
            {item.update} - <small>{item.date}</small>
          </li>
        ))}
      </ul>
    )}
  </>
)
