import React, { useState } from "react"
import styled from "styled-components"
import { lighten } from "polished"
import { navigate } from "gatsby"
import girl_left_stretched_img from "../images/girl-looking-left-stretched.jpg"
import girl_left_img from "../images/girl-looking-left.jpg"
import girl_left_lowres_img from "../images/girl-looking-left-lowres.jpg"
import Contain from "./Contain"
import { Colors, media } from "../constants/Style"
import Link from "./Link"
import SvgIcon from "./SVGIcon"
import apollo from "../lib/apollo"
import CreateFinderSearchMutation from "../graph/mutations/CreateFinderSearchMutation"

const submitZip = async (e, zip) => {
  e.preventDefault()
  e.stopPropagation()
  const res = await apollo.mutate({
    mutation: CreateFinderSearchMutation,
    variables: { input: { finderSearch: { zip, ipaddress: "192.168.1.255" } } },
  })
  navigate(`/home-security/best/#${zip}`, { state: res.data.createFinderSearch })
}

export default () => {
  const [zipcode, setZipcode] = useState("")
  return (
    <Contain>
      <Shell>
        <Blur />
        <Content>
          <div>
            <p className="zipHead">The FBI reports that a property crime occurs in the US every 4.1 seconds.</p>
            <p>Enter your zip code below to get a report on our safety recommendations for your area.</p>
            <form onSubmit={e => submitZip(e, zipcode)}>
              <input
                type="text"
                placeholder="Enter Your Zip Code"
                value={zipcode}
                onChange={e => setZipcode(e.currentTarget.value)}
              />
              <button>Get Report</button>
            </form>
          </div>
        </Content>
      </Shell>
      <LinkBlocks>
        <LinkBlock to="/home-security">
          <div className="icon">
            <SvgIcon icon="homelock" />
          </div>
          Home Security
        </LinkBlock>
        <LinkBlock to="/security-cameras">
          <div className="icon">
            <SvgIcon icon="camera" yshift={0} />
          </div>
          Security Cameras
        </LinkBlock>
        <LinkBlock to="/smart-home">
          <div className="icon">
            <SvgIcon icon="brain" yshift={0} />
          </div>
          Smart Home
        </LinkBlock>
        <LinkBlock to="/safety">
          <div className="icon">
            <SvgIcon icon="usflag" yshift={1} />
          </div>
          State Safety
        </LinkBlock>
      </LinkBlocks>
    </Contain>
  )
}

const LinkBlocks = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 0.5rem;
  margin-top: -1rem;
  margin-left: auto;
  margin-right: auto;
  width: 84%;

  ${media.tablet`
    width: 98%;
  `}
  ${media.hand`
    flex-direction: column;
    justify-content: center;
  `}
`
const LinkBlock = styled(Link)`
  padding: 1rem;
  background: ${Colors.white};
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.07);
  color: ${Colors.darkBlueGray};
  text-decoration: none;
  font-style: italic;
  position: relative;
  z-index: 4;
  width: 12rem;
  text-align: center;
  transition: all 0.2s 0s;
  transform: translateY(0px);
  &:hover {
    transform: translateY(-4px);
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.07);
  }

  .icon {
    background: ${Colors.canary};
    border: 2px solid ${Colors.canvas};
    width: 4.2rem;
    height: 4.2rem;
    border-radius: 50%;
    margin: 0 auto 0.5rem;

    svg {
      fill: ${lighten(0.2, Colors.brown)};
      width: 100%;
      max-height: 2.5rem;
      margin-top: 0.655rem;
    }
  }
  ${media.tablet`
    width: 11.2rem;
  `}
  ${media.hand`
    width: 100%;
    display: flex;
    align-items: center;
    text-align: left;
    .icon {
      margin: 0 1rem 0 0;
      width: 3.3rem;
      height: 3.3rem;
      svg {
        max-height: 1.7rem;
      }
    }
  `}
`

const Content = styled.div`
  display: flex;
  position: relative;
  z-index: 3;
  width: 50%;
  height: 100%;
  padding: 3rem;
  align-items: center;

  .zipHead {
    color: ${Colors.darkBlue};
    font-size: 1.8rem;
    padding: 0;
    margin: 0 0 1.45rem;
    font-weight: 700;
    text-rendering: optimizeLegibility;
    line-height: 1.1;
  }
  form {
    height: 3rem;
    width: 100%;
    display: flex;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
  input {
    display: block;
    border: 2px solid ${Colors.darkBlue};
    border-width: 2px 0 2px 2px;
    padding: 0.7rem;
    height: 100%;
    border-radius: 0;
    font-size: 1rem;
    flex: 1;
    -webkit-appearance: none;
  }
  input[type="color"],
  input[type="date"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="email"],
  input[type="month"],
  input[type="number"],
  input[type="password"],
  input[type="search"],
  input[type="tel"],
  input[type="text"],
  input[type="time"],
  input[type="url"],
  input[type="week"],
  select:focus,
  textarea {
    font-size: 16px;
  }
  button {
    display: block;
    background: ${Colors.darkBlue};
    border: 0;
    height: 100%;
    color: ${Colors.white};
    flex: 0.7;
    cursor: pointer;
    border-radius: 0;
    font-size: 1rem;
    -webkit-appearance: none;
  }
  ${media.hand`
    padding: 2rem;
    width: 100%;
  `}
`

const Shell = styled.div`
  position: relative;
  background: url(${girl_left_stretched_img});
  width: 100%;
  height: 21.5rem;
  background-size: cover;
  overflow: hidden;
  ${media.tablet`
    background: url(${girl_left_img});
    background-size: 100% auto;
  `}
  ${media.hand`
    background: url(${girl_left_lowres_img});
    margin-top: 0.8rem;
    background-size: cover;
  `}
`

const Blur = styled.div`
  overflow: hidden;
  height: calc(100%);
  width: calc(50%);
  position: absolute;
  left: 0px;
  top: 0px;

  &:before {
    height: calc(100% + 30px);
    width: calc(100% + 30px);
    background: url(${girl_left_stretched_img});
    position: absolute;
    left: -20px;
    top: -20px;
    content: "";
    filter: blur(10px);
    z-index: 1;
    ${media.hand`
    background: url(${girl_left_lowres_img});
    background-size: cover;
  `}
  }
  &:after {
    height: 100%;
    width: 100%;
    background: #fff;
    position: absolute;
    left: 0px;
    top: 0px;
    content: "";
    z-index: 2;
    opacity: 0.4;
  }
  ${media.hand`
    width: 100%;
  `}
`
