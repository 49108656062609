import React from "react"
import styled from "styled-components"
import { lighten } from "polished"
import { Colors, Dims, media } from "../constants/Style"
import flag from "../images/small-flag.png"
import Link from "./Link"
import Row from "../Row"
import withFilteredProps from "../lib/withFilteredProps"

const MissionBar = styled.div`
  background: ${lighten(0.011, Colors.veryLightBlue)};
  color: ${Colors.lightBlue};
  padding: 0.3rem 0 0.27rem 0rem;
  font-size: 0.85rem;
  border-bottom: 2px solid ${lighten(0.35, Colors.lightBlue)};
  font-weight: 100;
  ${media.tablet`
    font-size: 0.8rem;
  padding: 0.46rem 0 0.43rem 0rem;
  `}
`

const FilteredLink = withFilteredProps(Link, ["isPrimary"])
const Button = styled(FilteredLink)`
  font-size: 0.9rem;
  text-decoration: none;
  padding: 0.2rem 0.6rem;
  background: ${({ isPrimary }) => (isPrimary ? lighten(0.29, Colors.lightBlue) : "transparent")};
  opacity: ${({ isPrimary }) => (isPrimary ? "1" : "0.7")};
  color: ${Colors.lightBlue};
  margin-right: 0.2rem;
  color: ${Colors.darkBlueGray};
  text-align: center;
  &:last-of-type {
    margin-right: 0;
  }
  ${media.tablet`
    font-size: 0.7rem;
    padding: 0.24rem 0.3rem 0.18rem;
  `}
`
const Content = styled.div`
  width: ${Dims.container};
  margin: 0 auto;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 0.8rem;
  justify-content: space-between;
  line-height: 145%;
  .flag {
    flex: none;
    width: 25px;
    height: 14px;
    background-image: url(${flag});
    background-size: cover;
    margin-right: 0.875rem;
    margin-top: 0.065rem;
  }
  ${media.tablet`
    width: 100%;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    line-height: 100%;
    span {
    padding-right: 2rem;
    }
    .flag {
      margin-right: 0.4rem;
      margin-top: 2px;
    }
  `}
  ${media.hand`
      font-size: 0.7rem;
      .flag {
        display: none;
      }
  `}
`
const Buttons = styled(Row)`
  ${media.hand`
    flex-direction: column-reverse;
  `}
`

export default ({ disclosureBarText }) => {
  return (
    <MissionBar>
      <Content>
        <Row>
          <div className="flag" />
          <span>{disclosureBarText}</span>
        </Row>
        <Buttons>
          <Button to="/disclosure/">Disclosure</Button>
          <Button to="/mission/" isPrimary="true">
            Mission
          </Button>
        </Buttons>
      </Content>
    </MissionBar>
  )
}
