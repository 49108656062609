import React, { useState } from "react"
import styled from "styled-components"
import Row from "../Row"
import MissionBar from "./MissionBar"
import { Colors, Dims, media } from "../constants/Style"
import img_Logo from "../images/logo-wordmark.png"
import img_LogoStacked from "../images/logo-stacked.png"
import { lighten } from "polished"
import Droplink from "./Droplink"
import Link from "./Link"

const Shell = styled(Row)`
  position: relative;
  width: ${Dims.container};
  margin: 0 auto;
  padding: 0.6rem 0rem 0.6rem;
  justify-content: space-between;
  align-items: center;
  ul {
    list-style-type: none;
  }

  ${media.tablet`
    width: 100%;
    padding: ${Dims.tabletPadding};
    padding-top: 0.9rem;
  `}
  ${media.hand`
    width: 100%;
    padding: ${Dims.handPadding};
    padding-top: 0.6rem;
  `}
`
const Logo = styled(Link)`
  display: flex;
  font-size: 0;
  color: rgba(0, 0, 0, 0) !important;
  text-decoration: none;
  font-weight: 700;
  letter-spacing: 0.6px;
  position: relative;
  align-items: center;
  span {
    margin-top: -2px;
    color: ${Colors.lightBlue};
    span {
      color: ${Colors.darkBlue};
    }
  }
  position: relative;
  width: 20rem;
  height: 2.8125rem;
  display: block;
  img {
    object-fit: cover;
    max-width: 100%;
    max-height: 100%;
  }
  margin-right: 1rem;
  top: 0px;
  ${media.tablet`
    font-size: 1.8rem;
    top: -7px;
    &:before {
      width: 3.6rem;
      height: 3.4rem;
      margin-right: 0.65rem;
    }
  `}
  ${media.hand`
    width: 14rem;
    height: 2.325rem;
    top: 2px;
  `}
`
// const Input = styled.input`
//   color: ${Colors.darkBlueGray};
//   background: ${lighten(0.01, Colors.veryLightBlue)};
//   padding: 10px 12px 9px;
//   font-size: 1rem;
//   width: 14rem;
//   text-align: center;
//   border: 2px solid ${lighten(0.3, Colors.lightBlue)};
// `
const Links = styled.div`
  display: flex;
  align-items: center;
  > a,
  .droplink-label {
    font-size: 1.05rem;
    margin-right: 2.25rem;
    color: ${Colors.lightBlue};
    text-decoration: none;
  }
  > div:last-child {
    .droplink-label {
      margin-right: 0;
    }
  }
  ${media.tablet`
    position: fixed;
    top: 0;
    right: 0;
    padding-top: 100px;
    height: 100%;
    width: 50%;
    background: #fff;
    align-items: flex-start;
    flex-direction: column;
    z-index: 10;
    box-shadow: -10px 0px 20px rgba(0, 0, 0, 0.05);  
    transform: translateX(50vw);
    transition: 0.1s all;
    #header-search {
      margin-top: 48px;
      width: 200px;
      font-size: 1.1rem;
      text-align: center;
    }
    > a, > div {
      width: 100%;
      margin-bottom: 0;
    }
    > div > div {
      width: 100%;
      margin-left: 0;
      padding-left: 0;
      transform: translateY(0px);
      top: 0px;
      margin-bottom: 0;
      li {
        border-bottom: 1px solid ${lighten(0.49, Colors.darkBlueGray)};
        padding: 0;
        margin-bottom: 0;
      }
      a {
        border:0;
        display: block;
        margin: 0;
        margin-bottom: 3px;
        padding: 0.85rem 0 0.65rem;
        &:hover {
          border: 0;
        }
      }
      ul {
        margin: 0;
        padding: 0;
      
      }
    }
    > a,
    .droplink-label {
      margin-right: 0;
      /* height: 2.5rem; */
      display: block;
      width: 100%;
      border-bottom: 1px solid ${lighten(0.45, Colors.darkBlueGray)};
      padding: 1.4rem 0;
      text-align: center;
      span, svg {
        display: inline-block;
        vertical-align: middle;
      }
    }
    &.is-open {
      transform: translateX(0);
      right: 0px;
    }
  `}
  ${media.hand`
    width: 100%;
    transform: translateX(100vw);
  `}
`
const HamburgerShell = styled.button`
  display: none;
  ${media.tablet`
    padding: 15px 15px;
    display: inline-block;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
    z-index: 11;
    position: absolute;
    top: .75rem;
    right: 1.3rem;
    outline: none;
    user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -webkit-touch-callout: none;
    -o-user-select: none;
    -moz-user-select: none;

    .hamburger-box {
        width: 36px;
        height: 24px;
        display: inline-block;
        position: relative; 
        outline: none;
        user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        -webkit-touch-callout: none;
        -o-user-select: none;
        -moz-user-select: none;
    }
    .hamburger-inner, .hamburger-inner::after, .hamburger-inner::before {
        position: absolute;
        width: 36px;
        height: 3px;
        transition-timing-function: ease;
        transition-duration: .15s;
        transition-property: transform;
        background-color: ${Colors.darkBlue};
    }
    .hamburger-inner::before, .hamburger-inner::after {
      content: "";
      display: block; 
    }
    .hamburger-inner::before { top: -8px; }
    .hamburger-inner::after { bottom: -8px; }
    &:hover {
      opacity: 0.9; 
    }
    &.is-open:hover {
      opacity: 0.9; 
    }
    &.is-open .hamburger-inner,
    &.is-open .hamburger-inner::before,
    &.is-open .hamburger-inner::after {
      background-color: ${Colors.darkBlue}; 
    }
    .hamburger-inner {
      transition-duration: 0.075s;
      transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    .hamburger-inner::before {
      transition: top 0.075s 0.12s ease, opacity 0.075s ease; 
    }
    .hamburger-inner::after {
      transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19); 
    }
    &.is-open .hamburger-inner {
      transform: rotate(45deg);
      transition-delay: 0.12s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
    &.is-open .hamburger-inner::before {
      top: 0;
      opacity: 0;
      transition: top 0.075s ease, opacity 0.075s 0.12s ease; }
    &.is-open .hamburger-inner::after {
      bottom: 0;
      transform: rotate(-90deg);
      transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); 
    }
 
  `}
  ${media.hand`
    top: 0.8rem;
    right: 1.2rem;
  `}
`
const Hamburger = props => {
  return (
    <HamburgerShell {...props}>
      <span class="hamburger-box">
        <span class="hamburger-inner" />
      </span>
    </HamburgerShell>
  )
}
export default ({ disclosureBarText }) => {
  const [open, setOpen] = useState(false)
  const openClass = open ? "is-open" : "is-closed"
  return (
    <>
      <MissionBar disclosureBarText={disclosureBarText} />
      <Shell>
        <div>
          <Logo to="/">
            <picture>
              <source media="(max-width: 650px)" srcSet={img_LogoStacked} />
              <img src={img_Logo} alt="HomeAlarmReport Logo" title="HomeAlarmReport Logo" />
            </picture>
          </Logo>
        </div>
        <Hamburger aria-label="Toggle Menu" className={openClass} onClick={() => setOpen(!open)} />
        <Links className={openClass}>
          <Link to="/home-security/best/">Best Security Systems</Link>
          <Droplink text="Reviews">
            <ul>
              <li>
                <Link to="/home-security/adt/reviews/">ADT Review</Link>
              </li>
              <li>
                <Link to="/home-security/alder/reviews/">Alder Review</Link>
              </li>
              {/* FRONTPOINTREMOVAL <li>
                <Link to="/home-security/frontpoint/reviews/">Frontpoint Review</Link>
              </li> */}
              <li>
                <Link to="/home-security/guardian-protection-services/reviews/">Guardian Review</Link>
              </li>
              <li>
                <Link to="/home-security/protect-america/reviews/">Protect America Review</Link>
              </li>
              <li>
                <Link to="/home-security/simplisafe/reviews/">Simplisafe Review</Link>
              </li>
              <li>
                <Link to="/home-security/vivint/reviews/">Vivint Review</Link>
              </li>
            </ul>
          </Droplink>
          <Droplink text="Guides">
            <ul>
              <li>
                <Link to="/home-security/">Home Security</Link>
              </li>
              <li>
                <Link to="/safety/">Safety</Link>
              </li>
              <li>
                <Link to="/smart-home/">Smart Home</Link>
              </li>
              <li>
                <Link to="/security-cameras/">Security Cameras</Link>
              </li>
            </ul>
          </Droplink>
          {/* <Input id="header-search" type="text" placeholder="Search Your Zip Code" /> */}
        </Links>
      </Shell>
    </>
  )
}
