import React from "react"
import Icon from "./Icon"
import { Colors } from "../constants/Style"

export default ({ text, className }) => {
  const lines = text.split("\n")
  return (
    <ul className={className}>
      {lines.map(line => (
        <li key={line}>
          <Icon
            icon={className === "pros" ? "check" : "x"}
            color={className === "pros" ? Colors.green : Colors.red}
            size="20px"
          />
          <span>{line}</span>
        </li>
      ))}
    </ul>
  )
}
