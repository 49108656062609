import React from "react"
import styled from "styled-components"
import prismicSerializer from "../../helpers/prismicSerializer"
import Link from "../Link"

const Shell = styled.div`
  table {
    td,
    th {
      text-align: center;
      &:first-child {
        text-align: left;
      }
    }
  }
`

const PlanRow = ({ plan, company, col: { key, suffix, prefix, link } }) => {
  const val = plan[key]
  const finalVal = val ? `${prefix || ""}${val}${suffix || ""}` : "-"
  if (link) {
    return (
      <td>
        <Link company={company.uid} name="main" to={company.website.url}>
          {finalVal}
        </Link>
      </td>
    )
  }
  return <td>{finalVal}</td>
}

export default ({ primary, items, ...props }) => {
  const { header } = primary
  const { plans, company } = props

  const useColumns = props.columns || ["publicPlanName", "priceMonth", "promoPrice", "contractLengthInMonths"]
  const columns = {
    publicPlanName: { label: "Plan", link: true },
    priceMonth: { label: "Price", suffix: "/mo", prefix: "$" },
    promoPrice: { label: "Promo Price", suffix: "/mo", prefix: "$" },
    contractLengthInMonths: { label: "Contract Length", suffix: " mo" },
  }

  const filtered = useColumns.map(key => ({ key, ...columns[key] }))
  return plans ? (
    <Shell>
      {prismicSerializer(header)}
      <table>
        <tr>
          {filtered.map(({ label }) => (
            <th>{label}</th>
          ))}
        </tr>
        {plans.map(plan => (
          <tr>
            {filtered.map(col => (
              <PlanRow plan={plan} company={company} col={col} />
            ))}
          </tr>
        ))}
      </table>
    </Shell>
  ) : (
    <div />
  )
}
