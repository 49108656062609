module.exports = ({ first, last, company }) => {
  let template = "main"
  if (first === "home-security") {
    if (last === "deals") {
      template = "deals"
    } else if (last === "reviews" || last === "review") {
      template = "reviews"
    } else if (last === company) {
      template = "overview"
    } else if (last === "best") {
      template = "best"
    }
  }
  return template
}
