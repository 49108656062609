import React from "react"
import styled from "styled-components"
import ReactDOM from "react-dom"
import screenPosition from "../lib/screenPosition"
import { media } from "../constants/Style"
import withFilteredProps from "../lib/withFilteredProps"

const FilteredShell = withFilteredProps(props => <div {...props} />, [
  "width",
  "preload",
  "thewidth",
  "theheight",
  "float",
])
const Shell = styled(FilteredShell).attrs({ className: "contentImage" })`
  width: ${({ width }) => `${width}%`};
  float: ${({ float }) => float};
  margin-left: ${({ float }) => (float === "right" ? "2rem" : 0)};
  margin-right: ${({ float }) => (float === "left" ? "2rem" : 0)};
  margin-top: ${({ float }) => (["right", "left"].includes(float) ? "2rem" : 0)};
  position: relative;
  overflow: hidden;
  margin-bottom: 2rem;
  &:before {
    content: "";
    position: absolute;
    top: -1%;
    left: -1%;
    width: 102%;
    height: 102%;
    background: url(${({ preload }) => preload});
    background-size: cover;
    transition: 300ms all;
    z-index: 10;
    opacity: 1;
    filter: blur(5px);
  }
  &.loaded {
    &:before {
      opacity: 0;
    }
  }
  img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  div {
    width: 100%;
    padding-bottom: ${({ theheight, thewidth }) => `${(theheight / thewidth) * 100}%`};
  }
  ${media.hand`
    width: 100%;
    float: none;
    margin-left: 0;
    margin-right: 0;
  `}
`

export default class ContentImage extends React.Component {
  defaultProps = {
    style: "full",
  }
  constructor(props) {
    super(props)
    this.state = { loaded: false, shouldLoad: false }
    this.imgRef = React.createRef()
  }
  componentDidMount() {
    const el = ReactDOM.findDOMNode(this.imgRef.current)
    if (el) {
      this.pos = screenPosition(el)
      if (!this.loadIfInView()) {
        window.addEventListener("scroll", this.loadIfInView)
      }
    }
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.loadIfInView)
  }
  isInView = () => {
    return window.scrollY + window.outerHeight + 1100 > this.pos.top
  }
  loadIfInView = () => {
    const inView = this.isInView()
    if (inView) {
      this.setState({ shouldLoad: true })
      window.removeEventListener("scroll", this.loadIfInView)
    }
    return inView
  }
  setLoaded = () => {
    if (this.state.shouldLoad) {
      this.setState({ loaded: true })
    }
  }
  imageSources = ext => {
    const { filename } = this.props
    return `/images/${filename}-300.${ext} 300w,
    /images/${filename}-600.${ext} 600w,
    /images/${filename}-800.${ext} 800w,
    /images/${filename}.${ext} 1500w`
  }
  render() {
    const { dimensions, filename, base64, ext, alt, style, className } = this.props
    const { loaded, shouldLoad } = this.state
    const mimeExt = ext === "jpg" ? "jpeg" : ext
    const sourceAttr = shouldLoad ? "srcSet" : "data-srcset"
    const styleParts = style ? style.split("_") : "full"
    const styles = {
      float: "none",
      width: "100",
    }
    if (filename) {
      const { height, width } = dimensions
      if (styleParts !== "full" && styleParts.length > 1) {
        styles.float = styleParts[0]
        styles.width = styleParts[1]
      }
      return (
        <Shell
          preload={`data:image/${mimeExt};base64,${base64}`}
          className={`${loaded ? "loaded" : "unloaded"}${className ? ` ${className}` : ""}`}
          thewidth={width}
          theheight={height}
          {...styles}
        >
          <div />
          <picture ref={this.imgRef} onLoad={() => this.setLoaded()}>
            <source
              type={`image/${mimeExt}`}
              {...{ [sourceAttr]: this.imageSources(ext) }}
              onLoad={() => this.setLoaded()}
            />
            <source
              type="image/webp"
              {...{ [sourceAttr]: this.imageSources("webp") }}
              onLoad={() => this.setLoaded()}
            />
            <img
              onLoad={e => this.setLoaded()}
              alt={alt}
              title={alt}
              src={`data:image/${ext === "jpg" ? "jpeg" : ext};base64,${base64}`}
              {...{ [sourceAttr]: `/images/${filename}.${ext}` }}
            />
          </picture>
        </Shell>
      )
    }
    return null
  }
}
