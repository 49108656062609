import React from "react"
import styled from "styled-components"
import { lighten, darken } from "polished"
import { Colors, media } from "../constants/Style"
import Row from "../Row"

const Shell = styled.div`
  margin-top: 2rem;
  label {
    display: flex;
    justify-content: space-between;
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0.2rem;
  }
  ${media.hand`
    label {
      font-size: 0.8rem;
    }
  `}
`

const Bar = styled.div`
  opacity: 0.94;
  background: ${lighten(0.48, Colors.darkBlueGray)};
  border-bottom: 2px solid ${lighten(0.44, Colors.darkBlueGray)};
  width: 100%;
  height: ${({ size }) => (size === "large" ? "1rem" : "0.5rem")};
  position: relative;
  margin-bottom: 1.1rem;
  border-radius: 40px;
  &:before {
    border-radius: 40px;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: calc(100% + 2px);
    background: ${({ size }) => {
      const color = size === "large" ? Colors.darkBlue : Colors.darkBlueGray
      return `linear-gradient(
        to bottom,
        ${lighten(0.1, color)} 0%,
        ${darken(0.0, color)} 100%
      )
      ${color};`
    }};
    border-bottom: 2px solid ${darken(0.04, Colors.darkBlue)};
    width: ${({ score }) => (score / 10) * 100}%;
    margin-bottom: -3px;
    opacity: 0.8;
  }
  ${media.hand`
      && {
        margin-bottom: 1rem;
        &:last-of-type {
          margin-bottom: 1rem;
        }
      }
  `}
  &:last-of-type {
    margin-bottom: 0;
  }
`
const Half = styled(Row)`
  margin-top: 2rem;
  > div {
    width: 50%;
    margin-right: 2rem;
    &:last-of-type {
      margin-right: 0;
    }
  }
  ${media.hand`
    flex-direction: column;
    margin-top: 0rem;
    > div { 
      width: 100%;
      margin-right: 0;
    }
  `}
`
export default ({
  scores: { customerServiceScore, easeOfUseScore, equipmentScore, featuresAndTechnologyScore, valueScore },
}) => {
  return (
    <Shell>
      <div>
        <label>
          <div>Overall Value Score</div>
          <span>{valueScore}</span>
        </label>
        <Bar score={valueScore} size="large" />
      </div>
      <div>
        <Half>
          <div>
            <label>
              <div>Customer Service Score</div>
              <span>{customerServiceScore}</span>
            </label>
            <Bar score={customerServiceScore} />
          </div>
          <div>
            <label>
              <div>Ease of Use Score</div>
              <span>{easeOfUseScore}</span>
            </label>
            <Bar score={easeOfUseScore} />
          </div>
        </Half>
        <Half>
          <div>
            <label>
              <div>Equipment Score</div>
              <span>{equipmentScore}</span>
            </label>
            <Bar score={equipmentScore} />
          </div>
          <div>
            <label>
              <div>Features & Technology Score</div>
              <span>{featuresAndTechnologyScore}</span>
            </label>
            <Bar score={featuresAndTechnologyScore} />
          </div>
        </Half>
      </div>
    </Shell>
  )
}
