import gql from "graphql-tag"

export default gql`
  mutation CreateFinderSearch($input: CreateFinderSearchInput!) {
    createFinderSearch(input: $input) {
      cityByCityId {
        name
        stateByStateId {
          name
        }
      }
    }
  }
`
