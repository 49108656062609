import React from "react"
import { pick } from "lodash"
import styled from "styled-components"
import { lighten } from "polished"
import { Colors, Dims, Fonts, media } from "../constants/Style"
import Row from "../Row"
import prismicSerializer from "../helpers/prismicSerializer"
import ParsedContentBody from "./ParsedContentBody"
import ContentImage from "./ContentImage"
import Byline from "./Byline"

const Page = styled(Row)`
  position: relative;
  width: 100%;
  main {
    width: 100%;
  }
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 23.125rem;
    z-index: -1;
  }
`

const TopHeading = styled.div`
  padding: 4.375rem 0 0rem;
  background: ${lighten(0.04, Colors.veryLightBlue)};
  width: 100%;
  margin-bottom: -3rem;
  h1 {
    font-family: ${Fonts.sans};
    font-size: 2.825rem;
    color: ${Colors.darkBlue};
    width: ${Dims.container};
    margin: 0 auto -1rem;
    padding-right: 20rem;
  }
  h4 {
    color: #707984;
    font-size: 1.4375rem;
    margin-bottom: 4.5rem;
    line-height: 2rem;
    font-weight: 600;
    margin: 2rem auto 0;
    width: ${Dims.container};
    padding-right: 25rem;
    p {
      margin-bottom: 0.6rem;
    }
    ${media.hand`
      width: 100%;
      padding-right: 0;
    `}
  }
  ${media.tablet`
    padding: ${Dims.tabletPadding};
    padding-top: 4.375rem;
    padding-bottom: 0rem;
    /* h1, h4 {
      width: 100%;
      padding-right: 0;
      font-size: 1.24rem;
    } */
    h1 {
      width: 100%;
      padding-right: 0;
      font-size: 2.4rem;
      margin-bottom: 1.6rem;
    }
  `}
  ${media.hand`
    padding: ${Dims.handPadding};
    padding-top: 2.375rem;
    padding-bottom: 0rem;
    margin-top: 0.4rem;
    h1 {
      width: 100%;
      padding-right: 0;
      font-size: 1.9rem;
      margin-bottom: 1.6rem;
    }
  `}
`

const Article = styled.article.attrs({ className: "row" })`
  display: flex;
`

export default props => {
  const {
    page: { h1, body, topContent, featuredImage, author, dateModifiedPretty },
    ...moreProps
  } = props
  const pass = {
    ...pick(props.page, ["toc", "refs", "showTableOfContents", "showQuestionArea", "children", "after", "before"]),
  }
  return (
    <Page>
      <main>
        <TopHeading>
          <h1>{h1}</h1>
          {author && <Byline author={author} dateModified={dateModifiedPretty} />}
          {topContent && topContent.length > 0 && (
            <h4>
              <span>{topContent && topContent.length > 0 && prismicSerializer(topContent)}</span>
            </h4>
          )}
        </TopHeading>
        <ParsedContentBody {...pass} as={Article} body={body} {...moreProps}>
          <ContentImage {...featuredImage} />
        </ParsedContentBody>
      </main>
    </Page>
  )
}
