import React from "react"
import styled from "styled-components"
import Rating from "../components/Rating"
// import AwardRow from "../components/AwardRow"
import { Colors, media } from "../constants/Style"
import { lighten } from "polished"
import Link from "./Link"
import ShareBox from "./ShareBox"
import TableOfContents from "./TableOfContents"

const SideContent = styled.div`
  display: flex;
  background: #fff;
  padding: 0.75rem 0;
  margin-top: -8.12rem;
  justify-content: center;
  flex-direction: column;
  color: ${Colors.brown};
  ${Rating} {
    margin-top: 0.45rem;
    margin-bottom: 0.1rem;
  }
  h4,
  h6 {
    font-size: 1.55rem;
    margin: 2rem 0 0.8rem;
    color: ${Colors.brown};
    opacity: 0.8;

    ${media.tablet`
      margin: 0 0 1rem;
    `}
  }
  h5 {
    font-size: 1.15rem;
    text-align: center;
    margin: 1.5rem 0 -0.2rem;
    opacity: 0.8;
  }
  hr {
    width: 370px;
    margin: 1.8rem 0 0.4rem;
    opacity: 0.5;
  }
  > span {
    margin-bottom: 0.6rem;
    a {
      color: ${Colors.brown};
      text-decoration: none;
      border-bottom: 1px solid ${lighten(0.32, Colors.brown)};
    }
  }
  .table-of-contents {
    background: ${lighten(0.43, Colors.brown)};
    padding: 2rem;
    margin-top: 1.6rem;
    width: 100%;
    a {
      font-size: 1rem;
      margin-bottom: 1rem;
      color: ${Colors.brown};
      border-bottom: 1px solid ${lighten(0.35, Colors.brown)};
    }
    h6 {
      margin-top: 0;
    }
  }
  ${media.tablet`
    margin-top: 0;
  `}
`
const SidebarWrapper = styled.div`
  ${media.tablet`
    display: flex;
    margin: 0 -15px;
    justify-content: space-between;
  `}

  ${media.handlg`
    flex-wrap: wrap;
  `}

  @media(max-width: 575px) {
    display: block;
  }
`
const SidebarContent = styled.div`
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #eee;

  &:first-child {
    border-top: 0;
    padding-top: 0;
  }

  h4 {
    margin: 0 0 10px;
  }

  p {
    margin-bottom: 10px;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  @media (min-width: 576px) and (max-width: 1238px) {
    width: 50%;
    padding: 0 15px;
    border: none;
  }

  @media (max-width: 575px) {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
`

const Score = styled.div`
  background: ${lighten(0.67, Colors.darkBlue)};
  border: 2px solid ${lighten(0.58, Colors.darkBlue)};
  margin: -2rem 0rem 2.1rem 0rem;
  color: ${Colors.darkBlue};
  padding: 0.8rem 0 2.1rem;
  span {
    font-size: 2rem;
    font-weight: bold;
    margin-top: 0.3rem;
    text-align: center;
    width: 100%;
    display: block;
  }
`

export default props => {
  const {
    uid,
    website,
    wikipedia,
    name,
    salesPhone,
    customerServicePhone,
    template,
    reviewSummary,
    permalink,
    title,
    toc,
    valueScore,
  } = props
  return (
    <SideContent>
      <SidebarWrapper>
        <SidebarContent>
          <Score>
            <h5>HowSafe Score</h5>
            <span>{valueScore} / 10</span>
          </Score>
          {/* <h5>User Rating</h5> */}
          <Rating
            rating={reviewSummary.average}
            num={reviewSummary.count}
            company={uid}
            name={name}
            includeLinkToReviews={true}
          />
        </SidebarContent>
        {/* TODO: <h4>Awards & Accolades</h4>
        <AwardRow award="Best Customer Support" subtitle="2019" />
        <AwardRow award="Most Popular Home Security " subtitle="2019" />
        <AwardRow award="A+ Rating" subtitle="2019 Better Business Bureau" icon="bbb" /> */}
        <TableOfContents toc={toc} />
        <SidebarContent>
          <h4>{name} Customer Support</h4>
          <>
            {salesPhone && salesPhone.phone && salesPhone.display ? (
              <p>
                <b>New Customer: </b>
                <span>
                  <a href={`tel:${salesPhone.phone}`}>{salesPhone.display}</a>
                </span>
              </p>
            ) : null}
          </>
          {customerServicePhone && customerServicePhone.display ? (
            <p>
              <b>Existing Customers: </b>
              <span>{customerServicePhone.display}</span>
            </p>
          ) : null}
          <>
            {website && website.url ? (
              <p>
                <Link company={uid} name={template} to={website.url}>
                  Website
                </Link>
              </p>
            ) : null}
          </>
          <>
            {wikipedia && wikipedia.url ? (
              <p>
                <Link to={wikipedia.url}>Wikipedia</Link>
              </p>
            ) : null}
          </>
        </SidebarContent>
        <SidebarContent>
          <ShareBox url={permalink} title={title} />
        </SidebarContent>
      </SidebarWrapper>
    </SideContent>
  )
}
