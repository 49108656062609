import React from "react"
import styled from "styled-components"
import { darken, lighten } from "polished"
import Link from "../Link"
import AspectImage from "../AspectImage"
import { Colors, media } from "../../constants/Style"

const Shell = styled.div`
  margin-bottom: -7.4rem;
  position: relative;
  top: 1.2rem;
  && .top-pick {
    width: 60%;
    margin: -2rem auto 0;
    background: transparent;
    padding: 0.6rem 0 0rem;
    margin-bottom: 3rem;
    text-align: left;
    h3 {
      width: 100%;
      margin: 0;
      padding: 0;
      font-weight: 500;
      text-align: center;
      font-size: 1.3rem;
      line-height: 130%;
      color: ${lighten(0.1, Colors.medText)};
    }
    p {
      text-align: center;
      padding-top: 0.4rem;
      color: ${lighten(0.4, Colors.medText)};
      a {
        color: ${Colors.linkBlue};
        text-decoration: none;
        border-bottom: 1px solid ${lighten(0.0, Colors.linkBlue)};
      }
    }
  }
  .logo {
    margin: 0 0 0;
    max-height: 5rem;
    overflow: hidden;
    img {
      margin-left: 0;
    }
  }
  ${media.hand`
    && .top-pick {
      margin-top: 0;
      width: 100%;
      margin-bottom: 1.8rem;
      h3 {
        font-size: 1.2rem;
      }
      p {
        font-size: 0.9rem;
      }
    }
    .logo {
      img {
        margin-left: auto;
      }
    }
  `}
  .companies {
    display: flex;
    width: 100%;
    a {
      display: block;
    }
    .company {
      position: relative;
      background: #fff;
      flex: 1;
      margin-right: 2rem;
      box-shadow: 0px 7px 17px rgba(0, 0, 0, 0.08);
      padding-bottom: 7.3rem;
      &:last-of-type {
        margin-right: 0;
      }
      .top {
        padding: 2rem;
        display: flex;
        justify-content: space-between;
        min-height: 11rem;
        width: 100%;
        a {
          height: 100%;
          width: 13rem;
          margin-right: 2rem;
          align-self: center;
        }
        .data {
          text-align: right;
          padding-top: 3rem;
          div {
            margin-bottom: 0.1rem;
            font-size: 0.9rem;
            &:first-child {
              border: 2px solid #fce9c8;
              font-size: 1.1rem;
              font-weight: bold;
              background: #fffdf2;
              padding: 0.8rem 0.8rem;
              margin-bottom: 0.5rem;
              position: absolute;
              top: 1rem;
              right: 1rem;
              text-align: right;
            }
          }
        }
      }
      .cta {
        background: #f7fbff;
        padding: 2rem;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        border-top: 2px solid #edf2f6;
        a {
          display: block;
          padding: 1.1rem 2rem 1rem;
          font-size: 1.1rem;
          width: 100%;
          transition: all 0.3s;
          background: linear-gradient(to bottom, rgb(255, 220, 0) 0%, rgb(255, 183, 38) 100%);
          border-bottom: 2px solid #ecb138;
          color: ${darken(0.26, Colors.darkBrown)};
          font-weight: 600;
          text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.5);
          border-radius: 5px;
          text-align: center;
          text-decoration: none;
          transform-origin: center;
          &:hover {
            filter: brightness(1.05);
          }
        }
      }
    }
    ${media.hand`
      flex-direction: column;
      .company {
        margin-right: 0 !important;
        margin-bottom: 1rem;
        .top {
          flex-direction: column;
          > a {
            margin: 0 auto !important;
          }
          .data {
            text-align: right;
            width: 100%;
            text-align: center;
            padding-top: 1.8rem;
            div {
              font-size: 1rem;
              margin-bottom: 0.5rem;
              &:first-child {
                position: relative;
                text-align: center;
                top: 0;
                right: 0;
                font-size: 1rem;
                margin-bottom: 1rem;
              }
            }
          }
        }
      }
    `}
  }
`
const CompanyBox = ({ company }) => {
  const { maxPrice, minPrice, contract, incentive } = company
  const price = (minPrice, maxPrice) => {
    if (minPrice === 10000) {
      minPrice = 0
    }
    if (maxPrice === 100000) {
      maxPrice = 0
    }
    if (!maxPrice && !minPrice) {
      return ""
    } else if (!maxPrice && minPrice) {
      return `$${minPrice}`
    } else if (!minPrice && maxPrice) {
      return `$${maxPrice}`
    } else {
      return `$${minPrice} - $${maxPrice}`
    }
  }
  const priceStr = price(minPrice, maxPrice)
  const contractStr = contract.length && contract[0] ? `Contract: ${contract[0]} mos.` : "No Contract"
  const incentiveStr = incentive.length && incentive[0] ? `${incentive[0]}` : ""
  return (
    <div className="company">
      <div className="top">
        <Link company={company.uid} name="comparison" to={company.website.url}>
          <AspectImage {...company.companyLogo} useVariant={300} className="logo">
            <span>{company.name}</span>
          </AspectImage>
        </Link>
        <div className="data">
          <div>Our Score: {company.valueScore}/10</div>
          {priceStr && <div>{priceStr}</div>}
          {contractStr && <div>{contractStr}</div>}
          {incentiveStr && <div>{incentiveStr}</div>}
        </div>
      </div>
      <div className="cta">
        <Link company={company.uid} name="comparison" to={company.website.url}>
          View All Plans
        </Link>
      </div>
    </div>
  )
}

export default ({ primary, items, top, ...props }) => {
  // const { header } = primary
  const { companies } = props
  const companyList = companies.filter(({ name }) => name).map(({ name }) => name)
  const companyStr = [
    companyList.slice(0, companyList.length - 1).join(", "),
    companyList.slice(companyList.length - 1),
  ].join(" & ")
  // const rows = []

  return (
    <Shell>
      <div className="top-pick">
        <h3>
          {`We did an analysis on ${companyStr} and determined that your best choice is `}
          <strong>{top.name}.</strong>
        </h3>
        <p>
          Read on for more details, or{" "}
          <Link company={top.company} name="comparison" to={top.website.url}>
            click here to view plans
          </Link>
          .
        </p>
      </div>
      <div className="companies">
        {companies.map(company => (company ? <CompanyBox company={{ ...company }} /> : null))}
      </div>
    </Shell>
  )
}
