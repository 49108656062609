import { createGlobalStyle } from "styled-components"
import typefaceChivo from "typeface-chivo" // eslint-disable-line
import { media } from "./constants/Style"

export const GlobalStyles = createGlobalStyle`
  // @import url('https://fonts.googleapis.com/css?family=Chivo:400,400i,700,700i,900|Zilla+Slab:500,600,600i,700,700i');
  html {
    ${media.lap`
      font-size: 16px;
    `}
    ${media.lapsm`
      font-size: 14.3px;
    `}
  }
  body {
    font-family: 'Chivo', sans-serif;
  }
  .sans {
    font-family: 'Chivo', sans-serif;
  }
  * {
    box-sizing: border-box;
  }
  #table-199 td {
    border: 1px solid rgb(231, 236, 242);
  }
`
