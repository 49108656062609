import styled from "styled-components"
import { darken } from "polished"
import { Colors } from "../constants/Style"
import Link from "./Link"

export default styled(Link)`
  display: block;
  padding: 0.8rem 2rem;
  font-size: 1.1rem;
  width: 100%;
  border-radius: 5px;
  margin-bottom: 1rem;
  transition: all 0.3s;
  background: linear-gradient(to bottom, rgba(255, 220, 0, 1) 0%, rgba(255, 170, 0, 1) 100%);
  border-bottom: 2px solid #e49a03;
  color: ${darken(0.26, Colors.darkBrown)};
  font-weight: 600;
  text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.5);
  border-radius: 50px;
  text-align: center;
  text-decoration: none;
  transform-origin: center;
  min-width: 200px;
  &:hover {
    filter: brightness(1.05);
  }
  &:last-of-type {
    margin-right: 0;
  }
`
