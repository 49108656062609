import React, { useState } from "react"
import Row from "../Row"
import styled from "styled-components"
import { Colors } from "../constants/Style"
import star_img from "../images/star.png"
import star_img_light_blue from "../images/star-light-blue-bg.png"
import screenPosition from "../lib/screenPosition"
import { lighten } from "polished"

const Stars = styled(Row)`
  position: relative;
  z-index: 1;
`
const Star = styled.div`
  width: ${({ starSize }) => `${starSize}px`};
  height: ${({ starSize }) => `${starSize}px`};
  background-image: url(${({ lightBlue }) => (lightBlue ? star_img_light_blue : star_img)});
  background-size: contain;
  position: relative;
  background-repeat: no-repeat;
  background-color: transparent;
  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: ${({ starFill, starSize }) =>
      starFill >= 1 ? "100%" : starFill > 0 ? `${(starSize - 4) * starFill}px` : 0};
    height: 99%;
    background: ${Colors.gold};
    z-index: -1;
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: ${({ settingFill, starSize }) =>
      settingFill >= 1 ? "100%" : settingFill > 0 ? `${(starSize - 4) * settingFill}px` : 0};
    height: 100%;
    background: ${lighten(0.22, Colors.gold)};
    z-index: -2;
  }
`
const getVal = (e, size) => {
  const { clientX, currentTarget } = e
  const { left } = screenPosition(currentTarget)
  const val = (clientX - left) / size
  return val > 4.7 ? 5 : val
}
const valueSlide = (e, size, setValue) => {
  setValue(getVal(e, size))
}
export default ({ rating = 0, size = 30, valueChange, lightBlue }) => {
  const [value, setValue] = useState(0)
  const changeable = valueChange ? true : false
  const moveFnc = changeable ? e => valueSlide(e, size, setValue) : () => {}
  const changeFnc = changeable ? e => valueChange(getVal(e, size)) : () => {}
  return (
    <Stars
      style={changeable ? { cursor: "pointer" } : null}
      onMouseOver={moveFnc}
      onMouseMove={moveFnc}
      onClick={changeFnc}
      onMouseOut={() => setValue(false)}
    >
      {Array.from({ length: 5 }, (_, i) => (
        <Star key={`star-${i}`} starFill={rating - i} settingFill={value - i} starSize={size} lightBlue={lightBlue} />
      ))}
    </Stars>
  )
}
