import React from "react"
import styled from "styled-components"
import { darken } from "polished"
import ContentBody from "./ContentBody"
import AskQuestion from "./AskQuestion"
import TableOfContents from "./TableOfContents"
import Slices from "./Slices/Slices"
import References from "./References"
import { Colors } from "../constants/Style"
import Link from "./Link"
import slugBuilder from "../helpers/slugBuilder"

const CTAShell = styled.div`
  background: ${Colors.veryLightGold};
  border: 2px solid ${darken(0.1, Colors.veryLightGold)};
  padding: 2rem;
  margin-bottom: 1.2rem;
  h5 {
    font-size: 1.45rem;
    text-align: center;
  }
  a {
    display: block;
    padding: 1rem;
    padding-top: 1.04rem;
    background: ${Colors.red};
    color: ${Colors.white};
    text-align: center;
    text-decoration: none;
    font-size: 1.2rem;
    font-weight: bold;
    border-bottom: 2px solid ${darken(0.12, Colors.red)};
    transition: all 0.2s;
    &:hover {
      background: ${darken(0.06, Colors.red)};
    }
  }
`
const CTA = () => (
  <CTAShell>
    <h5>See Our Top Rated Home Security Picks</h5>
    <Link to="/home-security/best/">Compare Top Systems</Link>
  </CTAShell>
)
export default ({
  as,
  children,
  refs,
  after,
  showQuestionArea,
  showTableOfContents,
  toc,
  body,
  page,
  rightSide,
  leftSide,
  before,
  crumbs,
  type,
  showRightSide = true,
  ...props
}) => {
  const slug = crumbs && crumbs.current ? slugBuilder.page({ ...crumbs, includeRoot: false }) : "/"
  return (
    <ContentBody as={as} {...props}>
      {leftSide}
      <section className="primary">
        {before}
        {children && children}
        <Slices page={page}>{body}</Slices>
        {after}
        {refs && refs.length > 0 && <References refs={refs} />}
        {showQuestionArea && <AskQuestion />}
      </section>
      {showRightSide && (
        <div style={{ display: "flex", flexDirection: "column", width: "340px" }}>
          {showTableOfContents && (
            <>
              {slug !== "home-security/best/" && <CTA />}
              <TableOfContents toc={toc} />
            </>
          )}
          {rightSide}
        </div>
      )}
    </ContentBody>
  )
}
