import React from "react"
import styled from "styled-components"
import { trim } from "lodash"
import { Colors, media } from "../constants/Style"
import { lighten } from "polished"
// import Link from "./Link"

const TOC = styled.ul`
  margin-left: 0;
  background: ${lighten(0.36, Colors.lightBlue)};
  padding: 20px;
  width: 340px;
  flex: none;
  list-style-type: none;
  li {
    font-size: 0.9rem;
    list-style-type: none;
    margin-bottom: 0.6rem;
    a {
      color: ${Colors.darkBlueGray};
      border-bottom: 1px solid ${lighten(0.25, Colors.lightBlue)};
      display: inline;
      text-decoration: none;
      line-height: 1.6rem;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  ${media.tablet`
    && {
      margin: -8rem 0 2rem;
      background: ${Colors.white};
      width: 100%;
      border: 0;
    }
  `}
  ${media.hand`
    && {
      margin: 0rem 0 2rem;
    }

  `}
  h6 {
    font-size: 1rem;
    margin-bottom: 0.95rem;
    opacity: 0.8;
  }
`

const Empty = styled.div`
  flex: none;
  width: 340px;
`

// const CTAShell = styled.div`
//   background: ${Colors.veryLightGold};
//   a {
//     display: block;
//     padding: 1rem;
//   }
// `

// const CTA = () => (
//   <CTAShell>
//     <h5>See Our Top Rated Home Security Picks</h5>
//     <Link to="/home-security">Compare Top Systems</Link>
//   </CTAShell>
// )

export default ({ toc }) => {
  return toc && toc.length ? (
    <TOC className="table-of-contents">
      <h6>Table of Contents</h6>
      {toc.map(({ id, label }, i) => (
        <li key={id}>
          <a href={`#${id}`}>{trim(label, ":")}</a>
        </li>
      ))}
    </TOC>
  ) : (
    <Empty />
  )
}
