import styled from "styled-components"
import { Colors } from "../constants/Style"

export default styled.button`
  background: ${Colors.green};
  padding: 0.92rem 3rem 0.9rem;
  color: ${Colors.white};
  border: 0;
  min-width: 10rem;
  font-size: 1.1rem;
  cursor: pointer;
`
