import React from "react"
import styled from "styled-components"
import { Colors, media } from "../constants/Style.js"
import { lighten } from "polished"
import Usa from "@svg-maps/usa"
import { SVGMap } from "react-svg-map"
import { navigateTo } from "gatsby-link"
import states from "../lib/states"
import Link from "./Link"

const Shell = styled.div`
  width: 100%;
  margin: 0 auto;
  background: #fff;
  margin-bottom: 5.625rem;
  && section {
    padding-right: 0;
  }
  ${media.hand`
  width: 100%;
  `}
`

const Links = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 2rem;
  && {
    a {
      display: inline-block;
      width: calc(16% - 0.1rem);
      padding: 0.1rem;
      margin: 0.1rem;
      border-bottom-color: transparent;
      text-decoration: underline;
      text-align: center;
      ${media.tablet`
        width: calc(24% - 0.1rem);
      `}
      ${media.hand`
        width: calc(49% - 0.1rem);
      `}
    }
  }
`

const Map = styled(SVGMap)`
  width: 100%;
  height: auto;
  stroke: #fff;
  stroke-width: 3;
  stroke-opacity: 1;
  stroke-linecap: round;
  stroke-linejoin: round;

  .svg-map__location {
    fill: ${lighten(0.4, Colors.darkBlueGray)};
    cursor: pointer;
    stroke-width: 3px;
    transition: all 0.2s;

    &:hover {
      fill: ${lighten(0.2, Colors.darkBlueGray)};
    }

    &.svg-map__is-covered {
      fill: ${lighten(0.1, Colors.green)};
      outline: 0;
    }
  }
`

const slug = str => str.replace(/\s/g, "-").toLowerCase()

export default () => {
  const getLocationClassName = (location, index) => {
    let str = "not-covered"
    return `svg-map__location svg-map__${str}`
  }
  const click = location => {
    const state = slug(location.target.attributes.name.value)
    navigateTo(`/safety/${state}`)
  }
  return (
    <Shell>
      <section>
        <Map map={Usa} onLocationClick={click} locationClassName={getLocationClassName} />
        <Links>
          {states.map(state => (
            <Link to={`/safety/${slug(state)}`} key={state}>
              {state}
            </Link>
          ))}
        </Links>
      </section>
    </Shell>
  )
}
