import React, { useState } from "react"
import styled from "styled-components"
import apollo from "../lib/apollo"
import { round } from "lodash"
import gql from "graphql-tag"
import { Colors, media } from "../constants/Style"
import { lighten } from "polished"
import Button from "./Button"
import RatingStars from "./RatingStars"
import Row from "../Row"
import getCodeFromNetworkError from "../helpers/getCodeFromNetworkError"

const Shell = styled.form`
  width: 100%;
  padding: 3rem;
  background: ${lighten(0.012, Colors.veryLightBlue)};
  h3 {
    margin-top: 0;
    color: ${Colors.darkBlue};
  }
  label {
    display: block;
    font-weight: 600;
    margin-bottom: 0.05rem;
  }
  input,
  textarea {
    padding: 0.6rem;
    font-size: 1.1rem;
    margin-bottom: 1rem;
    border: 1px solid ${lighten(0.48, Colors.darkBlueGray)};
    width: 50%;
  }
  textarea {
    width: 100%;
  }
  label {
    display: block;
    color: ${Colors.darkBlueGray};
    margin-bottom: 0.2rem;
  }
  ${media.hand`
    && {
      padding: 2rem;
        h3 {
          margin-top: -1rem;
        font-size: 1.5rem;
      }
    }
    input, textarea {
      width: 100%;
    }
    button {
      width: 100%;
      margin-top: -1rem;
    }
    > .row {
      flex-direction: column;
      padding-top: 0.8rem;
      justify-content: center;
      text-align: center;
      align-items: center;
      margin-right: 0;
      .row {
        justify-content: center;
        margin-bottom: 0.6rem;
      }
    }
  `}
`
const Error = styled.div`
  padding: 2rem;
  background: ${Colors.red};
  color: #fff;
  margin-top: 2rem;
`
const Success = styled.div`
  padding: 2rem;
  background: ${Colors.green};
  color: #fff;
  margin-bottom: 2rem;
`

const submitReviewMutation = gql`
  mutation CreateReview($input: CreateReviewInput!) {
    createReview(input: $input) {
      clientMutationId
    }
  }
`

let errorTimo = 0
const submitReview = async (passedForm, formState, setFormState, e) => {
  clearTimeout(errorTimo)
  e.preventDefault()
  e.stopPropagation()
  let error = false
  const form = { ...passedForm }
  if (!form.body || !form.body.length > 20) {
    error = "You'll need to add some content to your review"
  }
  if (!form.title || !form.title.length) {
    error = "You'll need to add a title to your review"
  }
  if (!form.raterName || !form.raterName.length) {
    error = "You'll need to add your name"
  }
  if (error) {
    setFormState({ error })
    errorTimo = setTimeout(() => {
      setFormState("ready")
    }, 4400)
  } else {
    if (formState !== "process") {
      setFormState("process")
      try {
        form.ipaddress = "192.168.1.255"
        form.customerServiceScore = round(form.customerServiceScore * 2, 2)
        form.equipmentProtectionScore = round(form.equipmentProtectionScore * 2, 2)
        form.homeAutomationScore = round(form.homeAutomationScore * 2, 2)
        await apollo.mutate({
          mutation: submitReviewMutation,
          variables: { input: { review: form } },
        })
        setFormState("success")
      } catch (e) {
        const code = getCodeFromNetworkError(e.message)
        setFormState({
          error:
            code === 429
              ? "Wait a bit before submitting another review"
              : code === 409
              ? "You already reviewed this company."
              : "There was a server issue, our team has been notified",
        })
      }
    }
  }
}
export default ({ companyId, name }) => {
  const [form, setForm] = useState({
    body: "",
    companyId,
    ipaddress: "",
    raterName: "",
    title: "",
    customerServiceScore: 0,
    equipmentProtectionScore: 0,
    homeAutomationScore: 0,
  })
  const [formState, setFormState] = useState("ready")
  const btnText = formState === "process" ? "Submitting..." : "Submit Review"
  if (formState === "success") {
    return (
      <Success>
        Your review for {name} was successfully submitted! We will review your submission and it will be live shortly.
      </Success>
    )
  }
  return (
    <Shell onSubmit={e => submitReview(form, formState, setFormState, e)}>
      <h3 id="reviews">Submit Your Review of {name}</h3>
      <div>
        <label id="review-name">Your Name</label>
        <input
          value={form.raterName}
          placeholder="Your Name"
          aria-labelledby="review-name"
          onChange={({ target: { value } }) => setForm({ ...form, raterName: value })}
        />
      </div>
      <div>
        <label id="review-title">Review Title</label>
        <input
          value={form.title}
          placeholder="Review Title"
          aria-labelledby="review-title"
          onChange={({ target: { value } }) => setForm({ ...form, title: value })}
        />
      </div>
      <div>
        <label id="review-content">Your Review</label>
        <textarea
          value={form.body}
          placeholder="Your Review"
          aria-labelledby="review-content"
          onChange={({ target: { value } }) => setForm({ ...form, body: value })}
        />
      </div>
      <Row style={{ justifyContent: "space-between", width: "92%", margin: "0.4rem 0 3rem" }}>
        <div>
          <label>Customer Service</label>
          <RatingStars
            lightBlue
            rating={form.customerServiceScore}
            valueChange={value => setForm({ ...form, customerServiceScore: value })}
          />
        </div>
        <div>
          <label>Equipment Protection</label>
          <RatingStars
            lightBlue
            rating={form.equipmentProtectionScore}
            valueChange={value => setForm({ ...form, equipmentProtectionScore: value })}
          />
        </div>
        <div>
          <label>Home Automation</label>
          <RatingStars
            lightBlue
            rating={form.homeAutomationScore}
            valueChange={value => setForm({ ...form, homeAutomationScore: value })}
          />
        </div>
      </Row>
      <Button aria-label="Submit Your Review">{btnText}</Button>
      {formState.hasOwnProperty("error") && <Error>{formState.error}</Error>}
    </Shell>
  )
}
