import React from "react"
import styled from "styled-components"
import prismicSerializer from "../../helpers/prismicSerializer"
import { Colors } from "../../constants/Style"
import processStringToReact from "../../helpers/processStringToReact"
import { lighten, darken } from "polished"
import screenPosition from "../../lib/screenPosition"

const TableSection = styled.div.attrs({ className: "table-section" })`
  header {
    font-size: 1.2rem;
    margin-bottom: 2.4rem;
  }
  footer {
    padding: 1.4rem;
    background: ${Colors.veryLightBlue};
    color: ${Colors.darkBlueGray};
    margin-bottom: 2.4rem;
  }
`
const TableShell = styled.div.attrs({ className: "table-shell" })`
  position: relative;
  height: auto;
  overflow-x: auto;
  overflow-y: auto;
  width: ${({ frameWidth }) => (frameWidth === "wide" ? "130%" : "100%")};
  margin-bottom: 1.3rem;
  table {
    /* table-layout: fixed; */
    width: ${({ tableWidth }) => (tableWidth === "wide" ? "130%" : "100%")};
    margin-bottom: 0;
    tr:first-of-type {
      th {
        background: ${Colors.darkBlueGray};
        border: 0;
        color: #fff;
        font-size: 0.9rem;
        font-weight: 500;
        text-align: center;
        padding: 1.16rem 0;
      }
    }
    tr {
      th:first-child {
        position: sticky;
        left: 0;
        top: 0;
        z-index: 14;
        /* max-width: 16rem; */
      }
    }
    .button {
      display: inline-block;
      color: ${Colors.white};
      background: ${lighten(0.02, Colors.red)};
      border-radius: 3px;
      padding: 0.65rem 2rem;
      border: 0;
      text-align: center;
      min-width: 10rem;
      transition: all 0.2s;
      font-size: 1rem;
      text-decoration: none;

      &:hover {
        background: ${darken(0.04, Colors.red)};
      }
    }
  }
`
const Cell = styled.td`
  font-size: 0.9rem;
  line-height: 140%;
  border-bottom: 1px solid ${lighten(0.35, Colors.lightBlue)};
  overflow: hidden;
  text-align: center;
  letter-spacing: -0.24px;
  .icon {
    width: 40px;
    text-align: center;
    height: 40px;
    line-height: 57px;
    margin: 0 auto;
    border-radius: 50%;
  }
  .icon-check {
    background: ${lighten(0.485, Colors.green)};
    color: ${lighten(0.0, Colors.green)};
  }
  .icon-x {
    background: ${lighten(0.425, Colors.red)};
    color: ${lighten(0.0, Colors.red)};
    line-height: 56px;
  }
  .aspectImage {
    height: 100px;
    margin-bottom: 0;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    img {
      max-height: 100%;
      max-width: 60%;
      width: auto;
      height: auto;
      margin: auto;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }
`
const CellHead = styled.th`
  background: #f5f6f6;
  border-bottom: 1px solid ${lighten(0.35, Colors.lightBlue)};
  text-align: left;
  &:first-of-type {
    min-width: 12rem;
    width: 12rem;
    padding-left: 0.7rem;
    &::before {
      content: "";
      position: absolute;
      right: 0px;
      height: calc(100% + 3px);
      width: 4px;
      top: 0;
      background: #6571751a;
    }
  }
`

export default class Table extends React.Component {
  state = {
    frameWidth: "normal",
    tableWidth: "normal",
  }
  constructor(props) {
    super(props)
    this.ref = React.createRef()
  }
  componentDidMount() {
    let tocBot = 0
    const toc = document.querySelector(".table-of-contents")
    const tableTop = screenPosition(this.ref.current).top
    if (toc) {
      tocBot = screenPosition(toc).top + toc.clientHeight
    }
    if (tableTop > tocBot + 60 && !this.props.forceNormalWidth) {
      this.setState({ frameWidth: "wide", tableWidth: "normal" })
    }
  }
  render() {
    const { props } = this
    const {
      primary: { tableDescription, tableHeader, tableFooter },
      rows,
    } = props
    const cols = rows && rows[0].length > 0 ? rows[0].length : 0
    return (
      <TableSection ref={this.ref} id={`table-${props.id}`} data-slug={props.slug}>
        {prismicSerializer(tableHeader)}
        {tableDescription && tableDescription.length > 0 && <header>{tableDescription}</header>}
        <TableShell wide={cols > 5} {...this.state}>
          <table cellPadding="0" cellSpacing="0">
            <tbody>
              {rows &&
                rows.map((cells, x) => (
                  <tr key={x}>
                    {cells.map((cell, y) => (
                      <Cell
                        style={cell.style ? cell.style : null}
                        as={cell.header ? CellHead : null}
                        key={y}
                        colSpan={cell.colspan || "1"}
                        className={`${cell.header ? "header-cell" : ""}`}
                      >
                        {processStringToReact(cell.content)}
                      </Cell>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
        </TableShell>
        {tableFooter && tableFooter.length > 0 && <footer>{processStringToReact(tableFooter)}</footer>}
      </TableSection>
    )
  }
}
