import ApolloClient from "apollo-client"
import { createHttpLink } from "apollo-link-http"
import { InMemoryCache } from "apollo-cache-inmemory"
import { setContext } from "apollo-link-context"
import { GRAPH_URL } from "../constants/Net"

let client = {}

if (typeof window !== "undefined") {
  const httpLink = createHttpLink({
    uri: GRAPH_URL,
  })

  const authLink = setContext((_, { headers }) => {
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: `Bearer aafebf2cce6f16df695c10b20eef9501cd99753b`,
      },
    }
  })

  client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  })
}

export default client
