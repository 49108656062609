import React from "react"

class PrivacyPolicy extends React.Component {
	componentDidMount() {
		window.addEventListener("message", function(e) {
			if(e.data.contentHeight) {
				document.getElementById("privacy-policy-frame").style.height = e.data.contentHeight + "px";
			}
		}, false);
	}

	render() {
		return (
			<div className={`privacy-policy`}>
				<iframe
					src="https://compliance.homealarmreport.com/privacy-policy-embedded.php?site=homealarmreport.com"
					frameBorder="0"
					style={{
						width: "100%",
						display: "block",
						border: 0,
						margin: 0,
						padding: 0
					}}
					id="privacy-policy-frame"
				></iframe>
			</div>
		)
	}
}
export default PrivacyPolicy;
