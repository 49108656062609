import React from "react"
import styled from "styled-components"
import { darken } from "polished"
import usePortal from "react-useportal"
import ContentWithSidebar from "./ContentWithSidebar"
import { Colors, media } from "../constants/Style"
import { lighten } from "polished"
import Link from "../components/Link"
import TableOfContents from "../components/TableOfContents"
import Button from "../components/Button"
import ShareBox from "../components/ShareBox"
import Byline from "../components/Byline"
import ContentImage from "../components/ContentImage"
import Modal from "../components/Modal"
import CitationTable from "../components/CitationTable"

const Shell = styled.div`
  background: #f7f7f7;
  &&& .top-shell {
    background: #f7f7f7;
    min-height: 1rem;
    h1 {
      margin-top: 1rem;
      margin-bottom: 0rem;
      font-size: 2.8rem;
      text-align: left;
      display: block;
      width: 50rem;
      ${media.tablet`
        width: 100%;
      `}
      ${media.hand`
        font-size: 2.3rem;
        margin-top: 0.2rem;
      `}
    }
    .column-wrap {
      padding: 5rem 0 3rem;
      ${media.tablet`
        padding-left: 1rem;
        padding-right: 1rem;
      `}
      ${media.hand`
        padding-top: 2.4rem;
      `};
    }
  }
  &&& article {
    background: #fff;
    box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.025);
    padding: 4.4rem;
    margin-top: 0rem;
    max-width: 55rem;
    .highlight {
      background: ${lighten(0.045, Colors.lightBrightBlue)};
      border-top: 2px solid ${lighten(0.0, Colors.lightBrightBlue)};
      margin-left: -4.4rem;
      margin-right: -4.4rem;
      margin-bottom: 4.4rem;
      margin-top: 4.4rem;
      padding: 0.8rem 4.4rem;
    }
    .featured-image {
      float: right;
      width: 55%;
      margin-left: 2rem;
    }
    section.primary {
      padding-right: 0;
      max-width: 1000rem;
    }
    ${media.tablet`
      padding: 2.4rem;
    .highlight {
      margin-left: -2.4rem;
      margin-right: -2.4rem;
      margin-bottom: 2.4rem;
      margin-top: 2.4rem;
      padding: 0.8rem 2.4rem;
    }
    `}
  }
  &&& aside {
    margin-top: 0rem;
    .table-of-contents {
      background: #f7f7f7;
      margin-top: 2rem;
      border-top: 1px solid #ddd;
      padding: 2rem 0 2rem 0;
      border-bottom: 1px solid #ddd;
    }
    button {
      width: 100%;
    }
  }
  .byline {
    background: transparent;
    margin-bottom: -1.8rem;
    padding: 0;
  }
  .column-sidebar {
    width: 19.25rem;
    .table-of-contents {
      width: 100%;
    }
    ${media.tablet`
    margin-bottom: 2rem;
  `}
  }
  .table-shell {
    width: 100%;
  }
  .sharebox {
    h4 {
      margin-bottom: 0.5rem;
      margin-top: 1.8rem;
    }
    input {
      background: transparent;
    }
  }
`

const JournalistCta = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${lighten(0.045, Colors.darkBlue)};
  margin-left: -4.4rem;
  margin-right: -4.4rem;
  margin-bottom: 4.4rem;
  margin-top: -4.4rem;
  padding: 1.8rem 4.4rem;
  color: #fff;
  h6 {
    font-size: 0.95rem;
    margin-bottom: 0.8rem;
  }
  &&& p {
    color: #fff;
    font-size: 0.9rem;
    line-height: 130%;
    opacity: 0.7;
    width: 90%;
  }
  a {
    display: block;
    color: #fff;
    background: ${darken(0.05, Colors.darkBlue)};
    padding: 0.9rem 2rem;
    text-decoration: none;
    opacity: 0.8;
    transition: all 0.2s;
    &:hover {
      opacity: 1;
    }
  }
  ${media.tablet`
    margin-left: -2.4rem;
    margin-right: -2.4rem;
    margin-bottom: 2.4rem;
    margin-top: -2.4rem;
    padding: 0.8rem 2.4rem;
  `}
  ${media.hand`
    flex-direction: column;
    a {
      margin-top: 0.8rem;
      width: 100%;
      text-align: center;
    }
    `}
`
const SSRPortal = styled.div``
export default props => {
  var { openPortal, closePortal, isOpen, Portal } =
    typeof window !== "undefined"
      ? usePortal()
      : { openPortal: () => {}, closePortal: () => {}, isOpen: false, Portal: SSRPortal }
  const { page } = props
  const { topContent, h1, featuredImage, permalink, title, pageTitle, author, dateModifiedPretty, dateModified } = page
  return (
    <Shell>
      <ContentWithSidebar
        h1={h1}
        body={page.body}
        page={page}
        topContent={topContent}
        topComponent={author && <Byline author={author} dateModified={dateModifiedPretty} />}
        before={
          <>
            <JournalistCta>
              <div>
                <h6>Are you a journalist or researcher writing about this topic?</h6>
                <p>
                  Contact us and we'll connect you with a safety and home security market expert on our team who can
                  provide insights and data to support your work.
                </p>
              </div>
              <Link to="/contact">Contact</Link>
            </JournalistCta>
            <ContentImage {...featuredImage} className="featured-image" />
          </>
        }
        sidebar={
          <div>
            <Button onClick={() => (isOpen ? closePortal() : openPortal())}>Cite this Research</Button>
            <TableOfContents toc={page.toc} />
            <ShareBox url={permalink} title={title} />
          </div>
        }
        centerTop={true}
        topSideContent={false}
        overlayTopContent={false}
        topAlign="center"
      />
      {
        <Portal>
          <Modal isOpen={isOpen} handleClose={closePortal}>
            <h4>Cite this Research</h4>
            <CitationTable dateModified={dateModified} author={author} title={pageTitle} permalink={permalink} />
          </Modal>
        </Portal>
      }
    </Shell>
  )
}
