import React from "react"
import styled from "styled-components"
import Contain from "./Contain"
import { lighten } from "polished"
import Link from "./Link"
import { Colors, media } from "../constants/Style"
import ContentImage from "./ContentImage"
import prismicSerializer from "../helpers/prismicSerializer"

const Shell = styled(Contain)`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 4rem;
  h4 {
    font-size: 1.7rem;
    width: 100%;
    margin-bottom: 1.2rem;
    ${media.hand`
      margin-top: 4rem;
    `}
  }
`
const Row = styled(Link)`
  display: flex;
  width: 100%;
  min-height: 100px;
  margin-right: 1rem;
  margin-bottom: 1rem;
  flex-direction: row;
  padding: 2rem 2rem 1.8rem;
  background: ${lighten(0.55, Colors.darkBlueGray)};
  transition: 0.2s all;
  text-decoration: none;
  && {
    color: ${Colors.darkBlue};
    border-bottom: 2px solid ${lighten(0.48, Colors.darkBlueGray)};
  }
  &:hover {
    border-bottom: 2px solid ${lighten(0.42, Colors.darkBlueGray)};
    background: ${lighten(0.02, Colors.lightBrightBlue)};
  }
  ${media.aboveTablet`
  &:nth-of-type(3n) {
    margin-right: 0;
  }
  `}
  ${media.tablet`
    width: calc(50% - 0.5rem);
    &:nth-of-type(2n) {
      margin-right: 0;
    }
  `}
  ${media.hand`
    width: 100%;
    margin-right: 0;
  `}
  .img {
    width: 14rem;
    margin-right: 2rem;
    position: relative;
    overflow: hidden;
    margin-left: -1.9rem;
    margin-top: -2rem;
    margin-bottom: -1.9rem;
  }
  .contentImage {
    max-height: 100%;
    height: 100%;
    margin-bottom: 0;
    img {
      object-fit: cover;
    }
  }
`

const Content = styled.div`
  width: 100%;
  h6 {
    font-size: 1.7rem;
    width: 90%;
    line-height: 110%;
    margin-bottom: 0.6rem;
  }
`

export default ({ pages, title, showImage = false }) => {
  return (
    <Shell>
      {title && <h4>Related Pages</h4>}
      {pages.map(c => (
        <Row key={c.id} to={c.permalink}>
          {showImage && (
            <div className="img">
              <ContentImage {...c.image} />
            </div>
          )}
          <Content>
            <h6>{c.title}</h6>
            <div>{prismicSerializer(c.summary)}</div>
          </Content>
        </Row>
      ))}
    </Shell>
  )
}
