import React, { useEffect } from "react"
import gql from "graphql-tag"
import Layout from "../components/layout"
import Templater from "./Templater"
import { setCompanies } from "../components/Link"
import apollo from "../lib/apollo"
import { setComponentObjects } from "../helpers/processStringToReact"

let previousPage = ""
const createNotFoundMutation = gql`
  mutation CreateNotFound($input: CreateNotFoundInput!) {
    createNotFound(input: $input) {
      clientMutationId
    }
  }
`
let companiesSet = false
export default payload => {
  const {
    pageContext: { page, seo, settings, components, globalComponents },
  } = payload
  setComponentObjects(components, globalComponents)
  if (!companiesSet) {
    setCompanies(settings.companies)
    companiesSet = true
  }
  useEffect(() => {
    if (page.uid === "404") {
      apollo.mutate({
        mutation: createNotFoundMutation,
        variables: {
          input: {
            notFound: {
              path: window.location.pathname,
              from: previousPage || document.referrer || "",
              ipaddress: "192.168.1.255",
            },
          },
        },
      })
    }
    previousPage = window.location.pathname
  }, [])
  page.permalink = seo.permalink
  return (
    <Layout seo={seo} settings={settings}>
      <Templater crumbs={seo.breadcrumbs} page={page} location={payload.location}>
        {page && page.body ? page.body : []}
      </Templater>
    </Layout>
  )
}
