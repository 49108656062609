import React, { useEffect, useState } from "react"
import { last } from "lodash"
import styled from "styled-components"
import { Colors } from "../../constants/Style"
import { lighten } from "polished"

const Embed = styled.div`
  position: relative;
  margin-bottom: 2rem;
  background: ${lighten(0.051, Colors.lightBrightBlue)};
  div {
    width: 100%;
    padding-bottom: 51.3%;
  }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: all 300ms 50ms;
    &.loaded {
      opacity: 1;
    }
  }
`

export default ({ primary, items }) => {
  const [shouldLoad, setShouldLoad] = useState(false)
  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    if (document.readyState === "complete") {
      setTimeout(() => {
        setShouldLoad(true)
      }, 50)
    }
    if (window) {
      window.addEventListener("load", () => {
        setTimeout(() => {
          setShouldLoad(true)
        }, 50)
      })
    }
  }, [])
  let videoEmbed
  if (primary && primary.embed) {
    if (primary.embed.embedUrl) {
      videoEmbed = "https://youtube.com/embed/" + last(primary.embed.embedUrl.split("/"))
    } else {
      console.log(`>> Featured Video doesn't have a primary embedUrl: ${JSON.stringify(primary)}`)
    }
  }
  return (
    <>
      {primary && primary.embed ? (
        <div className="embed-wrap">
          <Embed className={`embed ${loaded ? "loaded" : ""}`}>
            <div />
            {shouldLoad && (
              <iframe
                height="100%"
                className={`${loaded ? "loaded" : ""}`}
                width="100%"
                src={videoEmbed}
                onLoad={() => setLoaded(true)}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                title="embedded video"
                allowFullScreen
              />
            )}
          </Embed>
          {primary.caption ? <p>{primary.caption}</p> : null}
        </div>
      ) : null}
    </>
  )
}
