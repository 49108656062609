import React from "react"
import PrimaryContent from "../PrimaryContent"
import ContentImage from "../ContentImage"

export default ({ primary, items }) => {
  const type = items.length > 1 ? "gallery" : "single"
  const images = items.map(item => item.galleryImage)
  return (
    <div className="image-gallery" key="gallery">
      <PrimaryContent className="image-gallery-header">{primary}</PrimaryContent>
      {type === "gallery" ? (
        <div className="images">
          {images.map(image => (
            <ContentImage {...image} key={image.url} />
          ))}
        </div>
      ) : (
        <ContentImage image={images[0]} />
      )}
    </div>
  )
}
