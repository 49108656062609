import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { IoMdClose } from "react-icons/io"
import { Colors } from "../constants/Style"
import { media } from "../constants/Style"

const Shell = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(236, 236, 236, 0.9);
  opacity: 0;
  transition: opacity 0.2s !important;
  left: 0;
  z-index: 1000;

  &.open {
    opacity: 1;
    .content {
      transform: translateY(0);
    }
  }
  .content {
    transition: all 0.2s;
    transform: translateY(-2rem);
  }
  h4 {
    margin-top: 0;
    margin-bottom: 0px;
    margin: -2rem -2rem 0.5rem -2rem;
    padding: 1.1rem 2rem;
    background: ${Colors.darkBlue};
    color: ${Colors.white};
    font-size: 1.1rem;
  }
`
const Content = styled.div.attrs({ className: "content" })`
  position: relative;
  width: 37.5rem;
  box-shadow: 0.375rem 0.375rem 0.75rem rgba(0, 0, 0, 0.05);
  margin: 6rem auto 0;
  padding: 2rem;
  background: #fff;

  ${media.hand`
  margin-top: 2rem;
  width: 96%;
  height: 90%;
  `}
`
const Close = styled.button`
  background: transparent;
  border: 0;
  position: absolute;
  right: -12px;
  top: -25px;
  cursor: pointer;
`

export default ({ children, isOpen, handleClose }) => {
  const totalDelay = 20
  const [shouldAppear, setShouldAppear] = useState(false)
  const [shouldCollapse, setShouldCollapse] = useState(true)
  useEffect(() => {
    if (isOpen && !shouldAppear) {
      setShouldCollapse(false)
      setTimeout(() => setShouldAppear(true), 20)
    } else if (!isOpen) {
      setShouldAppear(false)
      setTimeout(() => setShouldCollapse(true), totalDelay)
    }
  }, [isOpen])
  return (
    <Shell className={shouldAppear ? "open" : "closed"} style={{ visibility: shouldCollapse ? "collapse" : "visible" }}>
      <div />
      <Content className="the-content">
        <Close onClick={() => handleClose()}>
          <IoMdClose size={20} color="#666" />
        </Close>
        {children}
      </Content>
    </Shell>
  )
}
