import React from "react"
import styled from "styled-components"
import { Colors, Dims, Fonts, media } from "../constants/Style"
import prismicSerializer from "../helpers/prismicSerializer"
import ParsedContentBody from "../components/ParsedContentBody"

const Main = styled.main`
  position: relative;
  width: 100%;
  article {
    margin-top: ${({ overlayTopContent }) => (overlayTopContent ? "-90px" : "90px")};
    max-width: 48rem;
    section.primary {
      padding-right: 0;
    }
  }
  ${media.hand`
    margin-top: 0.9rem;
  `}
`
const ColumnWrap = styled.div.attrs({ className: "column-wrap" })`
  display: flex;
  width: ${Dims.container};
  justify-content: space-between;
  margin: 0 auto;
  ${media.tablet`
    flex-direction: column;
    padding: ${Dims.tabletPadding};
    width: 100%;
    align-items: center;
  `}
`
const Sidebar = styled.aside.attrs({ className: "column-sidebar" })`
  width: 25rem;
  margin-left: 2.5rem;
  margin-top: 2.5rem;
  flex: none;

  ${media.tablet`
    width: 100%;
    margin: 0;
  `}
`
const TopHeading = styled.div.attrs({ className: "top-shell" })`
  background: ${Colors.veryLightBlue};
  width: 100%;
  min-height: 32rem;
  text-align: ${({ topAlign }) => topAlign};

  ${ColumnWrap} {
    padding: 5.375rem 0;
    flex-direction: row;
    > div {
      width: 100%;
    }
    h1, .h1Shell {
      font-family: ${Fonts.sans};
      font-size: 3.625rem;
      color: ${Colors.darkBlue};
      line-height: 100%;
      margin-top: 0;
      font-weight: bold;
      span {
        display: inline;
      }
    }
    h1 {
      display: inline;
    }
    .h1Shell {
      margin-bottom: 1.4rem;
    }
    .topContent {
      color: #707984;
      font-size: 1.4375rem;
      margin-bottom: 4.5rem;
      width: 48.5rem;
      line-height: 140%;
      font-weight: 600;
    }
    ${Sidebar} {
      margin-top: 0;
    }
    ${media.tablet`
      padding: ${Dims.tabletPadding};
      padding-top: 86px;
      padding-bottom: 86px;
      width: 100%;
      ${Sidebar} {
        width: 22rem;
        margin-left: 0;
        margin-top: 3rem;
        margin-bottom: 40px;
        align-self: center;
      }
      h1 {
        font-size: 3rem;
      }
      .topContent {
        width: 100%;
        font-size: 1.25rem;
      }
    `}
    ${media.tabletOnly`
      h1,
      .topContent {
        padding-right: 20px;
      }
    `}
    ${media.handlg`
      padding-left: 1rem;
      padding-right: 1rem;
      display: block;

      h1 {
        font-size: 2rem;
        margin-bottom: 0.5rem;
      }

      .topContent {
        width: 100%;
        font-size: 1rem;
        font-weight: normal;
        line-height: 130%;
        margin-bottom: -1rem;
      }

      ${Sidebar} {
        width: 100%;
      }
    `}
    ${media.hand`
      padding-top: 1rem;
      h1 {
        font-size: 1.85rem;
      }
    `}

    ${media.handsm`
      ${Sidebar} {
        a {
          font-size: 1.175rem;
          /* &:before {
            left: 50px;
            top: 12px;
          } */
        }
        button {
          font-size: 1rem;

          &:before {
            left: 48px;
          }
        }
        svg {
          width: 20px;
          height: 20px;
          margin-right: 24px;
        }
      }
    `}
  }
`
export default ({
  h1,
  withH1,
  sidebar,
  page,
  body,
  children,
  after,
  overlayTopContent = true,
  topAlign = "left",
  topContent,
  topComponent,
  wideContent,
  topSideContent,
  before,
}) => {
  return (
    <Main overlayTopContent={overlayTopContent}>
      <TopHeading topAlign={topAlign}>
        <ColumnWrap>
          <div>
            <div className="h1Shell">
              <h1>{h1}</h1>
              {withH1 && <span>{withH1}</span>}
            </div>
            {topContent && topContent.length > 0 && <div className="topContent">{prismicSerializer(topContent)}</div>}
            {topComponent && <div>{topComponent}</div>}
          </div>
          {topSideContent && <Sidebar>{topSideContent}</Sidebar>}
        </ColumnWrap>
      </TopHeading>
      {wideContent && (
        <ParsedContentBody className="wide" page={page} body={body} after={after} before={before} refs={page.refs}>
          {wideContent}
        </ParsedContentBody>
      )}
      <ColumnWrap>
        <ParsedContentBody page={page} body={body} after={after} before={before} refs={page.refs}>
          {children && children}
        </ParsedContentBody>
        <Sidebar>{sidebar}</Sidebar>
      </ColumnWrap>
    </Main>
  )
}
