import React from "react"
import styled from "styled-components"
import { lighten } from "polished"
import Contain from "../components/Contain"
import Slices from "../components/Slices/Slices"
import SocialProof from "../components/SocialProof"
import { media, Colors } from "../constants/Style"
import Cards from "../components/Cards"
import ZipCta from "../components/ZipCta"
import WideSection from "../components/WideSection"
import Cols from "../components/Cols"
import Col from "../Col"
import StatBlock from "../components/StatBlock"
import SvgIcon from "../components/SVGIcon"

const Ctas = styled(Contain)`
  margin-top: 3rem;
  h1 {
    font-size: 2.4rem;
    margin-bottom: 1.7rem;
    text-align: center;
    letter-spacing: -1px;
  }
  p {
    font-size: 1.2rem;
    margin: 0 auto 4rem;
    text-align: center;
    width: 75%;
  }
`
const IntroCol = styled(Col)`
  h2 {
    font-size: 2.1rem;
  }
  p {
    font-size: 1.1rem;
  }
`
const CtaContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  ${media.hand`
    flex-direction: column;
  `}
`
const FeatureRow = styled.div`
  display: flex;
  > div {
    text-align: center;
    margin-right: 2rem;
    &:last-of-type {
      margin-right: 0;
    }
    h4 {
      font-size: 1.2rem;
      margin-bottom: 0.8rem;
    }
    p {
      width: 80%;
      margin: 0 auto;
    }
    .iconBg {
      background: #f2f2f2;
      width: 5rem;
      height: 5rem;
      border-radius: 50%;
      padding: 1.2rem;
      display: flex;
      align-items: center;
      margin: 0 auto 1rem;
      border: 1px solid #e6e6e6;
      svg {
        width: 4rem;
        height: 4rem;
        opacity: 0.6;
      }
    }
  }
  ${media.hand`
    flex-direction: column;
    > div {
      margin-bottom: 3rem;
      margin-right: 0;
      width: 100%;
    }
  `}
`

export default props => {
  const { children, page } = props
  const { images } = page.settings
  const [ctas] = children.reduce(
    (out, curr) => {
      curr.sliceType === "call_to_action" ? out[0].push(curr) : out[1].push(curr)
      return out
    },
    [[], []]
  )
  return (
    <div>
      <ZipCta />
      <WideSection color={Colors.white}>
        <Cols>
          <IntroCol cols="8">
            <h2>We've spent years, analyzing the home security industry and nationwide crime & disaster data.</h2>
            <p>While crime statistics are shocking, they're often impersonal...until it happens to you.</p>
            <p>
              Once you’re a victim, national crime statistics don’t help you. Instead, they leave you with a sense of
              “What could I have done to prevent this?”
            </p>
            <p>
              That's why we break down national crime data and the home security industry <i>before you're a victim</i>.
              We give actionable insights to potential threats near you along with how to protect your family.
            </p>
            <p>
              Our goal is to give you an all inclusive answer to the question:{" "}
              <b>“What are the largest risks to my family and how can I protect them?”</b>
            </p>
          </IntroCol>
          <Col cols="0.5" />
          <Col cols="3.5">
            <StatBlock value="6,697,751" label="Crimes Processed" icon="magwitheye" />
            <StatBlock value="29,777" label="Cities Analyzed" icon="apartment" />
            <StatBlock value="34" label="Crime Types Tracked" icon="handcuffs" />
          </Col>
        </Cols>
      </WideSection>
      <SocialProof
        images={images}
        style={{ marginTop: 0, padding: "4rem", background: lighten(0.04, Colors.lightBrightBlue) }}
      />
      <WideSection color={lighten(0.03, Colors.canary)}>
        <FeatureRow>
          <div>
            <div className="iconBg">
              <SvgIcon icon="magwithmonitor" />
            </div>
            <h4>In-Depth Research & Reviews</h4>
            <p>
              We do the research to create fair, in-depth reviews of the top security providers to help you make the
              right choice for you and your family.
            </p>
          </div>
          <div>
            <div className="iconBg">
              <SvgIcon icon="medal" />
            </div>
            <h4>Personalized, Smart Recommendations</h4>
            <p>
              Our smart home security provider tool uses local crime statistics and provider research to give you a
              tailor-made recommendation.
            </p>
          </div>
          <div>
            <div className="iconBg">
              <SvgIcon icon="analytics" />
            </div>
            <h4>Raw Crime Data Analysis</h4>
            <p>
              We translate the FBI's raw crime data from local, state and federal agencies into actionable insights so
              you can protect your home from potential threats.
            </p>
          </div>
        </FeatureRow>
      </WideSection>
      <WideSection>
        <Ctas>
          <h1>Home Security Analysis of the Top Providers</h1>
          <p>
            Navigating home security options can be complicated and confusing. Our team has invested hundreds of ours
            into examining all the options, so you don't have to.
          </p>
          <CtaContainer>
            <Slices>{ctas}</Slices>
          </CtaContainer>
        </Ctas>
      </WideSection>
      <WideSection color={Colors.white}>
        <h2>Recommended for You</h2>
        <Contain>
          <Cards
            pages={[
              { permalink: "/home-security/best", title: "Best Home Security System & Alarm Company For 2019" },
              { permalink: "/home-security/best/self-monitored/", title: "Best Self-Monitored Alarm Systems for 2019" },
              {
                permalink: "/smart-home/compatibility-guide/",
                title: "The Ultimate Guide to Smart Home Compatibility",
              },
              {
                permalink: "/security-cameras/smart-camera-comparison/",
                title: "Comparing Smart Cameras For Home Security",
              },
              {
                permalink: "/safety/apartment-safety-tips/",
                title: "Top 25 Apartment Safety Tips to Protect Your Home",
              },
              { permalink: "/home-security/best/no-contract/", title: "Best No-Contract Security Systems For 2019" },
            ]}
          />
        </Contain>
      </WideSection>
    </div>
  )
}
