import React from "react"
import { FiCheck, FiX } from "react-icons/fi"

const icons = {
  x: FiX,
  check: FiCheck,
}

export default ({ icon, color, size }) => {
  const Icon = icons[icon || "check"]
  let iconSize = `${size || 24}`
  if (!iconSize.includes("px")) {
    iconSize = `${iconSize}px`
  }
  return (
    <div className={`icon icon-${icon}`}>
      <Icon color={color} size={iconSize} />
    </div>
  )
}
