import React from "react"
import prismicSerializer from "../helpers/prismicSerializer"

export default ({ children, className }) => {
  const { header, text } = children
  return (
    <div className={className}>
      {header && <div className="primary-header">{prismicSerializer(header)}</div>}
      {text && <div className="primary-text">{prismicSerializer(text)}</div>}
    </div>
  )
}
