import React from "react"
import styled from "styled-components"
import { lighten } from "polished"
import { Colors } from "../constants/Style"
import SvgIcon from "./SVGIcon"

const StatBlock = styled.div`
  display: flex;
  background: ${Colors.lightGray};
  padding: 1.2rem;
  margin-bottom: 1rem;
  position: relative;
  span {
    display: block;
    font-size: 1.7rem;
    font-weight: bold;
    color: ${Colors.darkBlueGray};
  }
  label {
    display: block;
    color: ${lighten(0.1, Colors.brown)};
  }
  svg {
    position: relative;
    width: 3rem;
    height: 3rem;
    margin-right: 2.2rem;
    fill: ${Colors.brown};
  }
  &:before {
    content: "";
    height: 3rem;
    margin: auto;
    width: 1px;
    background: ${lighten(0.25, Colors.brown)};
    position: absolute;
    left: 5.1rem;
    top: 0;
    bottom: 0;
  }
`

export default ({ icon, value, label }) => {
  return (
    <StatBlock>
      <SvgIcon icon={icon} />
      <div>
        <span>{value}</span>
        <label>{label}</label>
      </div>
    </StatBlock>
  )
}
