import React from "react"
import styled from "styled-components"
import { darken } from "polished"
import { Colors, media } from "../../constants/Style"
import Link from "../Link"
import AspectImage from "../AspectImage"

const Shell = styled.div`
  position: relative;
  top: -0px;
  margin-bottom: -56px;
  background: #fff;
  header {
    width: 100%;
    padding: 14px 0;
    background: ${Colors.veryLightGold};
    border-bottom: 2px solid ${Colors.canvas};
    img {
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
    }
  }
  aside {
    padding: 0.95rem;
  }
  ${media.hand`
    top: -0.7rem;
    header {
      padding: 3px;
    }
    aside {
      padding: 0.6rem;
    }
  `}
`
const Logo = styled(AspectImage)`
  height: 100px;
  width: 100%;
  max-width: 200px;
  margin: 0 auto;
  ${media.hand`
    height: 60px;
    max-width: 140px;
  `}
`
const Button = styled(Link)`
  display: block;
  padding: 1.05rem 2rem 0.9rem;
  font-size: 1.32rem;
  width: 100%;
  border-radius: 5px;
  margin-bottom: 1rem;
  transition: all 0.3s;
  background: linear-gradient(to bottom, rgba(255, 220, 0, 1) 0%, rgba(255, 170, 0, 1) 100%);
  border-bottom: 2px solid #e49a03;
  color: ${darken(0.25, Colors.darkBrown)};
  font-weight: 600;
  text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.5);
  border-radius: 50px;
  text-align: center;
  text-decoration: none;
  transform-origin: center;
  &:last-of-type {
    margin-bottom: 0;
  }
  svg {
    margin-right: 30px;
  }
  transition: 0.3s all;
  &:hover {
    filter: brightness(1.05);
  }
  &:before {
    content: "";
    display: block;
    width: 1px;
    height: 29px;
    position: absolute;
    left: 57px;
    top: 14px;
    z-index: 4;
  }
  ${media.hand`
    margin-bottom: 0.45rem;
    padding: 0.8rem 2rem 0.73rem;
  `}
`
const PrimaryButton = styled(Button)`
  background: linear-gradient(to bottom, rgba(0, 99, 204, 1) 0%, rgba(0, 68, 145, 1) 100%);
  border-radius: 8px;
  border-bottom: 2px solid #002447;
  text-align: center;
  font-size: 1.55rem;
  text-decoration: none;
  padding: 2.1rem 2rem 0.65rem;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
  color: #fff;
  position: relative;
  &:hover {
    filter: brightness(1.15);
  }
  &:before {
    position: absolute;
    width: 100%;
    content: "Call for Free Quote";
    letter-spacing: 0.2px;
    top: 0.8rem;
    font-weight: 600;
    left: 0;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
    font-size: 1.1rem;
  }
  &:hover {
    filter: brightness(1.15);
  }
  ${media.hand`
    margin-bottom: 0.45rem;
    padding: 1.6rem 2rem 0.35rem;
    font-size: 1.45rem !important;
    &:before {
      font-size: 0.95rem;
      top: 0.50rem;
    }
  `}
`

// FRONTPOINTREMOVAL
export default ({ style, uid, companyLogo, salesPhone, website, template }) => (
  <Shell style={style}>
    <header>
      <Link company={uid} to={website.url} name={template || "main"}>
        <Logo {...companyLogo} useVariant="300" />
      </Link>
    </header>
    {uid !== "frontpoint" && (
      <aside>
        {uid !== "frontpoint" && salesPhone && salesPhone.display && salesPhone.phone ? (
          <PrimaryButton tel={salesPhone.phone}>{salesPhone.display}</PrimaryButton>
        ) : null}
        {uid !== "frontpoint" && website && website.url ? (
          <Button company={uid} to={website.url} name={template || "main"}>
            Visit Site »
          </Button>
        ) : null}
      </aside>
    )}
  </Shell>
)
