import React from "react"
import styled from "styled-components"
import { flattenDeep } from "lodash"
import { Colors, media } from "../constants/Style"
import processStringToReact from "../helpers/processStringToReact"
import Image from "./Image"
import { lighten, darken } from "polished"
import prismicSerializer from "../helpers/prismicSerializer"
import Link from "./Link"
import AspectImage from "./AspectImage"

const CTAShell = styled.div`
  .actions a,
  .phone-action {
    display: block;
    padding: 0.8rem 2rem;
    font-size: 1.1rem;
    width: 100%;
    border-radius: 5px;
    margin-bottom: 1rem;
    transition: all 0.3s;
    background: linear-gradient(to bottom, rgba(255, 220, 0, 1) 0%, rgba(255, 170, 0, 1) 100%);
    border-bottom: 2px solid #e49a03;
    color: ${darken(0.26, Colors.darkBrown)};
    font-weight: 600;
    text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.5);
    border-radius: 50px;
    text-align: center;
    text-decoration: none;
    transform-origin: center;
    &:hover {
      filter: brightness(1.05);
    }
    &:last-of-type {
      margin-right: 0;
    }
    &.phoneLink {
      background: linear-gradient(to bottom, rgba(0, 99, 204, 1) 0%, rgba(0, 68, 145, 1) 100%);
      border-radius: 8px;
      border-bottom: 2px solid #002447;
      text-align: center;
      font-size: 1.55rem !important;
      text-decoration: none;
      padding: 2.1rem 2rem 0.65rem !important;
      text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
      color: #fff;
      position: relative;
      &:hover {
        filter: brightness(1.15);
      }
      &:before {
        position: absolute;
        width: 100%;
        content: "Call for Free Quote";
        letter-spacing: 0.2px;
        top: 0.8rem;
        font-weight: 600;
        left: 0;
        text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
        font-size: 1.1rem;
      }
    }
  }
`
const HomeShell = styled(CTAShell).attrs({ className: "action-box" })`
  display: flex;
  background: ${lighten(0.54, Colors.darkBlueGray)};
  padding: 0rem;
  flex-direction: ${({ dir }) => dir || "row"};
  margin-bottom: 1.4rem;
  align-items: center;
  padding: 3rem;
  .logo {
    margin: 0 auto 2.4rem;
    max-height: 130px;
    overflow: hidden;
  }
  && .actions {
    width: 100%;
    a,
    .phone-action {
      padding: 1.15rem 1rem 0.98rem;
      margin-right: 0;
      font-size: 1.3rem;
    }
  }

  aside {
    width: 14rem;
    margin-right: 2rem;
  }
  .cta-head {
    width: 30%;
    flex: none;
    margin-right: 2rem;
  }
  .cta-body {
    width: 40%;
    margin-right: 2rem;
    p,
    li {
      font-size: 0.95rem;
      &:nth-of-type(3) {
        font-size: 1.1rem;
        font-weight: 600;
      }
    }
    h4 {
      font-size: 1.05rem;
      margin-top: 1.5rem;
      margin-bottom: 0.2rem;
      &:first-of-type {
        margin-top: 0;
      }
      &:nth-of-type(3) {
        font-size: 1.2rem;
        font-weight: 600;
        margin-bottom: 0.5rem;
      }
    }
  }
  .cta-right {
    flex: 1;
  }
  .cta-har-score {
    text-align: center;
    background: ${lighten(0.66, Colors.darkBlue)};
    padding: 1rem 0;
    font-size: 1.3rem;
    font-weight: bold;
    margin-top: -3px;
    border: 2px solid ${lighten(0.49, Colors.darkBlue)};
    h6 {
      margin-bottom: 0.25rem;
      font-size: 1rem;
    }
    ${media.hand`
      margin-bottom: 1.3rem;
    `}
  }
  .review-link {
    display: flex;
    justify-content: center;
    a {
      display: inline;
      text-align: center;
      color: ${Colors.darkBlue};
      text-decoration: underline;
      font-size: 0.9rem;
      opacity: 0.7;
      margin-top: 0.5rem;
      transition: all 0.2s;
      line-height: 130%;
      &:hover {
        opacity: 1;
      }
    }
  }
  &.top-align {
    align-items: flex-start;
  }
  ${media.hand`
    flex-direction: column;
    .cta-body,  .cta-head, .cta-right{
      width: 100%;
    }
    .review-link {
      margin-bottom: 1.5rem;
    }
  `}
`

const Shell = styled(CTAShell).attrs({ className: "action-box" })`
  display: flex;
  background: ${lighten(0.36, Colors.lightBlue)};
  padding: 4rem 2rem;
  flex-direction: row;
  margin-bottom: 3.6rem;
  align-items: center;

  aside {
    width: 31%;
    margin-right: 2rem;
    flex: none;
  }
  && .cta-body {
    h4 {
      margin-top: 0;
      margin-bottom: 0;
      padding-top: 0;
    }
    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 1.1rem;
      margin-top: 0.5rem;
      &.actions {
        padding: 0;
        margin-top: 1rem;
        a {
          width: 42%;
          max-width: 12rem;
          padding: 1rem 1.5rem 0.82rem;
          &:first-child {
            margin-right: 1rem;
          }
        }
        &:last-child {
          margin-right: 0;
        }
        a.phoneLink {
          width: 58%;
          flex: 1 !important;
          font-size: 1.3rem !important;
          width: auto !important;
          max-width: 40rem !important;
          padding: 1.5rem 0rem 0.3rem !important;
          &:before {
            font-size: 0.9rem;
            top: 0.45rem;
          }
        }
      }
    }
    h3 {
      font-size: 1.4rem;
      margin-top: 1.3rem;
      margin-bottom: 0.6rem;
      &:first-of-type {
        margin-top: 0;
      }
    }
  }
  ${media.hand`
    flex-direction: column;
    padding:  2rem 2rem 0.8rem;
    aside {
      width: 80% !important;
      margin-right: 0;
    }
    && .cta-body {
      h4 {
          margin-top: 0.6rem;
          padding-top: 0;
      }
      div {
        flex-direction: column;
        width: 100%;
        margin: 0 0 0.5rem 0;
        &.actions a {
          flex: 1;
          text-align: center;
          margin-bottom: 0.4rem;
          width: 100% !important;
          min-width: 100% !important;
          max-width: 100% !important;
          margin: 0 0 1rem !important;
        }
      }
    }
  `}
`

const LogoAloneShell = styled.div`
  position: relative;

  a {
    display: block;
    min-width: 10rem;
    max-height: 8rem;

    .adt-logo {
      max-width: 7rem;
      margin-left: auto;
      margin-right: auto;
    }
  }

  img {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
  }
  ${media.tablet`
    a .logo {
      padding: 0 0.5rem;
    }
  `}
  ${media.hand`
    display: flex;
    flex-direction: column;
  `}
`

const SideShell = styled(CTAShell).attrs({ className: "action-box" })`
  float: ${({ float }) => float};
  margin-left: ${({ float }) => (float === "right" ? "2rem" : 0)};
  margin-right: ${({ float }) => (float === "left" ? "2rem" : 0)};
  margin-top: 0;
  width: 16rem;
  display: flex;
  flex-direction: column;
  padding: 0.9rem;
  background: ${lighten(0.36, Colors.lightBlue)};
  margin-bottom: 2rem;
  &&& .cta-body {
    margin: 0.9rem 0 0.65rem;
    p {
      font-size: 0.9rem;
    }
  }
  .actions {
    border-top: 1px solid ${lighten(0.34, "#777")};
    padding-top: 1rem;
    padding: 0;
    a {
      margin-top: 0.8rem;
      margin-bottom: 0.8rem;
      padding: 0.9rem 0rem 0.7rem;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    a.phoneLink {
      margin-top: 0.5rem;
      flex: 1;
      font-size: 1.3rem !important;
      width: 14.5rem !important;
      padding: 1.5rem 0rem 0.3rem !important;
      &:before {
        font-size: 0.9rem !important;
        top: 0.45rem !important;
      }
    }
  }
  &&& h4 {
    background: ${Colors.darkBlueGray};
    color: ${Colors.white};
    font-size: 1.2rem;
    margin: -0.9rem;
    margin-bottom: 0.6rem;
    padding: 0.5rem 0 0.5rem 0;
    font-weight: 500;
    text-align: center;
  }
  .logo {
    margin: 0.3rem 0 0.4rem;
  }
  &&& ul {
    margin-left: 1.6rem;
    margin-bottom: 0.5rem;
    li {
      &.icon-li {
        list-style-type: none;
        position: relative;
        .icon {
          position: absolute;
          top: 4px;
          left: -25px;
        }
      }
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  ${media.hand`
  float: none;
  `}
`
const processChecks = text => {
  return text.reduce((lines, line) => {
    if (line.text.indexOf("x ") === 0) {
      line.type = "list-item"
      line.icon = "check"
      line.className = "icon-li"
      line.iconColor = Colors.green
      line.text = line.text.replace("x ", "")
    }
    if (line.text.indexOf("- ") === 0) {
      line.type = "list-item"
      line.icon = "x"
      line.iconColor = Colors.red
      line.className = "icon-li"
      line.text = line.text.replace("- ", "")
    }
    lines.push(line)
    return lines
  }, [])
}
const getDesignAndProps = ({ design, name }) => {
  if (["home"].includes(name)) return [name]
  return design ? design.split("_") : [""]
}
export default ({ items, ...props }) => {
  const { cta, company } = props
  if (!cta) return null
  const { header, primaryAction, secondaryAction, includeLogo, richText, richTextSpecial } = cta
  const primary = primaryAction ? processStringToReact(primaryAction) : false
  const primaryElm = primary ? flattenDeep([primary]).find(v => v && v.hasOwnProperty("props")) : false
  const secondary = secondaryAction ? processStringToReact(secondaryAction) : false
  const secondaryElm = secondary ? flattenDeep([secondary]).find(v => v && v.hasOwnProperty("props")) : false
  const [design, ...ctaProps] = getDesignAndProps({ design: props.cta.design, name: cta.internalName })
  const text =
    richText && richText.length
      ? prismicSerializer(richTextSpecial === "ul-as-checkmarks" ? processChecks(richText) : richText)
      : false

  // FRONTPOINTREMOVAL
  if (company.uid === "frontpoint") {
    return null
  }
  switch (design) {
    case "home": {
      return (
        <LogoAloneShell>
          <Link to={`/home-security/${company.uid}`}>
            <Image
              className={`logo ${company.uid}-logo`}
              {...company.data.companyLogo}
              alt={company.data.name + " logo"}
              forceLoaded
            />
          </Link>
        </LogoAloneShell>
      )
    }
    case "proscons": {
      return (
        <HomeShell className="top-align sidebar-actions cta-body">
          <div className="cta-head">
            <Link to={`/home-security/${company.uid}`}>
              <AspectImage
                className={`logo ${company.uid}-logo`}
                {...company.data.companyLogo}
                alt={company.data.name + " logo"}
                useVariant="600"
              />
            </Link>
            <div className="cta-har-score">
              <h6>HomeAlarmReport Score</h6>
              {company.data.valueScore}/10
            </div>
          </div>
          <div className="cta-body">{text && <div>{text}</div>}</div>
          <div className="cta-right">
            <div className="actions">
              {primary
                ? React.cloneElement(primaryElm, {
                    className: `${primaryElm.props.className} action phone-action`,
                  })
                : null}
              {secondary
                ? React.cloneElement(secondaryElm, {
                    children: "Visit Site »",
                  })
                : null}
            </div>
            <div className="review-link">
              <Link
                to={`/home-security/${company.uid}/reviews/`}
              >{`Read Our Complete Review of ${company.data.name}`}</Link>
            </div>
          </div>
        </HomeShell>
      )
    }
    case "side": {
      return (
        <SideShell float={ctaProps[0]}>
          {header && header.length > 0 && <h4>{header}</h4>}
          <AspectImage
            className="logo"
            {...company.data.companyLogo}
            alt={company.data.name + " logo"}
            useVariant="300"
          />
          <div className="cta-body">{text && <div>{text}</div>}</div>
          <div className="actions">
            {primaryAction && processStringToReact(primaryAction)}
            {secondary
              ? React.cloneElement(secondaryElm, {
                  children: "Visit Site »",
                })
              : null}
          </div>
        </SideShell>
      )
    }
    default: {
      return (
        <Shell>
          {includeLogo && (
            <aside>
              <Image className="logo" {...company.data.companyLogo} alt={company.data.name + " logo"} forceLoaded />
            </aside>
          )}
          <div className="cta-body">
            {header && header.length > 0 && <h4>{header}</h4>}
            {text && <div>{text}</div>}
            <div className="actions">
              {primaryAction && processStringToReact(primaryAction)}
              {secondary
                ? React.cloneElement(secondaryElm, {
                    children: "Visit Site »",
                  })
                : null}
            </div>
          </div>
        </Shell>
      )
    }
  }
}
