import React from "react"
import styled from "styled-components"
import { lighten } from "polished"
import { Colors } from "../constants/Style"
import Contain from "./Contain"

const Section = styled.div`
  background-color: ${({ backgroundColor }) => backgroundColor || lighten(0.04, Colors.lightBrightBlue)};
  width: 100%;
  padding: 5rem 0;
`

export default ({ color, children }) => {
  return (
    <Section backgroundColor={color}>
      <Contain>{children}</Contain>
    </Section>
  )
}
