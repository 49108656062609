import React from "react"
import PropTypes from "prop-types"
import { Normalize } from "styled-normalize"
import SEO from "./SEO/SEO"
import Footer from "./footer"
import "./layout.css"
import Header from "./header"
import { GlobalStyles } from "../GlobalStyles"

const Layout = ({ children, seo, settings }) => {
  return (
    <div class="site-wrapper">
      <Normalize />
      <GlobalStyles />
      <SEO {...seo} />
      <Header {...settings} />
      {children}
      <Footer {...settings} />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
