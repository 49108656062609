import React, { useState } from "react"
import styled from "styled-components"
import Select from "react-select"
import { lighten } from "polished"
import gql from "graphql-tag"
import { Colors } from "../constants/Style"
import apollo from "../lib/apollo"
import getCodeFromNetworkError from "../helpers/getCodeFromNetworkError"

const Shell = styled.div`
  p {
    background: ${lighten(0.35, Colors.gold)};
    padding: 2rem;
    line-height: 140%;
    &.no-form-msg {
      background: ${lighten(0.38, Colors.red)};
      margin-bottom: 2rem !important;
    }
  }
  label {
    display: block;
    font-weight: bold;
    margin-bottom: 0.2rem;
  }
  .select {
    margin-bottom: 2rem;
  }
`

const Form = styled.form`
  width: 100%;
  padding: 2rem;
  background: ${lighten(0.55, Colors.darkBlueGray)};
  h3 {
    margin-top: 0;
    color: ${Colors.darkBlue};
  }
  label {
    display: block;
    font-weight: 600;
    margin-bottom: 0.05rem;
  }
  input,
  textarea {
    padding: 0.6rem;
    font-size: 1.1rem;
    margin-bottom: 1.4rem;
    border: 1px solid ${lighten(0.48, Colors.darkBlueGray)};
    width: 100%;
  }
  textarea {
    width: 100%;
  }
  label {
    display: block;
    color: ${Colors.darkBlueGray};
    margin-bottom: 0.2rem;
  }
  button {
    padding: 0.85rem 2rem;
    border: 0;
    background: ${Colors.red};
    color: ${Colors.white};
  }
`
const Error = styled.div`
  padding: 2rem;
  background: ${Colors.red};
  color: #fff;
  margin-top: 2rem;
`
const Success = styled.div`
  padding: 2rem;
  background: ${Colors.green};
  color: #fff;
  margin-bottom: 2rem;
`

const reason = [
  {
    showForm: false,
    label: "Consumers: Security System or Smart Home Issues",
    value: "issues",
    descr:
      "If you’re having issues with your security system or smart home device please contact your provider or manufacturer to troubleshoot them. If you’re looking for more general help, please use our 'ask a question' on the relevant page of HomeAlarmReport",
  },
  {
    showForm: false,
    label: "Consumers: Billing Issues",
    value: "billing",
    descr:
      "Sorry, we’re not a home security provider. Please contact your provider directly. You can find this on your monthly bill. If you contact us we really can’t help you even though we want to.",
  },
  {
    showForm: true,
    label: "Journalists & Researchers: Request for Information",
    value: "info",
    descr:
      "Looking for details on our data or a custom report? Drop us a note and we can chat about your requirements and how HomeAlarmReport might be able to help.",
  },
  {
    showForm: true,
    label: "Partner with Home Alarm Report",
    value: "partner",
    descr:
      "If you’re looking to help drive more consumers to your security or smart home business, drop us a note. We may be able to help.",
  },
  {
    showForm: true,
    label: 'Send HomeAlarmReport a "Love Note"',
    value: "love",
    descr:
      "We’d love to hear from you. If you have a suggestion or just want to get in contact with us this is the option to use.",
  },
]

const submitFormMutation = gql`
  mutation CreateContact($input: CreateContactInput!) {
    createContact(input: $input) {
      clientMutationId
    }
  }
`

let errorTimo = 0
const submitForm = async (form, formState, setFormState, e) => {
  clearTimeout(errorTimo)
  e.preventDefault()
  e.stopPropagation()
  let error = false
  if (!form.message || !form.message.length > 20) {
    error = "Please include our message"
  }
  if (!form.email || !form.email.length) {
    error = "You'll need to add your email"
  }
  if (!form.name || !form.name.length) {
    error = "You'll need to add your name"
  }
  if (error) {
    setFormState({ error })
    errorTimo = setTimeout(() => {
      setFormState("ready")
    }, 4400)
  } else {
    if (formState !== "process") {
      setFormState("process")
      try {
        form.ipaddress = "192.168.1.255"
        await apollo.mutate({
          mutation: submitFormMutation,
          variables: { input: { contact: form } },
        })
        window.scrollTo({ top: 0 })
        setFormState("success")
      } catch (e) {
        const code = getCodeFromNetworkError(e.message)
        setFormState({
          error:
            code === 429
              ? "Wait a bit before submitting another review"
              : code === 409
              ? "You already reviewed this company."
              : "There was a server issue, our team has been notified",
        })
      }
    }
  }
}

export default () => {
  const [form, setForm] = useState({ name: "", email: "", reason: "" })
  const [formState, setFormState] = useState("ready")
  const selected = reason.find(({ value }) => value === form.reason) || { showForm: false, descr: "" }
  const { showForm, descr } = selected
  const btnText = formState === "process" ? "Submitting..." : "Submit Message"
  if (formState === "success") {
    return (
      <Success>
        Your message was successfully submitted! Our team will review your submission and respond as soon as possible.
      </Success>
    )
  }
  return (
    <Shell>
      <p>
        Please Note: We are not a home security provider. If you are having issues with your home security system or
        smart home devices, please contact the manufacturer. For all other requests please use the contact form to the
        right or leave us a voicemail at
      </p>
      <label>Contact Reason</label>
      <Select
        options={reason}
        value={selected.descr ? selected : null}
        onChange={({ value }) => setForm({ ...form, reason: value })}
        className="select"
      />
      {!showForm && descr.length > 0 && <p className="no-form-msg">{descr}</p>}
      {showForm && (
        <Form onSubmit={e => submitForm(form, formState, setFormState, e)}>
          <div>
            <label id="contact-name">Your Name</label>
            <input
              type="text"
              aria-labelledby="contact-name"
              onChange={({ target: { value } }) => setForm({ ...form, name: value })}
            />
          </div>
          <div>
            <label id="contact-email">Your Email</label>
            <input
              type="text"
              aria-labelledby="contact-email"
              onChange={({ target: { value } }) => setForm({ ...form, email: value })}
            />
          </div>
          <div>
            <label id="contact-msg">Your Message</label>
            <textarea
              type="text"
              aria-labelledby="contact-msg"
              onChange={({ target: { value } }) => setForm({ ...form, message: value })}
            />
          </div>
          <div>
            <button aria-label="Submit Your Question">{btnText}</button>
            {formState.hasOwnProperty("error") && <Error>{formState.error}</Error>}
          </div>
        </Form>
      )}
    </Shell>
  )
}
