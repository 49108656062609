const sanitizeForUrl = input => {
  return input
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, "-")
    .replace(/^-+/, "")
    .replace(/-+$/, "")
}

module.exports = {
  sanitizeForUrl,
  page: ({ parents = [], current, siteRoot = "/", includeRoot = false }) => {
    const outputSlug = parents.reduce((pv, cv) => {
      return pv + sanitizeForUrl(cv.slug) + "/"
    }, "")
    let output = outputSlug + sanitizeForUrl(current.slug)
    if (includeRoot) {
      output = siteRoot + outputSlug + sanitizeForUrl(current.slug)
    }
    if (output[output.length - 1] !== "/") {
      output = output + "/"
    }

    return output
  },
  state: ({ state }) => {
    return sanitizeForUrl(state.name) + "/"
  },
  city: ({ state, city }) => {
    return sanitizeForUrl(state.name) + "/" + sanitizeForUrl(city.name) + "/"
  },
}
