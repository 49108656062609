import { css } from "styled-components"
import { darken } from "polished"

const colors = {
  white: "#fff",
  veryLightBlue: "#F4F9FF",
  lightBlue: "#7F9AB8",
  darkBlue: "#154F8F",
  lightBrightBlue: "#E1EDFC",
  darkBlueGray: "#5B6B7D",
  lightGray: "#FAFAFA",
  brown: "#898886",
  darkBrown: "#716E68",
  canary: "#FFFCF3",
  canvas: "#F1EDE1",
  veryLightGold: "#FFFCF3",
  gold: "#ECC537",
  green: "#499F41",
  red: "#DB3640",
  greenGray: "#F3F4F2",
  linkBlue: "#0016FF",
}

const { darkBlue, darkBlueGray } = colors
export const Colors = {
  medText: darken(0.25, darkBlueGray),
  darkText: darkBlue,
  ...colors,
}

export const Dims = {
  container: "76rem",
  wideContainer: "86rem",
  tabletPadding: "0 1rem",
  handPadding: "0 0.94rem",
}

export const Fonts = {
  sans: `'Chivo', sans-serif`,
}

export const media = {
  handxs: (...args) =>
    css`
      @media (max-width: 320px) {
        ${css(...args)};
      }
    `,
  handsm: (...args) =>
    css`
      @media (max-width: 375px) {
        ${css(...args)};
      }
    `,
  hand: (...args) =>
    css`
      @media (max-width: 415px) {
        ${css(...args)};
      }
    `,
  aboveTablet: (...args) =>
    css`
      @media (min-width: 1122px) {
        ${css(...args)};
      }
    `,
  handlg: (...args) =>
    css`
      @media (max-width: 767px) {
        ${css(...args)};
      }
    `,
  tabletOnly: (...args) =>
    css`
      @media (min-width: 768px) and (max-width: 1122px) {
        ${css(...args)};
      }
    `,
  tablet: (...args) =>
    css`
      @media (max-width: 1122px) {
        ${css(...args)};
      }
    `,
  lapsm: (...args) =>
    css`
      @media (max-width: 1388px) {
        ${css(...args)};
      }
    `,
  lap: (...args) =>
    css`
      @media (max-width: 1441px) {
        ${css(...args)};
      }
    `,
  short: (...args) =>
    css`
      @media (max-height: 960px) {
        ${css(...args)};
      }
    `,
  xx: (...args) =>
    css`
      @media (min-resolution: 1.25dppx) {
        ${css(...args)};
      }
    `,
  xxx: (...args) =>
    css`
      @media (min-resolution: 2.25dppx) {
        ${css(...args)};
      }
    `,
}
