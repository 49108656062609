const React = require("react")
const { RichText } = require("prismic-reactjs")
const Children = require("react-children-utilities")
const { isString, isArray } = require("lodash/lang")
const { sanitizeForUrl } = require("./slugBuilder")
const Link = require("../components/Link").default
const Icon = require("../components/Icon").default

const Elements = RichText.Elements

const propsWithUniqueKey = function(props, key) {
  return Object.assign(props || {}, { key })
}

const linkResolver = () => {}

const processChildren = (children, fnc, forceStr = false) => {
  if (isArray(children)) {
    return children.map(child => processChildren(child, fnc, forceStr))
  } else if (isString(children)) {
    return fnc(children)
  } else if (children && children.props !== undefined && forceStr) {
    return fnc(Children.onlyText(children))
  }
  return children
}

const strToReact = children => {
  const processStringToReact = require("./processStringToReact").default
  children = processChildren(children, processStringToReact)
  return children
}

const htmlSerializer = function(type, element, content, children, key) {
  var props = {}
  children = strToReact(children)
  switch (type) {
    case Elements.heading1:
      return React.createElement("h1", propsWithUniqueKey(props, key), children)

    case Elements.heading2:
      props.id = processChildren(children, sanitizeForUrl, true)
      return React.createElement("h2", propsWithUniqueKey(props, key), children)

    case Elements.heading3:
      return React.createElement("h3", propsWithUniqueKey(props, key), children)

    case Elements.heading4:
      return React.createElement("h4", propsWithUniqueKey(props, key), children)

    case Elements.heading5:
      return React.createElement("h5", propsWithUniqueKey(props, key), children)

    case Elements.heading6:
      return React.createElement("h6", propsWithUniqueKey(props, key), children)

    case Elements.paragraph:
      return React.createElement("p", propsWithUniqueKey(props, key), children)

    case Elements.preformatted:
      return React.createElement("pre", propsWithUniqueKey(props, key), children)

    case Elements.strong:
      return React.createElement("strong", propsWithUniqueKey(props, key), children)

    case Elements.em:
      return React.createElement("em", propsWithUniqueKey(props, key), children)

    case Elements.listItem:
      let pre
      if (element.icon) {
        pre = <Icon icon={element.icon} color={element.iconColor} size="20" />
      }
      return React.createElement("li", { className: element.className, ...propsWithUniqueKey(props, key) }, [
        pre,
        children,
      ])

    case Elements.oListItem:
      return React.createElement("li", propsWithUniqueKey(props, key), children)

    case Elements.list:
      return React.createElement("ul", propsWithUniqueKey(props, key), children)

    case Elements.oList:
      return React.createElement("ol", propsWithUniqueKey(props, key), children)

    case Elements.image:
      const linkUrl = element.linkTo ? element.linkTo.url || linkResolver(element.linkTo) : null

      const linkTarget = element.linkTo && element.linkTo.target ? { target: element.linkTo.target } : {}

      const linkRel = linkTarget.target ? { rel: "noopener" } : {}

      const img = React.createElement("img", { src: element.url, alt: element.alt || "" })

      return React.createElement(
        "p",

        propsWithUniqueKey({ className: [element.label || "", "block-img"].join(" ") }, key),

        linkUrl ? React.createElement("a", Object.assign({ href: linkUrl }, linkTarget, linkRel), img) : img
      )

    case Elements.embed:
      props = Object.assign(
        {
          "data-oembed": element.oembed.embed_url,

          "data-oembed-type": element.oembed.type,

          "data-oembed-provider": element.oembed.provider_name,
        },
        element.label ? { className: element.label } : {}
      )

      const embedHtml = React.createElement("div", { dangerouslySetInnerHTML: { __html: element.oembed.html } })

      return React.createElement("div", propsWithUniqueKey(props, key), embedHtml)

    case Elements.hyperlink:
      const targetAttr = element.data.target ? { target: element.data.target } : {}

      const relAttr = element.data.target ? { rel: "noopener" } : {}

      props = Object.assign(
        {
          href: element.data.url || linkResolver(element.data),
        },
        targetAttr,
        relAttr
      )

      return React.createElement(Link, propsWithUniqueKey(props, key), children)

    case Elements.label:
      props = element.data ? Object.assign({}, { className: element.data.label }) : {}

      return React.createElement("span", propsWithUniqueKey(props, key), children)

    case Elements.span:
      if (content) {
        return content.split("\n").reduce((acc, p) => {
          if (acc.length === 0) {
            return [p]
          } else {
            const brIndex = (acc.length + 1) / 2 - 1

            const br = React.createElement("br", propsWithUniqueKey({}, brIndex))

            return acc.concat([br, p])
          }
        }, [])
      } else {
        return null
      }

    default:
      // Always include a default that returns null

      return null
  }
}

export default node => RichText.render(node, linkResolver, htmlSerializer)
