import React from "react"
import Gear from "../images/svg-components/technical-support.svg"
import UsFlag from "../images/svg-components/us-flag.svg"
import HomeLock from "../images/svg-components/home-lock.svg"
import Camera from "../images/svg-components/cctv.svg"
import Brain from "../images/svg-components/brain.svg"
import MagWithEye from "../images/svg-components/investigate.svg"
import Handcuffs from "../images/svg-components/handcuffs.svg"
import Apartment from "../images/svg-components/apartment.svg"
import Analytics from "../images/svg-components/analytics.svg"
import MagWithMonitor from "../images/svg-components/monitor.svg"
import Medal from "../images/svg-components/medal.svg"

const icons = {
  gear: Gear,
  usflag: UsFlag,
  homelock: HomeLock,
  camera: Camera,
  brain: Brain,
  magwitheye: MagWithEye,
  magwithmonitor: MagWithMonitor,
  handcuffs: Handcuffs,
  apartment: Apartment,
  analytics: Analytics,
  medal: Medal,
}

export default ({ icon, yshift = 0 }) => {
  const Icon = icons[icon]
  return <Icon style={{ position: "relative", top: `${yshift}px` }} />
}
