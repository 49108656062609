import styled from "styled-components"
import { Colors, Dims, media } from "../constants/Style"
import { lighten, darken } from "polished"
export default styled.article`
  width: ${Dims.container};
  padding-right: ${({ narrow }) => `${narrow ? 20 : 0}rem}`};
  margin: 5.375rem auto;
  .top-overlay:first-child {
    padding: 3rem;
    background: #fff;
    margin-bottom: 1.5rem;
    box-shadow: 0 -30px 80px rgba(0, 0, 0, 0.03);
    ${media.tablet`
    margin-right: 0;
    `}
    ${media.hand`
    padding: 1rem;
    margin-right: -0.9rem;
    margin-left: -0.9rem;
    margin-top: 3rem;
    `}
  }
  &.wide {
    width: 100%;
    max-width: 100%;
    section.primary {
      width: 100%;
      max-width: 100%;
      padding-right: 0;
    }
  }
  section.primary {
    max-width: 54.5rem;
    padding-right: 4rem;
    h2 {
      font-size: 2.2rem;
      margin-top: 2rem;
      padding-top: 2rem;
    }
    h3 {
      font-size: 1.7rem;
      margin-top: 1rem;
      padding-top: 1rem;
      margin-bottom: 1rem;
    }
    h4 {
      font-size: 1.4rem;
      margin-top: 1rem;
      padding-top: 1rem;
      margin-bottom: 1rem;
    }
    h5 {
      font-size: 1.2rem;
      margin-top: 1rem;
      padding-top: 1rem;
      margin-bottom: 1rem;
    }
    p {
      margin-bottom: 1.5rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
    p,
    li {
      font-size: 1.125rem;
      line-height: 160%;
      color: ${darken(0.12, Colors.darkBlueGray)};
    }
    ul,
    ol {
      padding-left: 0rem;
      margin-bottom: 1.5rem;
    }
    li {
      font-size: 1.125rem;
      margin-bottom: 0.5rem;
    }
    .primary-text {
      margin-bottom: 2.5rem;
    }
    p a,
    li a {
      color: ${Colors.linkBlue};
      text-decoration: none;
      border-bottom: 1px solid ${lighten(0.0, Colors.linkBlue)};
    }
    *:first-child h2 {
      margin-top: -2rem;
      padding-top: 2rem;
    }
  }
  ${media.tablet`
    flex-direction: column;
    width: 100%;
    padding: ${Dims.tabletPadding};
    section.primary {
      padding-right: 0rem;
    }
  `}
  ${media.hand`
    padding: ${Dims.handPadding};
  `}
`
