import styled from "styled-components"
import { Colors, media } from "../constants/Style"

export default styled.div`
  padding: 3rem;
  background: ${Colors.veryLightBlue};
  margin-bottom: 2.5rem;
  && h2 {
    margin-top: 0;
    padding-top: 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    li:last-child {
      margin-bottom: 0;
    }
  }
  && h5 {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }
  ${media.hand`
    padding: 2rem 1.6rem;
    margin-bottom: 1.5rem;
    && h2 {
      margin-bottom: -0.7rem;
    }
    &&& h4 {
      margin-bottom: 0.7rem;
      margin-top: 0;
    }
  `}
`
