import React from "react"
import prismicSerializer from "../../helpers/prismicSerializer"
import InnerContentBlock from "../InnerContentBlock"
import processStringToReact from "../../helpers/processStringToReact"

export default ({ primary, items }) => (
  <InnerContentBlock>
    {prismicSerializer(primary.header)}
    {items && items.length > 0 ? (
      <ul>
        {items.map(item => (
          <li>
            <h5>{item.question}</h5>
            <p>{processStringToReact(item.answer)}</p>
          </li>
        ))}
      </ul>
    ) : null}
  </InnerContentBlock>
)
// return
