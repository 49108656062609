import React from "react"
import styled from "styled-components"
import Contain from "./Contain"
import { lighten } from "polished"
import Link from "./Link"
import { Colors, media } from "../constants/Style"
import ContentImage from "./ContentImage"

const Shell = styled(Contain)`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 4rem;
  h4 {
    font-size: 1.7rem;
    width: 100%;
    margin-bottom: 1.2rem;
    ${media.hand`
      margin-top: 4rem;
    `}
  }
`
const Block = styled(Link)`
  display: block;
  width: calc(33% - 0.46rem);
  min-height: 100px;
  margin-right: 1rem;
  margin-bottom: 1rem;
  padding: 1.2rem;
  background: ${lighten(0.52, Colors.darkBlueGray)};
  transition: 0.2s all;
  .img {
    width: calc(100% + 2.4rem);
    margin: -1.2rem -1.2rem 1.2rem -1.2rem;
    position: relative;
    overflow: hidden;
    height: 242px;
    background: linear-gradient(45deg, rgba(108, 164, 204, 1) 0%, rgba(25, 95, 148, 1) 100%);
  }
  .contentImage {
    height: 100%;
    img {
      object-fit: cover;
    }
  }
  && {
    color: ${Colors.darkBlue};
    border-bottom: 2px solid ${lighten(0.48, Colors.darkBlueGray)};
  }
  h6 {
    font-size: 1rem;
    width: 90%;
    line-height: 120%;
  }
  &:hover {
    border-bottom: 2px solid ${lighten(0.0, Colors.lightBrightBlue)};
    background: ${lighten(0.02, Colors.lightBrightBlue)};
  }
  ${media.aboveTablet`
  &:nth-of-type(3n) {
    margin-right: 0;
  }
  `}
  ${media.tablet`
    width: calc(50% - 0.5rem);
    &:nth-of-type(2n) {
      margin-right: 0;
    }
    .img {
      height: 180px;
    }
  `}
  ${media.hand`
    width: 100%;
    margin-right: 0;
  `}
`

export default ({ pages, title, showImage = false }) => {
  return (
    <Shell>
      {title && <h4>Related Pages</h4>}
      {pages.map(c => (
        <Block key={c.id} to={c.permalink}>
          {showImage && (
            <div className="img">
              <ContentImage {...c.image} />
            </div>
          )}
          <h6>{c.title}</h6>
          <div>{c.description}</div>
        </Block>
      ))}
    </Shell>
  )
}
