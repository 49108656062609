import React, { useState } from "react"
import styled from "styled-components"
import Markdown from "react-remarkable"
import dayjs from "dayjs"
import { Colors, media } from "../constants/Style"
import { lighten } from "polished"
import apollo from "../lib/apollo"
import gql from "graphql-tag"
import RatingStars from "./RatingStars"

const Shell = styled.div`
  h3 {
    font-size: 2rem;
  }
`
const ReviewLayout = styled.div`
  display: flex;
  background: ${Colors.white};
  border-bottom: 1px solid ${lighten(0.3, Colors.lightBlue)};
  margin: 1.2rem 0 4rem;
  && {
    padding-right: 0;
  }

  && p {
    font-size: 0.98rem !important;
    margin-bottom: 1.5rem;
  }
  .actions {
    margin: 2rem 0;
    display: flex;
    width: 18rem;
    button {
      flex: 1;
      text-align: center;
      cursor: pointer;
      padding: 0.65rem 1.3rem;
      border: 1px solid ${lighten(0.45, Colors.darkBlueGray)};
      background: ${lighten(0.53, Colors.darkBlueGray)};
      color: ${lighten(0.0, Colors.darkBlueGray)};
      transition: all 0.2s;
      font-size: 0.88rem;
      &:first-child {
        border-radius: 4px 0 0 4px;
        border-right: 0;
      }
      &:last-child {
        border-radius: 0 4px 4px 0;
      }
      &:hover {
        background: ${lighten(0.51, Colors.darkBlueGray)};
      }
    }
  }
  aside {
    width: 12rem;
    flex: none;
    padding-left: 2rem;
    padding-right: 0;
    border-left: 1px solid ${lighten(0.3, Colors.lightBlue)};
    margin-left: 2rem;
    label {
      font-size: 0.94rem;
      margin-bottom: 0.2rem;
      margin-top: 0;
      display: block;
      font-weight: 500;
      color: ${lighten(0.0, Colors.darkBlueGray)};
    }
    > div {
      margin-bottom: 1rem;
    }
  }
  ${media.hand`
    flex-direction: column-reverse;
    padding-bottom: 1.2rem;
    aside {
      display: flex;
      width: 100%;
      justify-content: center;
      border-left: 0;
      margin-top: 0.2rem;
      padding-left: 0;
      margin-left: 0;
      label {
        width: 100%;
        text-align: center;
      }
      .row {
        width: 100%;
        justify-content: center;
      }
    }
    .actions {
      margin: 2rem auto;
    }
  `}
`
const ReviewShell = styled.section`
  h5 {
    color: ${Colors.darkBlue};
    font-size: 1.2rem;
  }
  h6 {
    font-size: 1rem;
    font-weight: normal;
    margin-top: -0.9rem;
    margin-bottom: 0;
    color: ${lighten(0.2, Colors.darkBlueGray)};
  }
  .review-content {
    flex: 1;
  }
`

const submitVoteMutation = gql`
  mutation CreateVote($input: CreateVoteInput!) {
    createVote(input: $input) {
      clientMutationId
    }
  }
`

const submitVote = async ({ id, useful, rated, submitting, setSubmitting, setRated }) => {
  if (!submitting) {
    setSubmitting(id)
    try {
      await apollo.mutate({
        mutation: submitVoteMutation,
        variables: { input: { vote: { reviewId: id, useful, ipaddress: "" } } },
      })
      setSubmitting(false)
      setRated([...rated, id])
    } catch (e) {}
  }
}
const Review = ({
  id,
  raterName,
  title,
  body,
  createdAt,
  equipmentProtectionScore,
  homeAutomationScore,
  customerServiceScore,
}) => {
  const [submitting, setSubmitting] = useState(false)
  const [rated, setRated] = useState([])
  const isRated = rated.includes(id)
  return (
    <ReviewShell>
      <h5>{title}</h5>
      <h6>{`Reviewed${raterName ? ` by ${raterName}` : ""} on ${dayjs(createdAt).format("MMM. D, YYYY")}`}</h6>
      <ReviewLayout>
        <div className="review-content">
          <Markdown>{body}</Markdown>
          <div className="actions">
            {isRated && <button>Thanks for Rating!</button>}
            {!isRated && submitting === id && <button>Submitting...</button>}
            {!isRated && submitting !== id && (
              <>
                <button
                  aria-label="Vote Helpful"
                  onClick={() => submitVote({ id, useful: true, submitting, rated, setSubmitting, setRated })}
                >
                  {submitting === id ? "Submitting..." : "This is Helpful"}
                </button>
                <button
                  aria-label="Vote Note Helpful"
                  onClick={() => submitVote({ id, useful: false, submitting, rated, setSubmitting, setRated })}
                >
                  {submitting === id ? "Submitting..." : "Not Helpful"}
                </button>
              </>
            )}
          </div>
        </div>
        <aside>
          <div>
            <label>Customer Service</label>
            <RatingStars size="20" rating={customerServiceScore / 2} />
          </div>
          <div>
            <label>Equipment Protection</label>
            <RatingStars size="20" rating={equipmentProtectionScore / 2} />
          </div>
          <div>
            <label>Home Automation</label>
            <RatingStars size="20" rating={homeAutomationScore / 2} />
          </div>
        </aside>
      </ReviewLayout>
    </ReviewShell>
  )
}
export default ({ reviews }) => (
  <Shell>
    {reviews.map(review => (
      <Review {...review} key={review.id} />
    ))}
  </Shell>
)
