import React from "react"
import Helmet from "react-helmet"
import PropTypes from "prop-types"

// import SchemaOrg from './SchemaOrg'

const SEO = ({ pageTitle, permalink, metaDescription, featuredImageUrl, schemaArray, social, template }) => {
  const root = "https://homealarmreport.com"
  return (
    <Helmet>
      <script
        type="text/javascript"
        innerHTML={`
        if (typeof ga === "function") {
          ga('set', 'dimension1', '${template}');
        }
            `}
      />
      {permalink.includes("/404/") ? null : <link rel="canonical" href={permalink} />}
      <title>{pageTitle}</title>
      {!metaDescription ? null : <meta name="description" content={metaDescription} />}
      {!metaDescription ? null : <meta property="og:description" content={metaDescription} />}
      {!metaDescription ? null : <meta property="twitter:description" content={metaDescription} />}
      {!featuredImageUrl ? null : <meta property="image" content={`${root}${featuredImageUrl}`} />}
      {!featuredImageUrl ? null : <meta property="og:image" content={`${root}${featuredImageUrl}`} />}
      {!featuredImageUrl ? null : <meta property="twitter:image" content={`${root}${featuredImageUrl}`} />}
      <meta content="telephone=no" property="format-detection" />
      <meta property="og:url" content={permalink} />
      <meta property="twitter:url" content={permalink} />
      <meta property="og:type" content="article" />
      <meta property="og:title" content={pageTitle} />
      <meta property="fb:app_id" content={social.fbAppId} />
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:creator" content={social.twitter} />
      <meta property="twitter:title" content={pageTitle} />
      {!schemaArray || !Array.isArray(schemaArray)
        ? null
        : schemaArray.map((schema, i) => (
            <script key={i} type="application/ld+json">
              {schema}
            </script>
          ))}
    </Helmet>
  )
}

SEO.propTypes = {
  metaDescription: PropTypes.string,
  // TODO: We should fill these in so the build fails if a page is missing a title...
}

SEO.defaultProps = {
  metaDescription: "",
  pageTitle: "",
  permalink: "",
  featuredImage: "",
  social: {
    twitter: "",
    fbAppId: "",
  },
}

export default SEO
