import React from "react"
import reactProcessString from "react-process-string"
import Ref from "../components/Ref"
import Table from "../components/Slices/Table"
import Phone from "../components/Phone"
import Link from "../components/Link"
import Icon from "../components/Icon"
import PrivacyPolicy from "../components/PrivacyPolicy"
import AspectImage from "../components/AspectImage"

let componentObjects = []
let globalObjects = []

const components = {
  link: Link,
  image: AspectImage,
  icon: Icon,
  table: Table,
  ref: Ref,
  phone: Phone,
  privacypolicy: PrivacyPolicy,
  a: "a",
}

export default str => {
  const config = [
    {
      regex: /%\|(.+?)\|/gim,
      fn: (key, result) => {
        const id = result[0].match(/\d+/)[0]
        let compObj = componentObjects[id]
        if (compObj) {
          const { type, content, tag, ...props } = compObj
          const component = components[tag]
          return React.createElement(component, { ...props, children: content })
        }
      },
    },
    {
      regex: /\$\|(.+?)\|/gim,
      fn: (key, result) => {
        const id = result[0].match(/\d+/)[0]
        let compObj = globalObjects[id]
        if (compObj) {
          const { type, content, tag, ...props } = compObj
          const component = components[tag]
          return React.createElement(component, { ...props, children: content })
        }
      },
    },
    {
      regex: /___<{(.+?)}>___/gim,
      fn: (key, result) => {
        const out = result[0].replace(/(.+)?___</g, "").replace(/>___(.+)?/g, "")
        const { type, content, tag, ...props } = JSON.parse(out)
        const component = components[tag]
        return React.createElement(component, { ...props, children: content })
      },
    },
  ]
  if (typeof str === "string") return reactProcessString(config)(str)
  return str
}
export const setComponentObjects = (objs, globalObs) => {
  componentObjects = objs
  globalObjects = globalObs
  // console.log(objs)
  // console.log(authObs)
}
