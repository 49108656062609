import React from "react"
import styled from "styled-components"
import { Colors, media } from "../constants/Style.js"
import { lighten } from "polished"
import { navigateTo } from "gatsby-link"
import Usa from "@svg-maps/usa"
import { SVGMap } from "react-svg-map"

const Shell = styled.div`
  width: 100%;
  background: #fff;
  box-shadow: 2px 6px 10px rgba(0, 0, 0, 0.04);
  margin-bottom: 5.625rem;
  &&& h2 {
    background: ${Colors.darkBlue};
    display: block;
    width: 100%;
    padding: 1rem !important;
    color: #fff;
    margin-bottom: 0;
    font-size: 1.05rem;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.4px;
  }
  .map-wrap {
    padding: 40px 40px 20px 0px;
  }
  ${media.hand`
    margin-top: 3rem;
  `}
`
const Map = styled(SVGMap)`
  width: 100%;
  height: auto;
  stroke: #fff;
  stroke-width: 3;
  stroke-opacity: 1;
  stroke-linecap: round;
  stroke-linejoin: round;

  .svg-map__location {
    fill: ${lighten(0.4, Colors.darkBlueGray)};
    cursor: pointer;
    stroke-width: 3px;
    transition: all 150ms;

    &:hover {
      fill: ${lighten(0.32, Colors.darkBlueGray)};
    }
    &.svg-map__is-covered {
      fill: ${lighten(0.1, Colors.green)};
      outline: 0;

      &:hover {
        fill: ${lighten(0.02, Colors.green)};
      }
    }
  }
`
const slug = str => str.replace(/\s/g, "-").toLowerCase()
export default ({ coverage, companyName }) => {
  const getLocationClassName = (location, index) => {
    let str = "not-covered"
    if (coverage === "nationwide" || coverage.includes(location.name)) str = "is-covered"
    return `svg-map__location svg-map__${str}`
  }
  const click = location => {
    const state = slug(location.target.attributes.name.value)
    navigateTo(`/safety/${state}`)
  }
  return (
    <Shell>
      <h2>{companyName} Service Area &amp; Coverage Map</h2>
      <div className="map-wrap">
        <Map map={Usa} onLocationClick={click} locationClassName={getLocationClassName} />
      </div>
    </Shell>
  )
}
