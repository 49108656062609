import React from "react"
import styled from "styled-components"
import { FiLink } from "react-icons/fi"
import { Colors } from "../constants/Style"
import { lighten } from "polished"

const InputShell = styled.div`
  display: flex;
  padding: 0.6rem 0.2rem 0.6rem 0.8rem;
  border: 2px solid ${lighten(0.38, Colors.brown)};
  input {
    position: relative;
    top: 1px;
    padding: 0 0 0 12px;
    border: 0;
    color: ${lighten(0.08, Colors.brown)};
    width: 100%;
  }
`

const icons = {
  link: FiLink,
}

export default ({ type = "text", icon, ...props }) => {
  const Icon = icons[icon]
  return (
    <InputShell>
      <Icon size="18px" color={lighten(0.2, Colors.darkBlueGray)} />
      <input aria-label="Share page url" type={type} {...props} />
    </InputShell>
  )
}
