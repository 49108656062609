import React from "react"
import styled from "styled-components"
import { Colors } from "../../constants/Style"
import { lighten } from "polished"
import Icon from "../Icon"
import Link from "../Link"

const Shell = styled.div`
  &&& > div {
    margin: -3rem -2rem 5rem -2rem;
    h3 {
      background: ${Colors.darkBlueGray};
      color: ${Colors.white};
      font-size: 1.2rem;
      font-weight: 500;
      padding: 1rem;
      margin-bottom: 0;
    }
    .descr {
      padding: 1.8rem 1rem;
      color: ${Colors.darkBlueGray};
      background: ${lighten(0.52, Colors.darkBlueGray)};
      line-height: 135%;
    }
  }
  .table {
    padding: 0 1rem 1.4rem 1rem;
    background: ${lighten(0.56, Colors.darkBlueGray)};
  }
  table {
    td,
    th {
      text-align: center;
      &:first-child {
        text-align: left;
      }
    }
    .button {
      display: block;
      color: ${Colors.white};
      background: ${Colors.red};
      border-radius: 3px;
      padding: 0.8rem 1.4rem;
      border: 0;
      text-align: center;
      min-width: 7rem;
      margin: 0 auto;
      font-size: 1.1rem;
      width: 50%;
    }
  }
`

const PlanTable = ({ plan, company, columns }) => {
  const { publicPlanName, description } = plan
  return (
    <div>
      <h3>{publicPlanName} Plan</h3>
      <div className="descr">{description}</div>
      <div className="table">
        <table>
          {columns.map(({ key, label, type, link, suffix, prefix, showEmpty }) => {
            const val = plan[key]
            if (!val && !showEmpty) {
              return false
            }
            let finalVal = val ? `${prefix || ""}${val}${suffix || ""}` : "-"
            if (type === "bool") {
              finalVal = (
                <Icon
                  size="20px"
                  icon={val === true ? "check" : "x"}
                  color={val === true ? Colors.green : Colors.red}
                />
              )
            }
            return (
              <tr>
                <th>{label}</th>
                <td>{finalVal}</td>
              </tr>
            )
          })}
          <tr>
            <td colspan="40">
              <Link className="button" to={company.website.url} company={company.uid} name="deals">
                Get this Deal
              </Link>
            </td>
          </tr>
        </table>
      </div>
    </div>
  )
}

export default ({ plans, company, showColumns }) => {
  const useColumns = showColumns || [
    "priceMonth",
    "promoPrice",
    "promoPricePeriod",
    "contractLengthInMonths",
    "earlyTerminationFee",
    "incentive",
    "returnPeriod",
    "includesHomeAutomation",
    "installType",
    "planType",
    "primaryConnectionType",
    "redundantConnectionType",
    "remoteMonitoringAppIntegration",
    "batteryBackup",
  ]
  //     oneTimeFee1Price: 0
  // oneTimeFee1Type: "Kit Price"
  // oneTimeFee2Price: 99
  // oneTimeFee2Type: "Installation Price"
  // oneTimeFee3Price: null
  // oneTimeFee3Type: "none"
  const columns = {
    publicPlanName: { label: "Plan", link: true },
    priceMonth: { label: "Price", suffix: "/mo", prefix: "$" },
    promoPrice: { label: "Promo Price", suffix: "/mo", prefix: "$", showEmpty: false },
    promoPricePeriod: { label: "Promo Price Period" },
    contractLengthInMonths: { label: "Contract Length", suffix: " mo", showEmpty: false },
    "247ProfessionalMonitoring": { label: "24/7 Monitoring", type: "bool" },
    batteryBackup: { label: "Battery Backup", type: "bool" },
    earlyTerminationFee: { label: "Early Termination Fee" },
    incentive: { label: "Sign-up Bonus" },
    includesHomeAutomation: { label: "Includes Home Automation", type: "bool" },
    installType: { label: "Install Type", vals: { professional: "Professional" } },
    planType: { label: "Plan Type" },
    primaryConnectionType: { label: "Primary Connection Type" },
    redundantConnectionType: { label: "Redundant Connection" },
    remoteMonitoringAppIntegration: { label: "Remote Monitoring App", type: "bool" },
    returnPeriod: { label: "Return Period", suffix: " days" },
  }
  const filtered = useColumns.map(key => ({ key, ...columns[key] }))
  const filteredPlans = plans.filter(({ publicPlanName }) => publicPlanName)
  return filteredPlans ? (
    <Shell>
      {filteredPlans.map(plan => (
        <PlanTable plan={plan} company={company} columns={filtered} />
      ))}
    </Shell>
  ) : (
    <div />
  )
}
