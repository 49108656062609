import styled from "styled-components"
import Col from "../Col"
import { media } from "../constants/Style"

export default styled.div`
  display: flex;
  flex-direction: row;

  ${Col} {
    padding-right: 2rem;
    &:last-of-type {
      padding-right: 0;
    }
    ${media.hand`
      padding-right: 0;
    `}
  }
  ${media.hand`
    flex-direction: column;
  `}
`
