import React, { useState } from "react"
import styled from "styled-components"
import withFilteredProps from "../lib/withFilteredProps"

const FilteredShell = withFilteredProps(props => <div {...props} />, [
  "width",
  "float",
  "thewidth",
  "theheight",
  "preload",
])
const Shell = styled(FilteredShell).attrs({ className: "imageShell" })`
  width: ${({ width }) => `${width}%`};
  position: relative;
  overflow: hidden;
  margin-bottom: 2rem;
  &:before {
    content: "";
    position: absolute;
    top: -1%;
    left: -1%;
    width: 102%;
    height: 102%;
    background: url(${({ preload }) => preload});
    background-size: cover;
    transition: all 300ms;
    z-index: 10;
    opacity: 1;
    filter: blur(5px);
  }
  &.loaded {
    &:before {
      opacity: 0;
    }
  }
  img {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  div {
    width: 100%;
    padding-bottom: ${({ theheight, thewidth }) => `${(theheight / thewidth) * 100}%`};
  }
`

export default ({ dimensions, filename, base64, ext, image, forceLoaded, alt, style = "full", ...props }) => {
  const [loaded, setLoaded] = useState(forceLoaded || false)
  const styleParts = style.split("_")
  const styles = {
    float: "none",
    width: "100",
  }
  if (styleParts.length > 2) {
    styles.float = styleParts[0]
    styles.width = styleParts[1]
  }
  if (filename) {
    const { height, width } = dimensions || { height: 0, width: 0 }
    return (
      <Shell
        className={`${loaded ? "loaded" : "unloaded"} ${props.className}`}
        thewidth={width}
        theheight={height}
        {...styles}
      >
        <div />
        <picture onLoad={() => setLoaded(true)}>
          <source
            type="image/webp"
            data-srcset={`/images/${filename}-300.webp 300w,
            /images/${filename}-600.webp 600w,
            /images/${filename}-800.webp 800w,
            /images/${filename}.webp 1500w`}
            onLoad={() => setLoaded(true)}
          />
          <source
            type={`image/${ext === "jpg" ? "jpeg" : ext}`}
            data-srcset={`/images/${filename}-300.${ext} 300w,
            /images/${filename}-600.${ext} 600w,
            /images/${filename}-800.${ext} 800w,
            /images/${filename}.${ext} 1500w`}
            onLoad={() => setLoaded(true)}
          />
          <img alt={alt} src={`/images/${filename}.${ext}`} />
        </picture>
      </Shell>
    )
  }
  return null
}
