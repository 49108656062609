import styled, { css } from "styled-components"
import React from "react"
import ReactDOM from "react-dom"
import screenPosition from "../lib/screenPosition"

const ImageShell = styled.div.attrs({ className: "aspectImage" })`
  position: relative;
`
const Image = styled.img`
  ${({ aspectBox }) =>
    aspectBox
      ? css`
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
        `
      : null}
  margin: auto;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  opacity: 0;
  transition: 120ms all;
  &.loaded {
    opacity: 1;
  }
`
export default class AspectImage extends React.Component {
  constructor(props) {
    super(props)
    this.state = { loaded: false, shouldLoad: false }
    this.imgRef = React.createRef()
  }
  componentDidMount() {
    const el = ReactDOM.findDOMNode(this.imgRef.current)
    if (el) {
      this.pos = screenPosition(el)
      if (!this.loadIfInView()) {
        window.addEventListener("scroll", this.loadIfInView)
      }
    }
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.loadIfInView)
  }
  isInView = () => {
    return window.scrollY + window.outerHeight + 600 > this.pos.top
  }
  loadIfInView = () => {
    const inView = this.isInView()
    if (inView) {
      this.setState({ shouldLoad: true })
      window.removeEventListener("scroll", this.loadIfInView)
    }
    return inView
  }
  setLoaded = () => {
    if (this.state.shouldLoad) {
      this.setState({ loaded: true })
    }
  }
  render() {
    const { dimensions, filename, useVariant, ext, image, aspectBox = true, alt, ...props } = this.props
    const { loaded, shouldLoad } = this.state
    if (filename) {
      const { height, width } = dimensions || { height: 0, width: 0 }
      const url = `/images/${filename}${useVariant ? `-${useVariant}` : ""}.${ext}`
      const percent = (height / width) * 100
      const srcProps = { [shouldLoad ? "src" : "data-src"]: url }
      return (
        <ImageShell {...props}>
          {aspectBox && <div style={{ paddingBottom: `${percent}%` }} />}
          <Image
            ref={this.imgRef}
            className={loaded ? "loaded" : ""}
            alt={alt}
            title={alt}
            onLoad={() => this.setLoaded()}
            aspectBox={aspectBox}
            {...srcProps}
          />
        </ImageShell>
      )
    }
    return null
  }
}
