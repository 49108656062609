import React from "react"
import Cta from "../Cta"
import PrimaryContent from "../PrimaryContent"
import ContentImage from "../ContentImage"

function getCta(primary) {
  let cta = null
  if (
    primary.call_to_action !== "false" &&
    primary.cta_name !== "" &&
    primary.entityQuery &&
    primary.entityQuery.companies.length > 0
  ) {
    const entity = primary.entityQuery.companies[0]
    if (entity.data.ctas.length > 0) {
      cta = entity.data.ctas.find(cta => cta.internal_name === primary.cta_name)
    }
  }
  return cta
}

export default ({ primary, items }) => {
  let blockClass = primary.blockType !== "none" ? primary.blockType : ""
  // const { image, imageStyle, imageCaption } = primary
  const { image, imageStyle } = primary
  const cta = getCta(primary)
  let logo
  if (cta) {
    logo = primary.entityQuery.companies[0].data.company_logo.localFile.childImageSharp.fluid
  }
  // let imageTop = null
  // let imageBottom = null
  // let imageInText = null
  return (
    <div className={`block-wrap ${blockClass || ""}`.trim()}>
      <ContentImage {...image} style={imageStyle} />
      {/* <Image image={imageTop} />
      <Image image={imageInText} /> */}
      <PrimaryContent className="block-text">{primary}</PrimaryContent>
      <Cta cta={cta} logo={logo} />
      {/* <Image image={imageBottom} /> */}
    </div>
  )
}

// TODO: Need to do device CTAs
