import React from "react"
import CompanyPlanList from "./CompanyPlanList"
import EntityComparisonTable from "./EntityComparisonTable"
import Table from "./Table"
import Faqs from "./Faqs"
import FeaturedVideo from "./FeaturedVideo"
import Callout from "./Callout"
import ImageGallery from "./ImageGallery"
import ProsCons from "./ProsCons"
import Text from "./Text"
import UpdatesFooter from "./UpdatesFooter"
import TOCAnchor from "../TOCAnchor"
import Cta from "../Cta"
import Comparison from "./Comparison"

export default ({ children }) => {
  // need to loop through the html, find the header tags, add an ID based on the content inside them.
  const slices = children.filter(slice => slice.sliceType)
  const raw = children.filter(slice => !slice.sliceType)
  let output = []
  if (slices) {
    output = slices.map(({ sliceType: type, primary, items, props }) => {
      const id = JSON.stringify(primary).substr(0, 120)
      switch (type) {
        case "text":
          return <Text primary={primary} items={items} key={id} />
        case "entity_comparison_table":
          return <EntityComparisonTable primary={primary} items={items} key={id} />
        case "faqs":
          return <Faqs primary={primary} items={items} key={id} />
        case "featured_video":
          return <FeaturedVideo primary={primary} items={items} key={id} />
        case "callout":
          return <Callout primary={primary} items={items} key={id} />
        case "pros_cons": {
          return <ProsCons primary={primary} items={items} key={id} />
        }
        case "table":
          return <Table primary={primary} items={items} key={id} {...props} />
        case "updates_footer":
          return <UpdatesFooter primary={primary} items={items} key={id} />
        case "call_to_action":
          return <Cta primary={primary} items={items} key={id} {...props} />
        case "company_plan_list":
          return <CompanyPlanList primary={primary} items={items} key={id} {...props} />
        case "comparison":
          return <Comparison primary={primary} items={items} key={id} {...props} />
        case "image_gallery":
          return <ImageGallery primary={primary} items={items} key={id} />
        case "table_of_content_ref":
          return <TOCAnchor primary={primary} key={id} />
        default:
          return ""
      }
    })
  }
  if (raw && raw.length) {
    output = [...output, <Text primary={{ text: raw }} items={[]} key="the-main-raw-body" />]
  }
  return output
}
