import React from "react"
import styled from "styled-components"
import { lighten } from "polished"
import { Colors, media } from "../constants/Style"
import AspectImage from "./AspectImage"
import Contain from "./Contain"

const Shell = styled.div`
  background: ${lighten(0.027, Colors.lightBrightBlue)};
  width: 100%;
  padding: 4rem;
  margin-top: 6rem;

  h5 {
    font-size: 1.1rem;
    text-transform: uppercase;
    font-weight: 800;
    text-align: center;
    color: ${Colors.darkBlue};
    opacity: 0.5;
    margin-bottom: 2rem;
  }
  .logos {
    height: 4rem;
    display: flex;
    justify-content: space-around;
    img {
      height: 2.4rem;
    }
    ${media.tablet`
      padding: 0;
      justify-content: space-between;
      > div {
        padding: 0 0.8rem;
      }
    `}
    ${media.hand`
      flex-direction: column;
      align-items: center;
      height: auto;
      margin-bottom: -1.3rem;
      img {
        margin-bottom: 1rem;
      }
    `}
  }
`

export default ({ images, style }) => {
  return (
    <Shell style={style}>
      <h5>Our Reports Have Been Used By:</h5>
      <Contain className="logos">
        <div>
          <AspectImage {...images["wirecutter"]} aspectBox={false} />
        </div>
        <div>
          <AspectImage {...images["huffpost"]} aspectBox={false} />
        </div>
        <div>
          <AspectImage {...images["engadget"]} aspectBox={false} />
        </div>
        <div>
          <AspectImage {...images["kxan"]} aspectBox={false} />
        </div>
      </Contain>
    </Shell>
  )
}
