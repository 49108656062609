import React from "react"
import ContentWithSidebar from "./ContentWithSidebar"
import Slices from "../components/Slices/Slices"
import Comparison from "../components/Slices/Comparison"
import ComparisonTable from "../components/Slices/ComparisonTable"
import TableOfContents from "../components/TableOfContents"
import CompanyPlanList from "../components/Slices/CompanyPlanList"

const makeHeading = heading => {
  return {
    sliceType: "text",
    sliceLabel: null,
    items: [{}],
    primary: {
      text: [{ type: "heading2", text: heading, spans: [] }],
      blockType: "none",
      imageStyle: "hidden",
      image: {},
      imageCaption: null,
    },
  }
}
export default props => {
  const { page, ...pass } = props
  const { body, toc } = page
  const comparisonSlice = body.slice(0, 1)[0]
  const comparisonBody = [makeHeading("\nOur Analysis"), ...body.slice(1)]
  const comparisonProps = { ...comparisonSlice, ...comparisonSlice.props }
  const companies = comparisonProps.companies
  const top = companies.reduce(
    (out, curr) => {
      if (out.valueScore < curr.valueScore) {
        return curr
      }
      return out
    },
    { valueScore: 0 }
  )
  const ComparisonCta = body.find(c => c.sliceType === "call_to_action" && c.props.company.uid === top.uid)
  return (
    <div>
      <ContentWithSidebar
        h1={page.h1}
        body={comparisonBody}
        page={page}
        sidebar={
          <div style={{ marginTop: "6.2rem" }}>
            <TableOfContents toc={toc} />
          </div>
        }
        topComponent={<Comparison {...comparisonProps} top={top} />}
        centerTop={true}
        after={
          <>
            {companies
              .filter(c => c.plans && c.plans.length > 0)
              .map(c => {
                return (
                  <CompanyPlanList
                    key={`planlist-${c.uid}`}
                    company={c}
                    plans={c.plans}
                    primary={{ header: [makeHeading(`${c.name} Plans`).primary.text[0]] }}
                  />
                )
              })}
            <br />
            <br />
            <h2>Comparison Summary</h2>
            <ComparisonTable {...comparisonProps} />
            <br />
            <br />
            {ComparisonCta && <Slices>{[ComparisonCta]}</Slices>}
          </>
        }
        topSideContent={false}
        overlayTopContent={false}
        topAlign="center"
        {...pass}
      >
        <div />
      </ContentWithSidebar>
    </div>
  )
}
