let page = ""
const pages = {}

module.exports = opts => {
  if (typeof opts === "undefined") return
  if (typeof opts === "string") {
    page = opts
    if (!pages[page]) pages[page] = []
    return pages[opts]
  }
  if (opts.render && opts.render === "simple") {
    return `___<${JSON.stringify(opts)}>___`
  }
  if (page) {
    const codeLen = opts.length || 20
    const id = pages[page].length
    pages[page].push(opts)
    const pad = codeLen - id.toString().length - 3
    const str = `${page === "global" ? "$" : "%"}|${id}${"-".repeat(+pad > 0 ? pad : 0)}|`
    return str
  }
}
