import React from "react"
import styled from "styled-components"
import { transparentize } from "polished"
import AspectImage from "./AspectImage"
import Contain from "./Contain"
import { Colors, media } from "../constants/Style"

const Shell = styled(Contain).attrs({ className: "byline" })`
  background: #fff;
  margin-bottom: -1rem;
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  color: ${Colors.darkBlueGray};
  /* width: calc(76rem + 55px); */
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
  /* padding: 0 0 0 26px; */
  position: relative;
  left: 0px;
  margin-top: 1.4rem;
  span {
    span {
      font-size: 1rem;
      position: relative;
      top: -1px;
      opacity: 0.6;
    }
  }
  && {
    ${media.tablet`
    padding-left: 0;
    padding-right: 0;
  `}
  }
`
const Avatar = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 3px solid ${transparentize(0.83, Colors.darkBlueGray)};
  overflow: hidden;
  margin-right: 1rem;
  flex: none;
  .author-photo {
    width: 50px;
    height: 50px;
    width: 100%;
    height: 100%;
    img {
      object-fit: cover;
    }
  }
`

export default ({ style, author: { firstName, lastName, photo }, dateModified }) => {
  return firstName && lastName ? (
    <Shell style={style}>
      <Avatar>
        <AspectImage {...photo} className="author-photo" aspectBox={false} useVariant="300" />
      </Avatar>
      <span>
        {`By: ${firstName} ${lastName} `}&nbsp;&middot;&nbsp;<span>Last updated {dateModified}</span>
      </span>
    </Shell>
  ) : null
}
