import React from "react"
import ContentWithSidebar from "./ContentWithSidebar"
import CompanyCta from "../components/sidebar/CompanyCTA"
import CoverageMap from "../components/CoverageMap"
import CompanySidebar from "../components/CompanySidebar"
import Cards from "../components/Cards"
import Byline from "../components/Byline"

export default props => {
  const { children, page } = props
  const { topContent, h1, coverage, statesCovered, companyName, author, dateModifiedPretty } = page
  const after = (
    <>
      <Cards pages={page.related} showImage />
      <Byline author={author} dateModified={dateModifiedPretty} />
    </>
  )
  return (
    <>
      <ContentWithSidebar
        h1={h1}
        body={children}
        sidebar={<CompanySidebar {...props.page} />}
        topContent={topContent}
        after={after}
        page={page}
        topSideContent={<CompanyCta {...props.page} />}
      >
        <CoverageMap
          companyName={companyName}
          coverage={coverage === "Nationwide" ? "nationwide" : statesCovered.map(({ state }) => state)}
        />
      </ContentWithSidebar>
    </>
  )
}
