import React from "react"
import styled from "styled-components"
import Link from "../Link"
import AspectImage from "../AspectImage"

const Shell = styled.div`
  table {
    td,
    th {
      text-align: center;
      &:first-child {
        text-align: left;
      }
    }
  }
`

const PlanRow = ({ company, col: { Component, key, fnc, suffix, prefix, link } }) => {
  const val = company[key]
  const nextVal = fnc ? fnc(val) : val
  const finalVal = Component ? (
    <Component val={val} company={company} />
  ) : nextVal ? (
    `${prefix || ""}${nextVal}${suffix || ""}`
  ) : (
    "-"
  )
  if (link) {
    return (
      <td>
        <Link company={company.uid} name="main" to={company.website.url}>
          {finalVal}
        </Link>
      </td>
    )
  }
  return <td>{finalVal}</td>
}

export default ({ primary, items, ...props }) => {
  const { companies } = props
  const useColumns = props.columns || [
    "companyLogo",
    "name",
    "customerServiceScore",
    "equipmentScore",
    "featuresAndTechnologyScore",
    "easeOfUseScore",
    "valueScore",
    "typeOfMonitoring",
    "installType",
    "integrations",
    "coverage",
  ]
  const columns = {
    // logo: { label: "", link: true },
    companyLogo: {
      label: "",
      link: true,
      Component: ({ val, company }) => (
        <div>
          <AspectImage {...val} useVariant={300} />
        </div>
      ),
    },
    name: {
      label: "",
    },
    valueScore: { label: "Value" },
    customerServiceScore: { label: "Customer Service" },
    equipmentScore: { label: "Equipment" },
    featuresAndTechnologyScore: { label: "Features & Technolgy" },
    easeOfUseScore: { label: "Ease of Use" },
    typeOfMonitoring: { label: "Monitoring Type" },
    installType: { label: "Installation Type" },
    integrations: { label: "Integrations", fnc: val => val.map(({ integration }) => integration).join(", ") },
    coverage: { label: "Coverage" },
    // publicPlanName: { label: "Plan", link: true },
    // priceMonth: { label: "Price", suffix: "/mo", prefix: "$" },
    // promoPrice: { label: "Promo Price", suffix: "/mo", prefix: "$" },
    // contractLengthInMonths: { label: "Contract Length", suffix: " mo" },
  }

  const filtered = useColumns.map(key => ({ key, ...columns[key] }))
  // const top = companies.reduce(
  //   (out, curr) => {
  //     if (out.valueScore < curr.valueScore) {
  //       return curr
  //     }
  //     return out
  //   },
  //   { valueScore: 0 }
  // )
  // const rows = []

  return (
    <Shell>
      <table>
        {filtered.map(col => (
          <tr>
            <th>{col.label}</th>
            {companies.map(company => (company ? <PlanRow company={{ ...company }} col={col} /> : null))}
          </tr>
        ))}
      </table>
    </Shell>
  )
}
