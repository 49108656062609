import styled from "styled-components"
import { Dims, media } from "../constants/Style"

export default styled.div`
  width: ${Dims.container};
  margin: 0 auto;
  ${media.tablet`
    width: 100%;
    padding: ${Dims.tabletPadding};
  `}
  ${media.hand`
    width: 100%;
    padding: ${Dims.handPadding};
  `}
`
