import React, { useState, useEffect } from "react"
import styled from "styled-components"
import Col from "../Col"
import { Colors } from "../constants/Style"
import { lighten } from "polished"
import Link from "./Link"

const Shell = styled(Col)`
  position: relative;
  z-index: 1;
  justify-content: center;
  align-items: center;
  span {
    /* padding-top: 0.5rem; */
  }
`
const Descr = styled(Link)`
  color: ${Colors.brown};
  font-size: 0.9rem;
  text-decoration: none;
  font-weight: 400;
  margin-top: -1.4rem;
  margin-bottom: 0.2rem;
  width: 100%;
  text-align: center;
  line-height: 155%;
  span {
    border-bottom: 1px solid ${lighten(0.3, Colors.brown)};
  }
`
const Rating = ({ rating, num, className, company, includeLinkToReviews = false, name }) => {
  const [url, setUrl] = useState(`/home-security/${company}/reviews/`)
  useEffect(() => {
    if (window.location.pathname.includes("/review")) {
      setUrl("#reviews")
    }
  })
  return (
    <Shell className={className}>
      {/* <RatingStars rating={rating} size="30px" /> */}
      {/* {TODO: Nicky, this needs to be conditional in case the review isn't published.} */}
      {/* {num > 0 ? <span>{`${rating} stars, ${num} ratings`}</span> : null} */}
      {!includeLinkToReviews ? null : (
        <Descr to={url}>
          <span>{num === 0 ? `Leave a Review for ${name}` : `See ${num} user reviews and ratings for ${name}`}</span>
        </Descr>
      )}
    </Shell>
  )
}

export default styled(Rating)``
