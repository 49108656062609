import React from "react"
import styled from "styled-components"
import Contain from "./Contain"
import Link from "./Link"
import { Colors } from "../constants/Style"
import withFilteredProps from "../lib/withFilteredProps"

const Shell = styled(Contain)`
  display: flex;
  padding: 1rem 0 0.2rem;
  margin-bottom: -1.14rem;
  flex-wrap: wrap;

  span {
    margin: 0.3rem 0.7rem 0;
    font-size: 0.7rem;
    display: block;
  }
`

const LinkProps = withFilteredProps(Link, ["showSymbol"])
const A = styled(LinkProps)`
  position: relative;
  color: ${Colors.darkBlueGray};
  text-decoration: underline;
  display: inline;
  line-height: 1.4rem;
  font-size: 0.84rem;
  margin-right: 1.2rem;
  &:after {
    content: "${({ showSymbol }) => (showSymbol ? "/" : "")}";
    font-size: 0.6rem;
    position: absolute;
    right : -0.85rem;
    top: -0.05rem;

  }
`

export default ({ crumbs }) => {
  const bits = [{ title: "Home", slug: "" }, ...crumbs.parents, crumbs.current]
  let active = []
  return (
    bits.length > 1 &&
    bits[1].slug !== "" && (
      <Shell>
        {bits.map(({ title, slug }, i) => {
          active.push(slug)
          const out = (
            <A key={title} to={active.join("/") || "/"} showSymbol={i < bits.length - 1}>
              {title}
            </A>
          )
          return out
        })}
      </Shell>
    )
  )
}
