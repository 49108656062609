import React from "react"
import SocialProof from "../components/SocialProof"
import Cards from "../components/Cards"
import MainContent from "../components/MainContent"
import ArticleList from "../components/ArticleList"

export default props => {
  const { page, ...pass } = props
  const { images } = page.settings
  const after =
    page.uid === "research" ? <ArticleList pages={page.related} showImage /> : <Cards pages={page.related} showImage />
  return (
    <div>
      <MainContent showRightSide={false} page={{ h1: page.h1, body: page.body, after }} {...pass} />
      <SocialProof images={images} />
    </div>
  )
}
