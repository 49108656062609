import React from "react"
import styled from "styled-components"
import {
  FacebookIcon,
  FacebookShareButton,
  RedditShareButton,
  RedditIcon,
  EmailShareButton,
  EmailIcon,
  TwitterShareButton,
  TwitterIcon,
} from "react-share"
import IconInput from "./IconInput"
import { Colors } from "../constants/Style"
import { lighten } from "polished"

const ShareIcons = styled.div`
  display: flex;
  margin-top: 0.4rem;
  > div {
    margin-right: 0.4rem;
  }
  .social-icon {
    cursor: pointer;
    rect {
      transition: all 0.2s;
      fill: ${lighten(0.1, Colors.brown)} !important;
    }
    &:hover {
      rect {
        fill: ${lighten(0.0, Colors.brown)} !important;
      }
    }
  }
`

export default ({ url, title }) => {
  const btnProps = { url }
  const iconProps = { size: 32 }
  return (
    <div style={{ width: "100%" }} className="sharebox">
      <h4>Share this Page</h4>
      <IconInput icon="link" value={url} />
      <ShareIcons>
        <FacebookShareButton
          {...btnProps}
          quote={title}
          hashtag="howsafe"
          additionalProps={{ "aria-label": "Share on Facebook" }}
        >
          <FacebookIcon {...iconProps} />
        </FacebookShareButton>
        <TwitterShareButton
          {...iconProps}
          title={title}
          via="HomeAlarmReport.com"
          hashtags={["homesecurity", "homealarms", "safety", "howsafe", "howsafeorg"]}
          additionalProps={{ "aria-label": "Share on Twitter" }}
        >
          <TwitterIcon {...iconProps} />
        </TwitterShareButton>

        <RedditShareButton {...btnProps} title={title} additionalProps={{ "aria-label": "Share on Reddit" }}>
          <RedditIcon {...iconProps} />
        </RedditShareButton>
        <EmailShareButton
          {...btnProps}
          subject={title}
          additionalProps={{ "aria-label": "Share with email" }}
          body={`Hey, thought you might be interested in this:\n\n${title} - ${url}`}
        >
          <EmailIcon {...iconProps} />
        </EmailShareButton>
      </ShareIcons>
    </div>
  )
}
