import styled from "styled-components"
import { media } from "./constants/Style"

export default styled.div`
  display: flex;
  flex: none;
  flex-direction: column;
  width: ${({ cols, width }) => (width ? width : cols ? `${(cols / 12) * 100}%` : null)};
  ${media.hand`
    width: 100%;
  `}
`
